import { Container } from 'theme-ui';
import { Markdown } from '@snippets';

export function MarkdownBody({
  cms: { body, containerSettings, textSettings },
}) {
  return (
    <Container
      data-comp={MarkdownBody.displayName}
      variant={
        containerSettings?.fullWidth ? 'layout.full' : 'layout.container'
      }
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <Markdown
        textAlign={textSettings?.centerText ? 'center' : 'left'}
        text={body}
      />
    </Container>
  );
}

MarkdownBody.displayName = 'MarkdownBody';

MarkdownBody.Schema = {
  label: 'Markdown Body',
  key: 'markdownBody',
  fields: [
    {
      name: 'body',
      component: 'markdown',
      label: 'Body',
      defaultValue:
        'I am a text field with *italic* __bold__ and other powers...',
    },
    {
      label: 'Text Settings',
      name: 'textSettings',
      component: 'group',
      fields: [
        {
          label: 'Center Align All Text',
          name: 'centerText',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
      ],
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: false,
        },
      ],
    },
  ],
};
