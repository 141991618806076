import { useInView } from 'react-intersection-observer';
import { Button, Box, Container, Flex, Paragraph } from 'theme-ui';

import Link from 'next/link';

import { ProductItem } from '@snippets';

export function ProductGrid({ cms: { heading, cta, products } }) {
  const { ref, inView } = useInView();

  return (
    <Container
      data-comp={ProductGrid.displayName}
      ref={ref}
      variant="layout.container"
      sx={{
        mb: [9, null, null, null, null, null, 13],
        px: 10,
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: ['column', 'row'],
          mb: [9, null, null, null, 15],
          px: [9, null, null, null, null, null, null, 0],
        }}
      >
        {heading && (
          <Paragraph
            as="h2"
            variant="text.h2.r.1"
            sx={{
              color: 'primary',
              mb: [4, null],
            }}
          >
            {heading}
          </Paragraph>
        )}

        <Box
          sx={{
            display: ['none', 'flex'],
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexGrow: 1,
            py: 0,
            px: 10,
          }}
        >
          <Box
            sx={{
              height: '1px',
              bg: 'black',
              width: inView ? '100%' : '8px',
              position: 'relative',
              transition: 'width .4s ease-in-out',
              '&::before, &::after': {
                content: '""',
                display: 'block',
                width: '8px',
                height: '8px',
                border: '1px solid black',
                borderRadius: '50%',
                backgroundColor: 'white',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
              },
              '&::before': {
                left: 0,
              },
              '&::after': {
                right: 0,
              },
            }}
          />
        </Box>

        {cta && cta.text && (
          <Link href={cta.url || '#'} alt={cta.text || ''}>
            <Button
              variant={cta.buttonStyle}
              sx={{
                width: ['100%', 'auto'],
              }}
            >
              {cta.text}
            </Button>
          </Link>
        )}
      </Flex>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {products?.length > 0
          ? products.map((product, index) => {
              if (!product) return null;
              const handle = product?.handle || product?.product?.handle;
              return (
                handle && (
                  <Box
                    key={handle}
                    sx={{
                      flexBasis: [
                        '100%',
                        null,
                        'calc(50% - 20px)',
                        'calc(33.33% - 20px)',
                        null,
                        'calc(25% - 20px)',
                      ],
                      flexShrink: 0,
                      mx: '10px',
                      mb: 9,
                    }}
                  >
                    <ProductItem handle={handle} index={index} />
                  </Box>
                )
              );
            })
          : null}
      </Box>
    </Container>
  );
}

ProductGrid.displayName = 'ProductGrid';

ProductGrid.Schema = {
  label: 'Product Grid',
  key: 'products-grid',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'cta',
      component: 'link',
      label: 'CTA',
    },
    {
      name: 'buttonStyle',
      component: 'select',
      label: 'Button Style',
      options: [
        {
          value: 'primary',
          label: 'Primary',
        },
        {
          value: 'secondary',
          label: 'Secondary',
        },
        {
          value: 'outline',
          label: 'Outline',
        },
        {
          value: 'link.main',
          label: 'Link',
        },
      ],
      defaultValue: 'primary',
    },
    {
      name: 'products',
      label: 'Products',
      component: 'group-list',
      itemProps: {
        label: '{{item.product.title}}' || 'Search product',
      },
      defaultItem: {
        product: {
          handle: 'bha-clarify-gel-cream',
          title: 'BHA Clarify Gel Cream',
        },
      },
      fields: [
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
      defaultValue: [
        {
          product: {
            handle: 'bha-clarify-gel-cream',
            title: 'BHA Clarify Gel Cream',
          },
        },
      ],
    },
  ],
};
