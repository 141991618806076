import React, { useEffect, useState } from 'react';
import { Box, Paragraph } from 'theme-ui';
import store, { useRecoilState } from '@store';
import { ProductMain, Markdown } from '@snippets';

export function Modal({ ...props }) {
  const [modal, setModal] = useRecoilState(store.modal);

  const closeModal = () => {
    setModal(null);
    setOverlay(false);
  };

  return (
    <Box
      as="aside"
      data-comp="Modal"
      sx={{
        zIndex: 40,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mx: 'auto',
        display: modal ? 'block' : 'none',
        opacity: modal ? 1 : 0,
        willChange: 'opacity',
        transition: 'opacity .3s ease-in-out',
        width: '100%',
        padding: 10,
      }}
    >
      <Box
        sx={{
          minHeight: '700px',
          maxHeight: '80vh',
          maxWidth: '1200px',
          mx: 'auto',
          overflow: 'scroll',
          width: ['100%', null, null, null, '83.33%'],
          boxShadow: '0px -3px 30px rgb(0 0 0 / 8%)',
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* close modal */}
        <Box
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            right: '20px',
            top: '20px',
          }}
          onClick={closeModal}
        >
          <img
            alt="Close Modal"
            src="/svg/close.svg"
            width="20px"
            height="20px"
          />
        </Box>

        {modal?.type === 'product' ? (
          <ProductModal handle={modal?.handle} />
        ) : modal?.type === 'productInfo' ? (
          <ProductInfoModal
            title={modal.title}
            description={modal.description}
          />
        ) : null}
      </Box>
    </Box>
  );
}

function ProductInfoModal({ title, description }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['column', null, null, null, 'row'],
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          flexBasis: '50%',
          backgroundColor: 'whitesmoke',
          flexShrink: 0,
          // flexGrow: 1,
        }}
      />

      <Box
        sx={{
          p: 10,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '480px',
          mx: 'auto',
          flexBasis: '50%',
        }}
      >
        <Paragraph
          variant="text.h2.r.1"
          sx={{
            color: 'primary',
            mb: 7,
          }}
        >
          {title}
        </Paragraph>

        <Markdown text={description} />
      </Box>
    </Box>
  );
}

function ProductModal({ handle }) {
  const [fullProduct, setFullProduct] = useState(null);

  const loadFullJsonProduct = () => {
    let cancelled = false;
    if (!handle) return null;

    const fetchJsonProduct = async (handle) => {
      let product = null;
      console.log('fetchJsonProduct:handle', handle);
      try {
        const jsonApiUrl = `${window.location.protocol}/json/products/${handle}.json`;
        const response = await fetch(jsonApiUrl);
        if (response?.ok) {
          product = await response.json();
        }
        return product;
      } catch (error) {
        console.log('error', error);
        return null;
      }
    };

    fetchJsonProduct(handle)
      .then((product) => {
        if (product) {
          if (!cancelled) {
            setFullProduct(product);
          }
        }
      })
      .catch((error) => {
        console.log('loadFullJsonProduct:error', error);
        if (!cancelled) {
          setFullProduct(null);
        }
      });

    return () => {
      cancelled = true;
    };
  };

  useEffect(loadFullJsonProduct, [handle]);

  return fullProduct && fullProduct?.handle === handle ? (
    <Box
      sx={{
        position: 'relative',
        top: '0',
        height: '100%',
        pt: [10, null, null, null, 13],
        pb: [10, null, null, null, 13],
        px: [10, null, 12, 13, 14, 15, 17],
      }}
    >
      <ProductMain product={fullProduct} isModal />
    </Box>
  ) : null;
}
