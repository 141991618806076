import { useCallback, useState } from 'react';
import { Box, Button, Input, Paragraph } from 'theme-ui';
import { subscribe } from 'klaviyo-subscribe';
import { useDataLayerActions } from '@hooks/datalayer';

export function EmailSignup({ placeholder, klaviyoId }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formResponse, setformResponse] = useState('');
  const { sendSubscribeEvent } = useDataLayerActions();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (!klaviyoId) return null;

    subscribe(klaviyoId, email).then(({ data }) => {
      if (!data.is_subscribed) {
        setEmail('');
        setMessage("Thank you - you're signed up!");
        setformResponse('success');
        sendSubscribeEvent({ email: state.email });
      } else {
        setMessage('Invalid email address');
        setformResponse('error');
      }
    });
  });

  return (
    <Box
      as="form"
      email={email}
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: ['column', null, null, 'row'],
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        position: 'relative',
      }}
    >
      {formResponse === 'success' ? (
        <Paragraph
          color="white"
          sx={{
            maxWidth: '300px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {message}
        </Paragraph>
      ) : (
        <>
          <Input
            placeholder={placeholder || 'Enter your email address'}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{
              m: 0,
              py: 7,
              px: 8,
              background: 'white',
              mb: [4, null, null, 0],
              borderRadius: ['2px', null, null, '2px 0 0 2px'],
              border: 'none',
              fontSize: 3,
              lineHeight: 18,
              width: '280px',
              '::placeholder': {
                color: (t) => t.colors.black,
                fontSize: 3,
                lineHeight: 18,
              },
            }}
          />

          <Button
            variant="buttons.secondary"
            sx={{
              width: ['100%', null, null, 'initial'],
            }}
          >
            Subscribe
          </Button>
        </>
      )}
    </Box>
  );
}
