import Link from 'next/link';
// import Image from 'next/image';
import { Box, Paragraph, Image } from 'theme-ui';

import { Markdown } from '@snippets';

export function FeatureDescriptionImages({ cms: { heading, features } }) {
  return (
    <Box
      data-comp={FeatureDescriptionImages.displayName}
      sx={{
        mb: [9, null, null, null, null, null, 13],
        px: [9, null, null, null, null, null, 0],
      }}
    >
      <Paragraph
        as="h1"
        variant="text.h2.d"
        sx={{
          color: 'primary',
          mb: [9, null, null, null, 13],
          textAlign: 'center',
        }}
      >
        {heading}
      </Paragraph>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: ['center', null, null, null, 'flex-start'],
          flexDirection: ['column', null, null, null, 'row'],
          justifyContent: 'space-between',
          maxWidth: '992px',
          width: '100%',
          mx: 'auto',
        }}
      >
        {features?.map((feature, index) => {
          const src = feature?.image?.src || feature?.image;
          return (
            <Box
              key={index}
              sx={{
                maxWidth: '280px',
                textAlign: 'center',
                mb: [10, null, null, null, 0],
              }}
            >
              {feature?.cta?.url ? (
                <>
                  <Link
                    href={feature?.cta?.url || '#'}
                    title={feature?.cta?.text || ''}
                    sx={{
                      textDecoration: 'none',
                    }}
                  >
                    <Image
                      as="img"
                      src={src}
                      height={180}
                      width={180}
                      alt={feature.title}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        mb: 10,
                        width: '180px',
                      }}
                    />
                  </Link>

                  <Link
                    href={feature?.cta?.url || '#'}
                    title={feature?.cta?.text || ''}
                  >
                    <Paragraph
                      variant="text.h5.d"
                      sx={{
                        mb: 4,
                        cursor: 'pointer',
                      }}
                    >
                      {feature.title}
                    </Paragraph>
                  </Link>
                </>
              ) : (
                <>
                  <Image
                    src={src}
                    height={180}
                    width={180}
                    alt={feature.title}
                    sx={{
                      display: 'inline-block',
                      mb: 10,
                      width: '180px',
                    }}
                  />

                  <Paragraph
                    variant="text.h5.d"
                    sx={{
                      mb: 4,
                    }}
                  >
                    {feature.title}
                  </Paragraph>
                </>
              )}
              <Markdown
                variant="text.body.reg"
                sx={{ mb: 4, textAlign: 'left' }}
                text={feature.description}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

FeatureDescriptionImages.displayName = 'FeatureDescriptionImages';

FeatureDescriptionImages.Schema = {
  label: 'Feature Images w/ Description',
  key: 'feature-description-images',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'How It Works',
    },
    {
      name: 'features',
      label: 'Features',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      defaultItem: {
        title: 'Lorem Amet Etiam',
        description: 'Nullam quis risus eget urna mollis ornare vel eu leo.',
        cta: {
          url: '#',
          text: '',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
          filename: 'DAILY_ESSENTIALS_2023.jpg',
        },
      },
      fields: [
        {
          name: 'image',
          label: 'Feature Image',
          component: 'image',
        },
        {
          name: 'title',
          label: 'Item Title',
          component: 'text',
          defaultValue: 'Lorem Amet Etiam',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'markdown',
          defaultValue:
            'Nullam quis risus eget urna mollis ornare vel eu leo. Praesent commodo cursus magna, vel nisl consectetur.',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Link',
          defaultValue: {
            url: '#',
            text: '',
          },
        },
      ],
      defaultValue: [
        {
          title: 'Lorem Amet Etiam',
          description: 'Nullam quis risus eget urna mollis ornare vel eu leo.',
          cta: {
            url: '#',
            text: '',
          },
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
            filename: 'DAILY_ESSENTIALS_2023.jpg',
          },
        },
        {
          title: 'Lorem Amet Etiam',
          description: 'Nullam quis risus eget urna mollis ornare vel eu leo.',
          cta: {
            url: '#',
            text: '',
          },
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
            filename: 'DAILY_ESSENTIALS_2023.jpg',
          },
        },
      ],
    },
  ],
};
