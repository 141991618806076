import { isBrowser } from '@utils';
import { store } from '@backpackjs/storefront';

// update the "variant" query param on selected variant change
export const onSetUpdateVariantUrlParam =
  () =>
  ({ onSet }) => {
    onSet((selectedVariant, oldValue) => {
      if (!isBrowser || !window?.location) return;
      if (!selectedVariant?.legacyResourceId) return;

      const { origin, search } = window.location;

      // update variant param
      const params = new URLSearchParams(search);
      params.set('variant', selectedVariant.legacyResourceId);

      const updatedUrl = `${origin}/products/${selectedVariant.product.handle}?${params}`;

      window.history.replaceState('', '', updatedUrl);

      return selectedVariant;
    });
  };

export default {
  ...store.effects,
  // more effects here
  onSetUpdateVariantUrlParam,
};
