import { jsx, Flex, Paragraph } from 'theme-ui';
import store, { useSetRecoilState } from '@store';
import { useSettings } from '@backpackjs/storefront';

export function SearchSuggestions(props) {
  const siteSettings = useSettings();
  const setRawSearchTerm = useSetRecoilState(store.rawSearchTerm);

  return siteSettings?.search?.suggested?.length ? (
    <Flex
      variant="flex.column"
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      <Paragraph
        variant="text.body.reg"
        sx={{
          mb: 4,
          fontWeight: 600,
          textDecoration: 'underline',
        }}
      >
        Top Search Terms
      </Paragraph>

      <Flex variant="flex.column">
        {siteSettings?.search?.suggested?.map(({ label }, index) => {
          return (
            <Paragraph
              onClick={() => {
                console.log('Setting raw search term to suggestion', label);
                setRawSearchTerm(label);
              }}
              variant="text.body.s"
              sx={{ mb: 4, cursor: 'pointer' }}
              key={label + index}
            >
              {label}
            </Paragraph>
          );
        })}
      </Flex>
    </Flex>
  ) : null;
}
