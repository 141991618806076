export default {
  label: 'Secondary Hero',
  key: 'secondary-hero',
  fields: [
    {
      label: 'Image Settings',
      name: 'imageSettings',
      description: 'E.g. Background Image, Image Position.',
      component: 'group',
      fields: [
        {
          name: 'image',
          label: 'Image',
          component: 'image',
        },
      ],
      defaultValue: {
        image: {
          src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
          alt: 'Hero Image',
        },
      },
    },
    {
      label: 'Text Settings',
      name: 'textSettings',
      description: 'E.g. Heading, Text Color.',
      component: 'group',
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
          defaultValue: 'Secondary Hero Heading',
        },
        {
          name: 'headingColor',
          component: 'select',
          label: 'Heading Color',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
            {
              value: 'primary',
              label: 'Primary',
            },
          ],
          defaultValue: 'black',
        },
        {
          name: 'subheading',
          component: 'text',
          label: 'Subheading',
          defaultValue: 'Secondary Hero Subheading',
        },
        {
          name: 'subHeadingColor',
          component: 'select',
          label: 'Sub Heading Color',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
            {
              value: 'primary',
              label: 'Primary',
            },
          ],
          defaultValue: 'black',
        },
        {
          name: 'textAlign',
          component: 'select',
          label: 'Text Alignment',
          options: [
            {
              value: 'left',
              label: 'Left',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'right',
              label: 'Right',
            },
          ],
          defaultValue: 'center',
        },
      ],
      defaultValue: {
        heading: 'Secondary Hero Heading',
        headingColor: 'black',
        subheading: 'Secondary Hero Subheading',
        subHeadingColor: 'black',
        textAlign: 'center',
      },
    },
    {
      label: 'Button Settings',
      name: 'buttonSettings',
      description: 'E.g. Button Text, Button Path.',
      component: 'group',
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
          defaultValue: {
            text: 'Shop Now',
            path: '/',
          },
        },
        {
          name: 'buttonStyle',
          component: 'select',
          label: 'Button Style',
          options: [
            {
              value: 'primary',
              label: 'Primary',
            },
            {
              value: 'secondary',
              label: 'Secondary',
            },
            {
              value: 'outline',
              label: 'Outline',
            },
            {
              value: 'link.main',
              label: 'Link',
            },
          ],
          defaultValue: 'primary',
        },
      ],
      defaultValue: {
        cta: {
          text: 'Shop Now',
          path: '/',
        },
        buttonStyle: 'primary',
      },
    },
    {
      label: 'Content Settings',
      name: 'contentSettings',
      description: 'E.g. Content Alignment, Content Position.',
      component: 'group',
      fields: [
        {
          name: 'maxContentWidth',
          component: 'number',
          label: 'Max Content Width (px)',
          defaultValue: '500',
        },
        {
          name: 'contentPosition',
          component: 'select',
          label: 'Content Position',
          options: [
            {
              value: 'flex-start flex-start',
              label: 'Left Top',
            },
            {
              value: 'flex-start center',
              label: 'Left Center',
            },
            {
              value: 'flex-start flex-end',
              label: 'Left Bottom',
            },
            {
              value: 'flex-end flex-start',
              label: 'Right Top',
            },
            {
              value: 'flex-end center',
              label: 'Right Center',
            },
            {
              value: 'flex-end flex-end',
              label: 'Right Bottom',
            },
            {
              value: 'center flex-start',
              label: 'Center Top',
            },
            {
              value: 'center center',
              label: 'Center Center',
            },
            {
              value: 'center flex-end',
              label: 'Center Bottom',
            },
          ],
          defaultValue: 'center center',
        },
      ],
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: true,
        },
      ],
      defaultValue: {
        fullWidth: true,
      },
    },
  ],
  defaultValue: {
    imageSettings: {
      image: {
        src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
        alt: 'Hero Image',
      },
    },
    textSettings: {
      heading: 'Secondary Hero Heading',
      headingColor: 'black',
      subheading: 'Secondary Hero Subheading',
      subHeadingColor: 'black',
      textAlign: 'center',
    },
    buttonSettings: {
      cta: {
        text: 'Shop Now',
        path: '/',
      },
      buttonStyle: 'primary',
    },
    contentSettings: {
      maxContentWidth: '500',
      contentPosition: 'center center',
    },
  },
};
