export const getOptionStyle = (optionName, active, swatch) => {
  switch (optionName) {
    default:
      return {
        variant: 'text.buttons.primary',
        border: 'thin solid',
        color: (t) => (active ? 'white' : 'black'),
        backgroundColor: (t) => (active ? 'primary' : 'white'),
        borderColor: (t) => (active ? t.colors.black : t.colors.greys[5]),
        borderRadius: '4px',
        transition: 'all .25s ease-in-out',
        '&:hover': {
          borderColor: (t) => t.colors.black,
          textDecoration: 'underline',
        },
      };
  }
};
