import Link from 'next/link';
import { Image, Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartRemoveItem } from '@backpackjs/storefront';

import { QuantitySelect } from './QuantitySelect';

function addZeroes(num) {
  const dec = num.split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

export function CartLineItem({ lineItem, index, isDrawer, ...props }) {
  if (!lineItem) return null;

  const {
    id,
    attributes,
    estimatedCost,
    quantity,
    variant,
    discountAllocations,
  } = lineItem;

  const { cartRemoveItem } = useCartRemoveItem();

  return (
    <Flex
      data-comp={CartLineItem.displayName}
      variant="flex.row.between.center"
      sx={{
        px: 8,
        pt: isDrawer && index === 0 ? 0 : 9,
        overflow: 'hidden',
      }}
    >
      <Image
        src={
          variant?.image?.src ? variant?.image?.src : '/svg/default-image.svg'
        }
        alt={variant?.image?.altText}
        sx={{
          height: '120px',
          width: '120px',
          objectFit: 'cover',
          mr: 9,
        }}
      />
      <Flex variant="flex.column" sx={{ flex: 1 }}>
        <Flex variant="flex.row.between.start" sx={{ minHeight: '44px' }}>
          <Flex
            variant="flex.column"
            sx={{
              mr: 4,
              flex: 1,
              minHeight: '44px',
            }}
          >
            <Link href={`/products/${variant?.product?.handle}` || '#'}>
              <Paragraph
                variant="text.h6.r.1"
                sx={{ mb: 2, cursor: 'pointer' }}
              >
                {variant?.product?.title}
              </Paragraph>
            </Link>

            {variant.title !== 'Default Title' ? (
              <Paragraph variant="text.label.2" sx={{ my: 2 }}>
                {variant.title}
              </Paragraph>
            ) : null}

            {attributes?.length > 1 &&
            attributes.find(({ key }) => key === 'PACK_BUNDLE_NAME') ? (
              <Paragraph variant="text.label.2" sx={{ my: 2 }}>
                Bundle
              </Paragraph>
            ) : null}
          </Flex>

          <Flex variant="flex.column" sx={{ mb: '26px', minHeight: '44px' }}>
            <Paragraph variant="text.h6.r.1">
              ${addZeroes(estimatedCost?.totalAmount?.amount)}
            </Paragraph>

            {estimatedCost?.compareAtPriceAmount ? (
              <Paragraph
                variant="text.h6.r.1"
                sx={{
                  textAlign: 'right',
                  textDecoration: 'line-through',
                  color: (t) => t.colors.red,
                }}
              >
                ${addZeroes(estimatedCost?.compareAtPriceAmount?.amount)}
              </Paragraph>
            ) : null}
          </Flex>
        </Flex>

        <Flex variant="flex.row.between.center">
          <QuantitySelect id={id} quantity={quantity} />

          <Flex>
            <Button
              variant="buttons.link"
              onClick={async () => await cartRemoveItem({ lineId: id })}
            >
              Remove
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

CartLineItem.displayName = 'CartLineItem';
