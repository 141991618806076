import { Box, Container, Image } from 'theme-ui';

import { useBreakpoint } from '@hooks';
import { breakpoints } from '../../theme/theme.breakpoints';
import { PrimaryHeroContent } from './PrimaryHeroContent';
import Schema from './Schema';

export function PrimaryHero({ cms, isSlide }) {
  const {
    imageSettings,
    textSettings,
    buttonSettings,
    contentSettings,
    containerSettings,
  } = cms;

  const image = {
    desktopSrc: imageSettings?.image || '',
    desktopRatio: imageSettings?.desktopRatio || '5:12',
    mobileRatio: imageSettings?.mobileRatio || '1:1',
    mobileSrc: imageSettings?.mobileImage || '',
    position: imageSettings?.imagePosition || 'center center',
    mobilePosition: imageSettings?.mobileImagePosition || 'center center',
  };

  const text = {
    heading: textSettings?.heading || '',
    subheading: textSettings?.subheading || '',
    superheading: textSettings?.superheading || '',
    color: textSettings?.textColor || 'black',
    mobileColor: textSettings?.mobileTextColor || 'black',
    textAlign: textSettings?.textAlign || 'center',
  };

  const button = {
    text: buttonSettings?.cta?.text || '',
    path: buttonSettings?.cta?.url || '#',
    style: buttonSettings?.buttonStyle || 'primary',
    text2: buttonSettings?.cta2?.text || '',
    path2: buttonSettings?.cta2?.url || '#',
    style2: buttonSettings?.buttonTwoStyle || 'primary',
  };

  const content = {
    maxWidth: contentSettings?.maxContentWidth || '500px',
    position: contentSettings?.contentPosition || 'center center',
    mobilePosition: contentSettings?.mobileContentPosition || 'center',
  };

  const container = {
    fullWidth: containerSettings?.fullWidth || true,
  };

  const contentProps = {
    heading: text.heading,
    subheading: text.subheading,
    superheading: text.superheading,
    textAlign: text.textAlign,
    ctaText: button.text,
    ctaPath: button.path,
    buttonStyle: button.style,
    cta2Text: button.text2,
    cta2Path: button.path2,
    button2Style: button.style2,
    maxContentWidth: content.maxWidth,
    textColor: text.color,
    mobileTextColor: text.mobileColor,
  };

  const { s, m, _m, _l, l } = useBreakpoint();
  const isMobile = s || m || _m || _l || l;

  return (
    <Container
      data-comp={PrimaryHero.displayName}
      variant={container.fullWidth ? 'layout.full' : 'layout.container'}
      sx={{
        width: '100%',
        padding: 0,
        mb: isSlide ? 0 : [9, null, null, null, null, null, 13],
      }}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          paddingTop: [
            `calc((${image.mobileRatio.split(':').join(' / ')}) * 100%)`,
            null,
            null,
            null,
            null,
            `calc((${image.desktopRatio.split(':').join(' / ')}) * 100%)`,
          ],
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <picture>
            <source
              media={`(min-width: ${breakpoints[4]})`}
              srcSet={image.desktopSrc.src || image.desktopSrc}
            />
            <Image
              src={image.mobileSrc.src || image.mobileSrc}
              alt={text.heading}
              sx={{
                display: 'block',
                width: '100%',
              }}
            />
          </picture>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: [
              'center',
              null,
              null,
              null,
              null,
              content.position.split(' ')[0],
            ],
            justifyContent: [
              content.mobilePosition,
              null,
              null,
              null,
              null,
              content.position.split(' ')[1],
            ],
          }}
        >
          <PrimaryHeroContent {...contentProps} />
        </Box>
      </Box>
    </Container>
  );
}

PrimaryHero.displayName = 'PrimaryHero';

PrimaryHero.Schema = Schema;
