/*
  Note: this project has and extra breakpoint compared to other projects (@ about small laptop size)
  so if you were used to doing:
  mb: [10, null, null, 20] you may now need to do..
  mb: [10, null, null, null, 20]
*/

export const breakpoints = [
  /* DO NOT ADD/REMOVE */
  '30em', // 480px
  '37.5em', // 600px
  '48em', // 768px
  '64em', // 1024px
  '72em', // 1152px
  '80em', // 1280px
  '90em', // 1440px
  /* DO NOT ADD/REMOVE */
];
