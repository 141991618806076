import React, { useEffect } from 'react';
import { Box } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { SocialLinks, SearchSuggestionsOrResults } from '@snippets';
import { MenuItems } from './MenuItems';
import { MenuLinksMob } from './MenuLinksMob';

export function SearchOrMenus() {
  const searchIsFocused = useRecoilValue(store.searchIsFocused);
  const searchResults = useRecoilValue(store.searchResults$);

  useEffect(() => {
    if (searchResults?.products?.length && searchIsFocused) {
      PubSub.publish('SEARCH_RESULTS', searchResults?.products);
    }
  }, [searchResults?.products?.length, searchIsFocused]);

  return (
    <>
      <SearchSuggestionsOrResults />

      {/* otherwise this */}
      {/* If we have search results we hide the menus */}
      <Box
        data-comp="MenuDrawerMenus"
        sx={{
          display: searchIsFocused || searchResults ? 'none' : 'block',
        }}
      >
        {/* Menu Items Accordion */}
        <MenuItems />

        {/* Account, Contact Us... */}
        <MenuLinksMob />

        <SocialLinks iconColor="primary" />
      </Box>
    </>
  );
}
