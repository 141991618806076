import { Shopify3DModelViewer } from '../../snippets/Shopify3dModelViewer';

export function ThreeDViewer({ cms: { body } }) {
    // Currently the attributes are hard-coded but we could expand the
    // configuration fields to allow the model files to be specified.
    return <Shopify3DModelViewer
        alt="Good Molecules Skincare Mini-Fridge"
        src="/3d-models/gm-minifridge-closed-static.glb"
        poster="/images/gm-minifridge-closed-static.jpeg"
        iosSrc="/3d-models/gm-minifridge-closed-static.usdz#allowsContentScaling=0"
    />
}

ThreeDViewer.displayName = '3D Viewer';

ThreeDViewer.Schema = {
    label: '3D Viewer',
    key: 'threeDViewer',
    fields: [
    ],
};