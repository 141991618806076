import Image from 'next/image';
import { AspectRatio } from 'theme-ui';

export function BackpackImage({ ratio, src, ...props }) {
  return typeof ratio !== 'undefined' ? (
    <AspectRatio data-comp="BackpackImageRatio" ratio={ratio}>
      <Image src={src || '/svg/default-image.svg'} {...props} />
    </AspectRatio>
  ) : (
    <Image
      data-comp="BackpackImage"
      src={src || '/svg/default-image.svg'}
      {...props}
    />
  );
}
