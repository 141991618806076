export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      label: 'Free Shipping Meter Settings',
      name: 'freeShippingMeter',
      component: 'group',
      fields: [
        {
          label: 'Enable Free Shipping Meter',
          name: 'enableFreeShipping',
          component: 'toggle',
          toggleLabels: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
        {
          label: 'Free Shipping Cart Spend (USD)',
          name: 'freeShippingCartMin',
          component: 'number',
          description:
            'IMPORTANT: Dollar amount must be equal to the spend amount used in Shopify.',
        },
        {
          label: 'Name of Shipping Type',
          name: 'freeShippingName',
          component: 'text',
          description: 'Defaults to "Free Shipping".',
          defaultValue: 'Free Shipping',
        },
      ],
    },
    {
      label: 'Gift With Purchase',
      name: 'giftWithPurchase',
      component: 'group',
      fields: [
        {
          label: 'Enable Gift With Purchase',
          name: 'enableGiftWithPurchase',
          component: 'toggle',
          toggleLabels: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
        {
          label: 'Gift With Purchase Min Cart Spend (USD)',
          name: 'giftWithPurchaseMinCartSpend',
          component: 'number',
          description: '',
        },
        {
          label: 'Gift With Purchase Product',
          component: 'productSearch',
          name: 'giftWithPurchaseProduct',
        },
      ],
    },
  ],
};
