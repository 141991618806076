import { useEffect, useState, useCallback } from 'react';
import { Box, Container, Button, Paragraph } from 'theme-ui';
import { Afterpay, ProductOptions, ProductImages, Markdown } from '@snippets';
import {
  useCartAddItem,
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';
import { useInitSelectedVariant } from '@hooks';

import store, { useSetRecoilState } from '@store';
import { StarBadges } from '@snippets/Stamped';

// import { addToCartGtm } from '../../global/DataLayer';

function addZeroes(num) {
  const dec = num.split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

export function ProductMain({
  productDescription,
  productBundle,
  product,
  onVariantChange,
  isModal,
}) {
  const siteSettings = useSettings();
  const selectedVariant = useInitSelectedVariant();
  const [isSoldOut, setIsSoldOut] = useState(false);

  const descriptionMessage = siteSettings?.productPage?.descriptionMessage;

  const { cartAddItem, ...status } = useCartAddItem();

  const { inventory } = useProductInventoryByHandle({
    handle: product?.handle,
    withInventory: false,
  });

  useEffect(() => {
    if (inventory && selectedVariant) {
      const variantInventories = inventory?.variants;
      const selectedVariantAvailable =
        variantInventories?.[selectedVariant?.id]?.availableForSale;
      setIsSoldOut(!selectedVariantAvailable);
    }
  }, [inventory, selectedVariant]);

  const addToCartText = !isSoldOut ? 'ADD TO BAG' : 'SOLD OUT';

  const setModal = useSetRecoilState(store.modal);
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const setCartOpen = useSetRecoilState(store.cartDrawerOpen);

  const toggleCart = useCallback(() => {
    setSideDrawer('cart');
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
    setCartOpen(true);
  }, []);

  const [atcHover, setAtcHover] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const changeBy = (amount) => {
    const newQuantity = quantity + amount;
    if (newQuantity < 1) return;

    setQuantity(newQuantity);
  };

  const getPriceOffPercentage = (price, compareAtPrice) => {
    const totalValue = 100 * ((compareAtPrice - price) / compareAtPrice);
    return `${totalValue.toFixed(0)}% Off`;
  };

  return (
    <Container
      data-comp="ProductMain"
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '1200px',
        flexDirection: ['column', null, null, null, null, null, 'row'],
        justifyContent: 'center',
        mb: [11, null, null, null, 0],
      }}
    >
      <Box
        sx={{
          position: ['relative', null, null, null, null, null, 'sticky'],
          top: [
            '0',
            null,
            null,
            null,
            null,
            null,
            isModal ? 0 : 'calc(var(--header-height) + 24px)',
          ],
          alignSelf: 'flex-start',
          justifySelf: 'flex-end',
          width: '100%',
        }}
      >
        <ProductImages images={product?.images} />
      </Box>

      {/* Product Details */}
      <Box
        data-comp="ProductDescription"
        sx={{
          maxWidth: ['100%', null, null, null, null, null, '378px'],
          mt: [8, null, null, null, null, null, 0],
          width: '100%',
          ml: [0, null, null, null, null, null, 10, 15],
        }}
      >
        {/* Details box */}
        <Box
          sx={{
            border: 'thin solid',
            borderColor: (t) => t.colors.black,
            p: [8, null, 10],
            borderRadius: '4px',
            position: 'relative',
          }}
        >
          {/* Detail Box Dots */}
          <Box
            sx={{
              width: '8px',
              height: '8px',
              border: 'thin solid',
              borderColor: (t) => t.colors.black,
              borderRadius: '50%',
              position: 'absolute',
              transition: 'all .4s ease-in-out',
              backgroundColor: atcHover ? 'yellow' : 'white',

              top: '-4px',
              left: atcHover ? '15%' : '-4px',
            }}
          />

          <Box
            sx={{
              width: '8px',
              height: '8px',
              border: 'thin solid',
              borderColor: (t) => t.colors.black,
              borderRadius: '50%',
              position: 'absolute',
              transition: 'all .4s ease-in-out',
              backgroundColor: atcHover ? 'yellow' : 'white',
              bottom: '-4px',
              left: atcHover ? '-4px' : '20%',
            }}
          />

          <Box
            sx={{
              width: '8px',
              height: '8px',
              border: 'thin solid',
              borderColor: (t) => t.colors.black,
              borderRadius: '50%',
              position: 'absolute',
              transition: 'all .4s ease-in-out',
              backgroundColor: atcHover ? 'yellow' : 'white',

              bottom: atcHover ? '5%' : '30%',
              right: '-4px',
            }}
          />

          {/* Description & Title */}
          <Box
            sx={{
              mb: 9,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Paragraph
                as="h1"
                variant="text.h4.r.1"
                sx={{
                  color: 'primary',
                }}
              >
                {product?.title}
              </Paragraph>
            </Box>

            <Box
              sx={{
                mt: 4,
                mb: 8,
              }}
            >
              <StarBadges product={product} />
            </Box>

            <Box>
              {/* Product Price */}
              <Paragraph variant="text.buttons.primary">
                {selectedVariant?.priceV2?.amount ? (
                  <Paragraph
                    as="span"
                    variant="text.buttons.primary"
                    sx={{ mr: 4 }}
                  >
                    ${addZeroes(selectedVariant?.priceV2?.amount) || '0'} USD
                  </Paragraph>
                ) : null}

                {selectedVariant?.compareAtPriceV2?.amount ? (
                  <>
                    <Paragraph
                      as="span"
                      sx={{
                        textDecoration: selectedVariant?.compareAtPriceV2
                          ?.amount
                          ? 'line-through'
                          : 'none',
                        color: selectedVariant?.compareAtPriceV2?.amount
                          ? (t) => t.colors.red
                          : 'black',
                      }}
                    >
                      ${addZeroes(selectedVariant?.compareAtPriceV2?.amount)}{' '}
                      USD
                    </Paragraph>

                    <Paragraph
                      as="span"
                      sx={{
                        color: 'primary',
                        pl: '10px',
                      }}
                    >
                      {getPriceOffPercentage(
                        selectedVariant?.priceV2?.amount,
                        selectedVariant?.compareAtPriceV2?.amount
                      )}
                    </Paragraph>
                  </>
                ) : null}
              </Paragraph>

              <Box
                sx={{
                  marginTop: '16px',
                }}
              >
                {!(product?.optionsMap && product?.optionsMap.Title) &&
                product?.optionsMap &&
                selectedVariant ? (
                  <ProductOptions
                    options={product?.optionsMap}
                    handle={selectedVariant?.product?.handle}
                    image={selectedVariant?.image?.originalSrc}
                  />
                ) : null}
              </Box>

              {/* ATC Controls */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 8,
                }}
              >
                {/* QTY Selector */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: [5, null, null],
                  }}
                >
                  <Button
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 5,
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      border: 'thin solid',
                      borderColor: (t) => t.colors.black,
                      p: 0,
                      minWidth: 'unset',
                      fontWeight: 400,
                      flexShrink: 0,
                      backgroundColor: 'white',
                      color: 'black',
                      '&:hover': {
                        borderColor: (t) => t.colors.black,
                        backgroundColor: 'black',
                        color: 'white',
                      },
                    }}
                    onClick={() => changeBy(-1)}
                  >
                    &#8212;
                  </Button>

                  <Box
                    as="input"
                    type="number"
                    sx={{
                      height: '28px',
                      width: '45px',
                      border: 'none',
                      textAlign: 'center',
                      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                        {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      '&': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    onChange={(event) => {
                      if (
                        Number.isNaN(parseInt(event.target.value)) ||
                        parseInt(event.target.value) < 1
                      ) {
                        setQuantity(1);
                        return;
                      }

                      setQuantity(parseInt(event.target.value));
                    }}
                    value={quantity}
                  />

                  <Button
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 5,
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      border: 'thin solid',
                      borderColor: (t) => t.colors.black,
                      p: 0,
                      minWidth: 'unset',
                      fontWeight: 400,
                      flexShrink: 0,
                      backgroundColor: 'white',
                      color: 'black',
                      '&:hover': {
                        borderColor: (t) => t.colors.black,
                        backgroundColor: 'black',
                        color: 'white',
                      },
                    }}
                    onClick={() => changeBy(1)}
                  >
                    +
                  </Button>
                </Box>

                <Button
                  variant="buttons.primary"
                  disabled={isSoldOut}
                  sx={{
                    cursor: isSoldOut ? 'default' : 'pointer',
                    outline: isSoldOut ? 'none' : null,
                  }}
                  onMouseEnter={() => setAtcHover(true)}
                  onMouseLeave={() => setAtcHover(false)}
                  onClick={async () => {
                    if (isSoldOut) return;
                    if (!selectedVariant?.id) {
                      return console.log('Missing selectedVariant id');
                    }
                    await cartAddItem({
                      merchandiseId: selectedVariant.id,
                      quantity,
                    }).then(() => {
                      toggleCart();
                      setModal(false); // in case it's open
                    });
                  }}
                >
                  {addToCartText}
                </Button>
              </Box>

              <Box
                sx={{
                  mt: 6,
                }}
              >
                <Afterpay price={selectedVariant?.price} />
              </Box>
            </Box>

            {productDescription && (
              <Box sx={{ mt: 8 }}>{productDescription}</Box>
            )}
          </Box>
        </Box>

        {descriptionMessage ? (
          <Box
            sx={{
              variant: 'text.body.xs',
              mt: 8,
              mb: 9,
              p: {
                mb: 2,
              },
              'p:last-of-type': {
                mb: 2,
              },
            }}
          >
            <Markdown text={descriptionMessage} />
          </Box>
        ) : null}

        {/* Upsell Box */}
        {productBundle}
      </Box>
    </Container>
  );
}
