import { Box, Button, Paragraph } from 'theme-ui';
import Link from 'next/link';

export function PrimaryHeroContent({
  heading,
  subheading,
  superheading,
  ctaText,
  ctaPath,
  maxContentWidth,
  buttonStyle,
  textAlign,
  textColor,
  cta2Text,
  cta2Path,
  button2Style,
  mobileTextColor,
}) {
  return (
    <Box
      data-comp={PrimaryHeroContent.displayName}
      sx={{
        mx: [4, 12, null, null, null, 17],
        my: [12, null, null, null, null, 17],
        maxWidth: ['100%', null, null, `${parseInt(maxContentWidth)}px`],
      }}
    >
      {superheading && (
        <Paragraph
          variant="text.h4.r.1"
          sx={{
            color: [mobileTextColor, null, null, null, textColor],
            textAlign,
            mb: 4,
          }}
        >
          {superheading}
        </Paragraph>
      )}

      <Paragraph
        as="h1"
        variant="display.r.1"
        sx={{
          color: [mobileTextColor, null, null, null, textColor],
          textAlign,
          mb: subheading || ctaText ? 6 : 0,
        }}
      >
        {heading}
      </Paragraph>

      {subheading && (
        <Paragraph
          variant="text.body.r.2"
          sx={{
            color: [mobileTextColor, null, null, null, textColor],
            textAlign,
            mb: 4,
          }}
        >
          {subheading}
        </Paragraph>
      )}

      <Box
        sx={{
          display: ctaText || cta2Text ? 'flex' : 'none',
          alignItems: 'center',
          mt: 11,
          flexDirection: ['column', null, null, null, 'row'],
          justifyContent: textAlign
            ? textAlign === 'left'
              ? 'flex-start'
              : textAlign === 'center'
              ? 'center'
              : 'flex-end'
            : 'center',
        }}
      >
        <Box
          sx={{
            display: ctaText ? 'block' : 'none',
          }}
        >
          <Link href={ctaPath}>
            <Button variant={`buttons.${buttonStyle}`}>{ctaText}</Button>
          </Link>
        </Box>

        <Box
          sx={{
            display: cta2Text ? 'block' : 'none',
            ml: [0, null, null, null, 10],
            mt: [9, null, null, null, 0],
          }}
        >
          <Link href={cta2Path}>
            <Button variant={`buttons.${button2Style}`}>{cta2Text}</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

PrimaryHeroContent.displayName = 'PrimaryHeroContent';
