import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Flex, Paragraph, Box, Image } from 'theme-ui';
import store, { useRecoilValue, useSetRecoilState } from '@store';
import { useSettings } from '@backpackjs/storefront';
import { CollectionTile } from '@snippets';
import { useDataLayerActions } from '@hooks/datalayer';

export function SearchResults(props) {
  const router = useRouter();
  const siteSettings = useSettings();
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const searchTerm = useRecoilValue(store.searchTerm);
  const searchResults = useRecoilValue(store.searchResults$);
  const foundProducts = useRecoilValue(store.foundProducts$);
  const foundCollections = useRecoilValue(store.foundCollections$);
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  const goToLinkAndToggle = React.useCallback((link) => {
    if (link) {
      // navigate
      router.push(link);

      // no desktop close the sideDrawer
      setSideDrawer(null);
      setOverlay(false);
    }
  });

  const productResults = searchResults?.products?.filter((product) => {
    const exclude = siteSettings?.search?.excludeProducts;

    return !exclude?.includes(product.handle);
  });

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: foundProducts,
      isSearchPage: false,
    });
  }, [foundProducts, sendViewSearchResultsEvent]);


  return (
    <Box
      data-comp="SearchResults"
      sx={{
        ...props.sx,
      }}
    >
      <Flex
        variant="flex.column"
        sx={{
          pb: foundProducts ? 8 : 0,
        }}
      >
        {/* Product Results */}
        {siteSettings?.search?.showProducts && productResults?.length ? (
          <Flex variant="flex.column">
            <Paragraph
              variant="16.1"
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
                py: 8,
                bg: 'white',
              }}
            >
              PRODUCTS
            </Paragraph>

            <Flex variant="flex.column">
              {productResults.map((product) => {
                // Each product
                return (
                  <Flex
                    key={product.id}
                    sx={{
                      mb: 4,
                      py: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, .025)',
                      },
                    }}
                    onClick={() => goToLinkAndToggle(product.url.split('?')[0])}
                  >
                    {/* [todo]: Load sized images from shopify */}
                    <Box
                      sx={{ position: 'relative', width: 75, flexShrink: 0 }}
                    >
                      {/* 
                      TODO: fix load image from shopify
                    */}
                      <Image
                        alt={product.title}
                        ratio={1 / 1}
                        src={product.featured_image.url}
                        width={75}
                        height={75}
                      />
                    </Box>

                    <Flex variant="flex.column" sx={{ ml: 8 }}>
                      <Paragraph variant="text.h6.r.1" sx={{ pt: 3 }}>
                        {product.title}
                      </Paragraph>
                      <Paragraph
                        variant="text.body.r.1"
                        sx={{ color: 'darkGrey', mt: 2 }}
                      >
                        {product.type}
                      </Paragraph>
                      <Paragraph variant="text.body.s" sx={{ mt: 4 }}>
                        ${product.price}
                      </Paragraph>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        ) : null}

        {/* Collection Results */}
        {siteSettings?.search?.showCollections && foundCollections?.length ? (
          <Flex
            variant="flex.column"
            sx={{
              py: 8,
            }}
          >
            <Paragraph
              variant="16.1"
              sx={{
                position: 'sticky',
                top: ['-32px', null, null, null, '72px'],
                zIndex: 2,
                py: 6,
                bg: 'white',
              }}
            >
              COLLECTIONS
            </Paragraph>

            <Flex variant="flex.column">
              {searchResults.collections.map((collection) => {
                // Each Collection
                return (
                  <CollectionTile
                    key={collection.id}
                    alt={collection.title}
                    src={collection.featured_image.url}
                    text={collection.title}
                    onClick={() => goToLinkAndToggle(collection.url)}
                  />
                );
              })}
            </Flex>
          </Flex>
        ) : null}

        {/* No results */}
        {searchTerm && !foundCollections?.length && !productResults?.length ? (
          <Paragraph>Nothing found, please try a different search</Paragraph>
        ) : null}
      </Flex>
    </Box>
  );
}
