import { Box, Spinner } from 'theme-ui';
import { useProduct, useProductByHandle } from '@backpackjs/storefront';
import { useEffect } from 'react';
import { isBrowser } from '@utils';
import { useInView } from 'react-intersection-observer';

export function ReviewsWidget() {
  const BASE_URL = process.env.SITE_URL; // TODO: Replace with your URL
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const product = useProduct();

  useEffect(() => {
    if (isBrowser && inView) {
      try {
        window.StampedFn?.reloadUGC();
      } catch (e) {
        console.error('failed to load stamped ugc', e);
      }
    }
  }, [product?.id, inView]);

  return (
    <div
      className="px-contained py-contained"
      id={ReviewsWidget.displayName}
      ref={ref}
    >
      {product ? (
        <Box
          as="div"
          id="stamped-main-widget"
          data-widget-style="standard"
          data-product-id={product.legacyResourceId}
          data-name={product?.title}
          data-url={`${BASE_URL}/products/${product?.handle}`}
          data-image-url={product?.featuredImage?.originalSrc}
          data-description={product?.description}
          data-product-sku={product?.handle}
          css={{
            '.fa-star, .fa-star-checked, .fa-star-half-o, .fa-star-o, .stamped-fa-star, .stamped-fa-star-checked, .stamped-fa-star-half-o, .stamped-fa-star-o, .stamped-verified-badge':
              {
                color: '#B33BB3',
              },
            '.summary-rating-bar-content': {
              backgroundColor: '#B33BB3',
            },
          }}
        />
      ) : (
        <div className="flex h-[500px] items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
