import PropTypes from 'prop-types';
import Link from 'next/link';
import { Button, Paragraph, Flex } from 'theme-ui';
import { useCartCount, useSettings } from '@backpackjs/storefront';
import store, { useSetRecoilState } from '@store';

export function CartEmptyState({ isDrawer = false, ...props }) {
  const hideSideDrawer$ = useSetRecoilState(store.hideAllDrawers$);
  const cartCount = useCartCount();
  const siteSettings = useSettings();

  const emptyCartMessage = siteSettings?.cart?.cartMessage;

  return (
    <Flex
      data-comp={CartEmptyState.displayName}
      variant="flex.column.center.center"
      sx={{
        pt: 13,
        pb: [13, null, null, null, '68px'],
        px: 10,
        border: isDrawer ? 0 : ['1px solid #7A7A7A', null, null, null, 0],
        borderLeft: 0,
        borderRight: 0,
        mr: isDrawer ? 0 : [0, null, null, null, '130px'],
      }}
      {...props}
    >
      <Paragraph
        variant="text.h3.d"
        sx={{ color: 'primary', mb: 5, textAlign: 'center' }}
      >
        {emptyCartMessage?.message}
      </Paragraph>

      <Link href={emptyCartMessage?.cta?.url || '#'}>
        <Button
          variant="buttons.outline"
          onClick={() => (cartCount < 1 && isDrawer ? hideSideDrawer$() : null)}
        >
          {emptyCartMessage?.cta?.text}
        </Button>
      </Link>
    </Flex>
  );
}

CartEmptyState.displayName = 'CartEmptyState';

CartEmptyState.propTypes = {
  isDrawer: PropTypes.bool,
};
