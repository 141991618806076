import { Button, Image, Box } from 'theme-ui';
import { BackpackImage } from '@snippets';

/*
  These tiles are not product items. These are used in the search
  to display "collection thumbnails/items"
*/
export function CollectionTile({
  src,
  ratio = 335 / 200,
  alt,
  text,
  ...props
}) {
  const imageSrc =
    typeof src !== 'undefined' && src ? src : '/svg/default-image.svg';

  return (
    <Box
      data-comp="MenuItemChild"
      {...props}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        mb: 7,
        ...props.sx,
      }}
    >
      {/* img */}
      {/* TODO: IMAGE needs to be fixed */}
      {src && src.includes('cdn.shopify.com') ? (
        <Image
          ratio={ratio}
          alt={alt}
          src={imageSrc}
          objectFit="cover"
          layout="fill"
          width={335}
        />
      ) : src && src.includes('backpack-cms') ? (
        <BackpackImage
          ratio={ratio}
          alt={alt}
          src={imageSrc}
          objectFit="cover"
          layout="fill"
          width={335}
        />
      ) : (
        <AspectImage ratio={ratio} alt={alt} src={imageSrc} width="100%" />
      )}

      {/* Overlay */}
      <Box
        sx={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          position: 'absolute',
          backgroundColor: 'black',
          opacity: src ? 0.3 : 0.1,
        }}
      />

      {/* Button */}
      <Button
        variant="outline.1"
        sx={{
          position: 'absolute',
          bottom: '16px',
          left: '16px',
        }}
      >
        {text}
      </Button>
    </Box>
  );
}
