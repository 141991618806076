import { Container } from 'theme-ui';

export function RawHTMLBody({ cms: { body } }) {
  return (
    <Container
      data-comp={RawHTMLBody.displayName}
      variant="layout.full"
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </Container>
  );
}

RawHTMLBody.displayName = 'RawHTMLBody';

RawHTMLBody.Schema = {
  label: 'Raw HTML Body',
  key: 'rawHTMLBody',
  fields: [
    {
      name: 'body',
      component: 'html',
      label: 'Body',
      defaultValue: 'Add <b>HTML</b> here...',
    },
  ],
};
