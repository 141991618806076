import { ReviewsWidget } from '@snippets/Stamped';

export function StampedReviewWidget() {
  return <ReviewsWidget />;
}

StampedReviewWidget.Schema = {
  label: 'Stamped Review Widget',
  key: 'stamped-review-widget',
  fields: [],
};
