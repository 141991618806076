import React from 'react';
import { Flex } from 'theme-ui';

import { Menu } from './Menu';
import { MenuDrawerMob } from './Mobile/MenuDrawerMob';
import { PromoBar } from './Promobar';

const setHeaderHeightEffect = () => {
  const headerHeight = () => {
    const mediaQueryList = window.matchMedia('(max-width: 48em)');
    if (mediaQueryList.matches) {
      document.documentElement.style.setProperty('--header-height', '56px');
    } else {
      document.documentElement.style.setProperty('--header-height', '72px');
    }
  };

  window.addEventListener('resize', headerHeight);
  headerHeight();

  return () => {
    window.removeEventListener('resize', headerHeight);
  };
};

export function Header() {
  React.useEffect(setHeaderHeightEffect, []);

  return (
    <Flex
      as="header"
      data-comp="Header"
      sx={{
        position: 'sticky',
        flexDirection: 'column',
        top: 0,
        zIndex: 30,
        height: 'auto',
        boxShadow: 'bottom',
        alignItems: 'center',
        bg: (t) => t.colors.white,
      }}
    >
      <PromoBar />

      {/* 📱 & 🖥  */}
      <Menu />

      {/* 📱 Only */}
      <MenuDrawerMob />
    </Flex>
  );
}

Header.displayName = 'Header';
