import { memo } from 'react';
import { Flex, Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartCount, useSettings } from '@backpackjs/storefront';

import { FreeShippingProgress, GiftWithPurchase } from '@snippets';
import { CartEmptyState } from './CartEmptyState';
import { CartLineItems } from './CartLineItems';
import { CartTotals } from './CartTotals';

export const CartDrawer = memo(({ isDrawer, ...props }) => {
  const cartCount = useCartCount();
  const siteSettings = useSettings();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          bg: 'white',
          boxShadow: '0px -3px 30px rgba(0, 0, 0, 0.08)',
        }}
      >
        <CartTotals isDrawer={isDrawer} />
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
        }}
      >
        {siteSettings?.cart && cartCount ? <FreeShippingProgress /> : <div />}
        {siteSettings?.cart && cartCount ? <GiftWithPurchase /> : <div />}
        {cartCount > 0 ? (
          <CartLineItems key="cart-line-items" isDrawer={isDrawer} />
        ) : (
          <CartEmptyState key="cart-empty-state" isDrawer={isDrawer} />
        )}
      </Box>
    </Box>
  );
});

CartDrawer.displayName = 'CartDrawer';

CartDrawer.propTypes = {
  isDrawer: PropTypes.bool,
};
