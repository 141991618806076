import { store } from '@backpackjs/storefront';

const sizeGuideEnabled = store.recoil.atom({
  key: 'sizeGuide/enabled',
  default: false,
});

export default {
  sizeGuideEnabled,
};
