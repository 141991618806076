import { memo } from 'react';
import { Container, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCart } from '@backpackjs/storefront';

import { CartHeader } from './CartHeader';
import { CartEmptyState } from './CartEmptyState';
import { CartLineItems } from './CartLineItems';
import { CartTotals } from './CartTotals';

export const CartPage = memo(({ isDrawer, ...props }) => {
  const cart = useCart();

  const totalsProps = {
    isDrawer,
  };

  return (
    <Container variant="layout.container">
      <Flex
        data-comp={CartPage.displayName}
        sx={{
          flexDirection: ['column-reverse', null, null, null, 'row'],
          justifyContent: ['flex-start', null, null, null, 'center'],
          alignItems: ['center', null, null, null, 'flex-start'],
          mt: [10, 13],
          mb: [11, 16],
        }}
        {...props}
      >
        <Flex
          variant="flex.column"
          sx={{
            flex: 1,
            width: ['100%', null, null, null, 'auto'],
          }}
        >
          <CartHeader
            sx={{
              display: ['none', null, null, null, 'flex'],
            }}
          />
          {cart?.lines?.length > 0 ? (
            <CartLineItems key="cart-line-items" isDrawer={isDrawer} />
          ) : (
            <CartEmptyState key="cart-empty-state" isDrawer={isDrawer} />
          )}
          <CartTotals
            {...totalsProps}
            isFooter
            sx={{
              display: ['flex', null, null, null, 'none'],
              mt: 9,
            }}
          />
        </Flex>
        <Flex
          variant="flex.column"
          sx={{
            width: '100%',
            bg: 'white',
            maxWidth: ['100%', null, null, null, '380px'],
            position: ['relative', null, null, null, 'sticky'],
            top: [
              0,
              null,
              null,
              null,
              'calc(24px + var(--header-height) + var(--promoBar-height))',
            ],
          }}
        >
          <CartHeader
            isDrawer={isDrawer}
            sx={{
              display: ['flex', null, null, null, 'none'],
            }}
          />
          <CartTotals {...totalsProps} />
        </Flex>
      </Flex>
    </Container>
  );
});

CartPage.displayName = 'CartPage';

CartPage.propTypes = {
  isDrawer: PropTypes.bool,
};
