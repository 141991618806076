import { isBrowser } from '@utils';
import { useState, useEffect } from 'react';

const DEBUG = process.env.NODE_ENV == 'development';

const refreshStampedWidgets = ({ delay = 250 }) => {
  if (isBrowser) {
    const __rInterval = function (callback, delay) {
      const dateNow = Date.now;
      const requestAnimation = window.requestAnimationFrame;
      let start = dateNow();
      let stop;
      const intervalFunc = function () {
        dateNow() - start < delay || ((start += delay), callback());
        stop || requestAnimation(intervalFunc);
      };
      requestAnimation(intervalFunc);
      return {
        clear() {
          stop = 1;
        },
      };
    };

    const maxWait = 10000; // 10sec
    const start = Date.now();

    const stampedLoaded = __rInterval(() => {
      if (
        typeof window !== 'undefined' &&
        typeof window.StampedFn === 'object'
      ) {
        window.StampedFn.reloadUGC && window.StampedFn.reloadUGC();

        stampedLoaded.clear();
      } else {
        console.log('⚠️ Waiting for reloadUGC...');
        const pastThreshold = Date.now() - start > maxWait;
        if (pastThreshold) {
          console.log(
            '🚫 reloadUGC did not load within the threshold of',
            maxWait / 1000,
            's'
          );
          stampedLoaded.clear();
        }
      }
    }, delay);
  }
};

// export const useStampedRefreshOnMount = ({ delay = 100 }) => {
//   if (DEBUG) return;

//   const [mounted, setMounted] = useState(false);
//   useEffect(() => {
//     setMounted(true);
//   }, []);

//   useEffect(() => {
//     if (!mounted) return;
//     DEBUG && console.log('🔄 Stamped element mounted');
//     refreshStampedWidgets({ delay });
//   }, [mounted]);
// };
