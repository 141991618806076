import { useRef } from 'react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';
import Link from 'next/link';

import { ProductItem } from '@snippets';

export function ProductSwiper({ products, heading, cta }) {
  const { ref, inView } = useInView();
  const sliderRef = useRef(null);

  return (
    <Box
      data-comp="ProductSwiper"
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        ref={ref}
        css={{
          '.swiper-horizontal': {
            display: 'flex',
            flexDirection: 'column',
            pb: 2,
            position: 'relative',
            '.swiper-slide': {
              height: 'auto',
            },
            '.swiper-pagination': {
              position: 'static',
              paddingTop: 9,
              order: 1,
              zIndex: 0,
              '.swiper-pagination-bullet': {
                width: '10px',
                height: '10px',
                opacity: 1,
                background: 'white',
                border: 'thin solid',
                borderColor: 'black',
                transition: 'all .25s ease-in-out',
                '&.swiper-pagination-bullet-active': {
                  backgroundColor: 'black',
                },
              },
            },
          },
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            flexDirection: ['column', 'row'],
            mb: [9, null, null, null, 15],
            px: [9, null, null, null, null, null, null, 0],
          }}
        >
          {heading && (
            <Paragraph
              as="h2"
              variant="text.h2.r.1"
              sx={{
                color: 'primary',
                mb: [4, null],
              }}
            >
              {heading}
            </Paragraph>
          )}

          <Box
            sx={{
              display: ['none', 'flex'],
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexGrow: 1,
              py: 0,
              px: 10,
            }}
          >
            <Box
              sx={{
                height: '1px',
                bg: 'black',
                width: inView ? '100%' : '8px',
                position: 'relative',
                transition: 'width .4s ease-in-out',
                '&::before, &::after': {
                  content: '""',
                  display: 'block',
                  width: '8px',
                  height: '8px',
                  border: '1px solid black',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
                '&::before': {
                  left: 0,
                },
                '&::after': {
                  right: 0,
                },
              }}
            />
          </Box>

          {cta && cta.text && (
            <Link href={cta.url || '#'} alt={cta.text || ''}>
              <Button
                variant={cta.buttonStyle}
                sx={{
                  width: ['100%', 'auto'],
                }}
              >
                {cta.text}
              </Button>
            </Link>
          )}
        </Flex>

        <Box
          ref={sliderRef}
          className="product-swiper-container"
          sx={{
            width: '100%',
            pl: [9, null, null, null, null, null, null, 0],
          }}
        >
          {/* Product Carousel */}
          {products?.length > 0 && (
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              loop
              pagination={{ clickable: true }}
              spaceBetween={20}
              slidesPerView={1.4}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              breakpoints={{
                480: {
                  slidesPerView: '1.25',
                  spaceBetween: 24,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
              }}
            >
              {products.map(({ product }, index) => {
                if (!product?.handle) return null;
                const handle = product?.handle || product?.product?.handle;

                return (
                  handle && (
                    <SwiperSlide key={index}>
                      <ProductItem
                        index={index}
                        handle={product.handle || product.product.handle}
                      />
                    </SwiperSlide>
                  )
                );
              })}

              <div className="swiper-button-prev" css={buttonStyle} />
              <div className="swiper-button-next" css={buttonStyle} />
            </Swiper>
          )}
        </Box>
      </Box>
    </Box>
  );
}

ProductSwiper.propTypes = {
  products: PropTypes.array,
  heading: PropTypes.string,
  cta: PropTypes.object,
};

const buttonStyle = {
  background: 'white',
  color: 'black',
  opacity: 0.8,
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  fontSize: '20px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
  '&:after': {
    fontSize: '20px',
  },
  '@media only screen and (max-width: 991px)': {
    height: '40px',
    width: '40px',
    fontSize: '16px',
    '&:after': {
      fontSize: '16px',
    },
  },
  '&:hover': {
    opacity: 1,
  },
};
