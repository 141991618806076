import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Image, Paragraph } from 'theme-ui';

import { useCartAddItems } from '@backpackjs/storefront';
import store, { useSetRecoilState } from '@store';

export function Bundle({ bundle }) {
  const { cartAddItems, ...addItemStatus } = useCartAddItems();
  const setModal = useSetRecoilState(store.modal);
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const toggleCart = useCallback(() => {
    setSideDrawer('cart');
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
  }, []);

  const [products, setProducts] = useState([]);

  const bundleProducts = bundle?.products;
  if (!bundleProducts) return null;


  const variants = products.reduce((acc, product) => {
    if (product?.variants?.length) {
      acc = [
        ...acc,
        {
          ...product?.variants[0],
          bundleQty: product.bundleQty || 1,
        },
      ];
    }

    return acc;
  }, []);

  const bundlePrice = variants.reduce((acc, variant) => {
    return acc + Number.parseFloat(variant.price).toFixed(2) * variant.bundleQty;
  }, 0);

  const isAvailable = variants.every((variant) => {
    return variant.availableForSale;
  });

  const handleAddToCart = useCallback(async () => {
    const itemsToAdd = variants.map((variant) => {
      return {
        merchandiseId: variant.id,
        quantity: variant?.bundleQty || 1,
        attributes: [
          {
            key: 'PACK_BUNDLE_NAME',
            value: bundle.title,
          },
          {
            key: 'PACK_BUNDLE_ID',
            value: bundle.id,
          },
        ],
      };
    });

    const cart = await cartAddItems(itemsToAdd);

    if (!cart) {
      console.log('Error adding to cart', addItemStatus?.errors);
      return;
    }

    toggleCart();
    setModal(false);
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productPromises = bundleProducts.map(async (product) => {
          const response = await fetch(`/json/products/${product.handle}.json`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        });

        const productsData = await Promise.all(productPromises);
        setProducts(productsData.filter(Boolean));
      } catch (error) {
        console.error('Error getting bundle products:', error);
      }
    };

    fetchProducts();
  }, [bundleProducts]);
  return (
    <Box
      sx={{
        border: 'thin solid',
        borderColor: (t) => t.colors.black,
        borderRadius: '4px',
        mb: 10,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'yellow',
          py: '4',
          textAlign: 'center',
          borderBottom: 'thin solid',
          borderColor: (t) => t.colors.black,
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Paragraph variant="text.label.2">{bundle?.title}</Paragraph>
      </Box>

      <Box
        sx={{
          p: 10,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            minHeight: '100px',
            mb: 10,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 9,
            rowGap: 9,
          }}
        >
          {products?.map((product) => {
            return (
              <Box key={product.handle} sx={{ minHeight: 0 }}>
                <Link
                  data-comp="BundleItem"
                  href={encodeURI(`/products/${product.handle}`)}
                >
                  <Box
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Image
                        src={product.featuredImage?.originalSrc}
                        alt={product.featuredImage?.altText}
                        sx={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      {product.bundleQty > 1 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '30px',
                            height: '30px',
                            backgroundColor: 'primary',
                            borderRadius: '50%',
                            color: 'white',
                            fontSize: 3,
                            fontWeight: '500',
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                          }}
                        >
                          {product.bundleQty}
                        </Box>
                      ) : null}
                    </Box>

                    <Box
                      sx={{
                        py: 2,
                        '.stamped-starrating': {
                          mr: '4px !important',
                        },
                        '.stamped-badge-caption': {
                          fontSize: '10px',
                          fontWeight: 400,
                          textTransform: 'uppercase',
                          textDecoration: 'underline',
                        },
                        '.fa-star-half-o:before, .fa-star-o:before, .fa-star:before, .stamped-fa-star-half-o:before, .stamped-fa-star-o:before, .stamped-fa-star:before':
                        {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {/* <StarBadges product={product} /> */}
                    </Box>

                    <Paragraph variant="text.h6.r.1">
                      {product?.title}
                    </Paragraph>

                    <Paragraph
                      variant="text.labels.2"
                      sx={{
                        mt: 4,
                      }}
                    >
                      ${product?.variants?.[0]?.price}
                    </Paragraph>
                  </Box>
                </Link>
              </Box>
            );
          })}

          {products.length > 1 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                fontSize: 9,
                fontWeight: 600,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '45px',
                height: '45px',
                borderRadius: '50%',
                background: 'white',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
              }}
            >
              +
            </Box>
          ) : null}
        </Box>

        {isAvailable ? (
          <Button
            variant="buttons.secondary"
            sx={{
              width: '100%',
            }}
            onClick={handleAddToCart}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Paragraph>Add All To Bag</Paragraph>

              <Paragraph variant="text.label.2">${bundlePrice}</Paragraph>
            </Box>
          </Button>
        ) : (
          <Button
            variant="buttons.secondary"
            sx={{
              opacity: '.5',
              pointerEvents: 'none',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Paragraph>Sold Out</Paragraph>

              <Paragraph variant="text.label.2">${bundlePrice}</Paragraph>
            </Box>
          </Button>
        )}
      </Box>
    </Box>
  );
}
