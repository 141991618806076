import { Box, Flex, Paragraph } from 'theme-ui';

import { useSelectedOptions, useSelectedOptionIsAvailable } from '@hooks';
import { getOptionStyle } from '@utils';

export function ProductOptions({ options, handle, image }) {
  const [selectedOptions, setSelectedOptions] = useSelectedOptions();
  const selectedOptionIsAvailable = useSelectedOptionIsAvailable();
  return (
    <Box
      data-comp="ProductOptions"
      sx={{
        alignItems: 'center',
      }}
    >
      {options &&
        Object.keys(options)?.map((optionName, index) => {
          return (
            <Box key={optionName}>
              <Paragraph
                variant="text.links.text"
                sx={{
                  flexShrink: 0,
                  mr: 8,
                }}
              >
                Select {optionName}
              </Paragraph>

              <ProductOption
                key={optionName}
                optionName={optionName}
                index={index}
                selectOption={setSelectedOptions}
                selectedOptions={selectedOptions}
                options={options}
                handle={handle}
                image={image}
              />
            </Box>
          );
        })}
    </Box>
  );
}

function ProductOption({
  index,
  options,
  selectOption,
  selectedOptions,
  optionName,
}) {
  return (
    <Box data-comp="ProductOption" key={index} sx={{}}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {options[optionName]?.map((value, index) => {
          const isActive = selectedOptions[optionName] === value;
          // console.log(selectedOptions[optionName], value, 'isActive', isActive)
          return (
            <Box
              key={index}
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                height: '32px',
                px: 9,
                mr: 4,
                mb: 4,
                position: 'relative',
                ...getOptionStyle(optionName, isActive, value),
              }}
              onClick={() =>
                selectOption({
                  [optionName]: value,
                })
              }
            >
              {value}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
