import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';
import { Box, Container, Flex, Paragraph } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';

import { SocialLinks } from '@snippets';

export function Footer() {
  const siteSettings = useSettings();

  return (
    <Box
      as="footer"
      sx={{
        backgroundColor: 'primary',
      }}
    >
      <Container
        variant="layout.container"
        sx={{
          py: [12, null, null, 13],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Email & Social Links */}
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            alignItems: 'center',
            mb: [11, null, null, 12],
          }}
        >
          {/* Email */}
          <Flex
            sx={{
              flexDirection: ['column', null, null, 'row'],
              alignItems: 'center',
              mb: [11, null, null, 0],
              mr: [0, null, null, 14],
            }}
          >
            <a
              href="https://goodmolecules.attn.tv/p/yJv/landing-page"
              target="_blank"
              rel="noreferrer"
            >
              <Paragraph
                variant="text.h5.d"
                sx={{
                  color: 'white',
                  mb: [8, null, null, 0],
                  mr: [0, null, null, 10],
                  cursor: 'pointer',
                }}
              >
                {siteSettings?.footer?.emailSignup?.heading}
              </Paragraph>
            </a>
          </Flex>

          {/* Social Links */}
          <SocialLinks iconColor="white" />
        </Flex>

        {/* Footer Nav Links */}
        <Flex
          as="ul"
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            mb: [6, null, null, 3],
            listStyleType: 'none',
            px: 0,
          }}
        >
          {siteSettings?.footer?.footerMenu.map((menuItem, index) => {
            return (
              <Box
                as="li"
                key={index}
                sx={{
                  color: 'white',
                  mr: 10,
                  mb: 4,
                }}
              >
                <Link href={menuItem?.link?.url || '#'}>
                  <Paragraph variant="text.label.2" sx={{ cursor: 'pointer' }}>
                    {menuItem?.link?.text}
                  </Paragraph>
                </Link>
              </Box>
            );
          })}
        </Flex>

        {/* Legal Links */}
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            alignItems: 'center',
            color: 'white',
            fontSize: 4,
            fontWeight: 400,
          }}
        >
          {/* Links */}
          <Flex
            as="ul"
            sx={{
              alignItems: 'center',
              listStyleType: 'none',
            }}
          >
            {siteSettings?.footer?.legalLinks?.map((legalLink, index) => {
              return (
                <Flex
                  as="li"
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                >
                  <Link href={legalLink?.link?.url || '#'}>
                    <Paragraph>{legalLink?.link?.text}</Paragraph>
                  </Link>

                  <Paragraph
                    sx={{
                      color: 'white',
                      mx: 3,
                      fontSize: '8px',
                      userSelect: 'none',
                    }}
                  >
                    •
                  </Paragraph>
                </Flex>
              );
            })}
          </Flex>

          {/* Legal Message */}
          <Paragraph>
            &copy;&nbsp;
            {new Date().getFullYear() > 2010 && new Date().getFullYear()}&nbsp;
            {siteSettings?.footer?.copyrightNotice}
          </Paragraph>
        </Flex>
      </Container>
    </Box>
  );
}
