import React, { useCallback } from 'react';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';

import 'swiper/css/bundle';

export function ProductImages({ images }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [swiper, setSwiper] = React.useState(null);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const goToSlide = useCallback(
    (index) => {
      swiper.slideToLoop(index);
      setActiveIndex(index);
    },
    [swiper]
  );

  // useEffect(() => {
  //   if (!swiper || !swiper.initialized) return;
  //   swiper.on('slideChange', (swiper) => {
  //     if (!swiper) return
  //     setActiveIndex(swiper.realIndex);
  //   });
  // }, [swiper]);

  return (
    <Box
      data-comp="ProductImages"
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: ['column', null, null, null, 'row-reverse'],
        justifyContent: 'flex-start',
      }}
    >
      {/* MAIN IMAGE CAROUSEL */}
      <Box
        sx={{
          width: '100%',
          paddingTop: '100%',
          mb: 6,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <Swiper
            data-comp="ProductImages"
            loop
            spaceBetween={0}
            modules={[Thumbs]}
            thumbs={{ swiper: thumbsSwiper }}
            className="productImageCarousel"
            fadeEffect={{ crossFade: true }}
          >
            {images?.map((img, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={img.originalSrc}
                    alt={img.altText}
                    sx={{
                      width: '100%',
                      display: 'block',
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>

      {/* THUMBNAILS */}
      <Box
        sx={{
          width: ['100%', null, null, null, '80px'],
          mr: [0, null, null, null, 10],
          flexShrink: 0,
          '.swiper-vertical': {
            height: ['auto', null, null, null, '480px'],
          },
          '.swiper-slide-thumb-active .gallery-thumb': {
            opacity: 1,
            border: 'thin solid #0096A5',
          },
        }}
      >
        <Swiper
          loop={false}
          onSwiper={setThumbsSwiper}
          spaceBetween={8}
          slidesPerView={6}
          navigation={false}
          watchSlidesProgress
          className="productImageThumbnails"
          direction="horizontal"
          breakpoints={{
            992: {
              loop: false,
              direction: 'vertical',
              spaceBetween: 12,
            },
          }}
        >
          {images?.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <Box
                  className="gallery-thumb"
                  sx={{
                    padding: '4px',
                    borderRadius: '4px',
                    // mb: [0, null, null, null, 4],
                    // mr: [4, null, null, null, 0],
                    overflow: 'hidden',
                    cursor: 'pointer',
                    transition: 'all .25s ease-in-out',
                    border: 'thin solid transparent',
                    opacity: '.6',
                    ':hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={img.originalSrc}
                    // src={sizeShopifySrc(img.originalSrc, { width: 200 })}
                    alt={img.altText}
                    sx={{
                      display: 'block',
                      width: '100%',
                    }}
                  />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
}
