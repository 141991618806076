import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCart } from '@backpackjs/storefront';

import { CartLineItem } from './CartLineItem';

export function CartLineItems({ isDrawer = false, ...props }) {
  const cart = useCart();

  return (
    <Box
      data-comp={CartLineItems.displayName}
      sx={{
        mr: isDrawer ? 0 : [0, null, null, null, '130px'],
      }}
    >
      {cart?.lines?.map((lineItem) => {
        return (
          <CartLineItem
            key={lineItem.id}
            index={lineItem.index}
            isDrawer={isDrawer}
            lineItem={lineItem}
          />
        );
      })}
    </Box>
  );
}

CartLineItems.displayName = 'CartLineItems';

CartLineItems.propTypes = {
  isDrawer: PropTypes.bool,
};
