import { Container, Box, Flex, Paragraph, Image } from 'theme-ui';

export function ImageText({
  cms: { heading, text, image, imageLocation = 'left' },
}) {
  const imageLeft = imageLocation === 'left';

  return (
    <Container data-comp={ImageText.displayName} variant="layout.container">
      <Flex
        sx={{
          width: '100%',
          mb: [9, null, null, null, null, null, 13],
          flexDirection: [
            'column',
            null,
            null,
            imageLeft ? 'row' : 'row-reverse',
          ],
          justifyContent: ['flex-start', null, null, 'center'],
        }}
      >
        <Box
          sx={{
            bg: 'ghostwhite',
            gridArea: 'media',
            paddingBottom: '100%',
            position: 'relative',
            overflow: 'hidden',
            width: ['100%', null, null, 'calc(50% - 16px)'],
            pb: ['100%', null, null, 'calc(50% - 16px)'],
            mr: imageLeft ? [0, null, null, 11] : 0,
            ml: imageLeft ? 0 : [0, null, null, 11],
            mb: [9, null, null, 0],
          }}
        >
          <Image
            src={image?.src || image}
            alt={heading}
            sx={{
              width: '100%',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
        </Box>

        <Flex
          variant="flex.column"
          sx={{
            width: ['100%', null, null, 'calc(50% - 16px)'],
            justifyContent: ['flex-start', null, null, 'center'],
          }}
        >
          {heading && (
            <Flex
              variant="flex.column"
              sx={{
                mb: [10, null, null, null, 12],
              }}
            >
              <Paragraph
                variant="text.h2.r.1"
                sx={{
                  mb: 6,
                }}
              >
                {heading}
              </Paragraph>
            </Flex>
          )}

          <Paragraph variant="text.body.reg">{text}</Paragraph>
        </Flex>
      </Flex>
    </Container>
  );
}

ImageText.displayName = 'ImageText';

ImageText.Schema = {
  label: 'Image Text Block',
  key: 'image-text',
  fields: [
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue: {
        src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
        filename: 'DAILY_ESSENTIALS_2023.jpg',
      },
    },
    {
      component: 'select',
      name: 'imageLocation',
      label: 'Image Location',
      options: [
        {
          value: 'left',
          label: 'Left',
        },
        {
          value: 'right',
          label: 'Right',
        },
      ],
      defaultValue: 'left',
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'LOREM IPSUM DOLOR SIT',
    },
    {
      name: 'text',
      label: 'Text',
      component: 'textarea',
      defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    },
  ],
};
