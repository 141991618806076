import { useEffect, useState } from 'react';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';

export function useShopifyAnalyticsAddToCart({
  basePayload,
  sendShopifyAnalyticsEvent,
}) {
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);

  useEffect(() => {
    if (!basePayload || !cart) return;
    if (!previousCartItems) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      return;
    }

    if (cartCount > previousCartCount) {
      let isAdded = false;
      let isIncreased = false;
      let quantity = 1;
      const updatedCartItem = cartItems.find((line, index) => {
        if (index === 0) {
          const currentAddedAt = line.attributes?.find(
            (attr) => attr.key === '_addedAt'
          )?.value;
          const prevAddedAt = previousCartItems[index]?.attributes?.find(
            (attr) => attr.key === '_addedAt'
          )?.value;
          if (currentAddedAt && currentAddedAt !== prevAddedAt) {
            isAdded = true;
            quantity =
              line.quantity - (previousCartItems[index]?.quantity || 0);
            return true;
          }
        }
        if (line.quantity > previousCartItems[index]?.quantity) {
          isIncreased = true;
          quantity = line.quantity - previousCartItems[index].quantity;
          return true;
        }
        return false;
      });

      if (!updatedCartItem?.variant || (!isAdded && !isIncreased)) return;

      const price = updatedCartItem.estimatedCost?.totalAmount?.amount;

      sendShopifyAnalyticsEvent(
        // payload
        {
          ...basePayload,
          cartId: cart.id,
          totalValue: parseFloat(price),
          products: [
            {
              productGid: updatedCartItem.variant.product.id,
              name: updatedCartItem.variant.product.title,
              price,
              variantGid: updatedCartItem.variant.id,
              variantName: updatedCartItem.variant.title,
              category: updatedCartItem.variant.product.productType,
              brand: process.env.NEXT_PUBLIC_SITE_TITLE || '7Diamonds',
              ...(updatedCartItem.sku ? { sku: updatedCartItem.sku } : null),
              quantity,
            },
          ],
        },
        // event name
        'ADD_TO_CART',
        // callback
        () => {
          setPreviousCartItems(cartItems);
          setPreviousCartCount(cartCount);
        }
      );
    }
  }, [basePayload, cart?.updatedAt]);
}
