export default {
  label: 'Fifty Fifty Tile',
  key: 'fifty-fifty-tile',
  fields: [
    {
      label: 'Media Settings',
      name: 'mediaSettings',
      description: 'E.g. Image or Video',
      component: 'group',
      fields: [
        {
          name: 'mediaPosition',
          component: 'toggle',
          label: 'Media Position',
          description: 'Position media on left or right',
          toggleLabels: {
            true: 'Media Right',
            false: 'Media Left',
          },
          defaultValue: false,
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
        },
        {
          name: 'images',
          label: 'Carousel Images',
          description: 'An active carousel will overwrite the single image.',
          component: 'group-list',
          defaultItem: {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
              filename: 'DAILY_ESSENTIALS_2023.jpg',
            },
          },
          fields: [
            {
              name: 'image',
              label: 'Image',
              component: 'image',
            },
          ],
        },
        {
          name: 'vimeoLink',
          label: 'Vimeo Link',
          component: 'text',
          description:
            'Adding a Vimeo video will overwrite the carousel & single image.',
        },
        {
          name: 'genericVideoLink',
          label: 'Video Link',
          component: 'text',
          description:
            'Adding a generic video url will overwrite the carousel, single image, and vimeo video.',
        },
      ],
      defaultValue: {
        mediaPosition: false,
        images: [
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
              filename: 'DAILY_ESSENTIALS_2023.jpg',
            },
          },
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
              filename: 'DAILY_ESSENTIALS_2023.jpg',
            },
          },
        ],
      },
    },
    {
      label: 'Details Settings',
      name: 'detailsSettings',
      description: 'E.g. Heading, description list, CTA',
      component: 'group',
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
          defaultValue: 'Parturient Ipsum Condimentum',
        },
        {
          name: 'headingColor',
          component: 'select',
          label: 'Heading Color',
          options: [
            {
              value: 'primary',
              label: 'Primary',
            },
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
        },
        {
          name: 'buttonStyle',
          component: 'select',
          label: 'Button Style',
          options: [
            {
              value: 'primary',
              label: 'Primary',
            },
            {
              value: 'secondary',
              label: 'Secondary',
            },
            {
              value: 'outline',
              label: 'Outline',
            },
            {
              value: 'link.main',
              label: 'Link',
            },
          ],
          defaultValue: 'secondary',
        },
        {
          name: 'moreDetails',
          component: 'link',
          label: 'More Details Link',
        },
        {
          label: 'Details',
          name: 'details',
          component: 'group-list',
          itemProps: {
            label: '{{item.heading}}',
          },
          defaultItem: {
            heading: 'Etiam Vestibulum Risus Nullam',
            description:
              'Nullam id dolor id nibh ultricies vehicula ut id elit.',
          },
          fields: [
            {
              name: 'heading',
              component: 'text',
              label: 'Heading',
              defaultValue: 'Etiam Vestibulum Risus Nullam',
            },
            // {
            //   name: 'image',
            //   label: 'Image',
            //   component: 'image',
            // },
            {
              name: 'description',
              component: 'markdown',
              label: 'Description',
              defaultValue:
                'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            },
            {
              name: 'routine',
              component: 'group-list',
              label: 'Routine',
              itemProps: {
                label: '{{item.step}}',
              },
              defaultItem: {
                step: 'Step 1: Cleanse',
              },
              fields: [
                {
                  name: 'step',
                  component: 'text',
                  label: 'Step',
                  defaultValue: 'Step 1: Cleanse',
                },
                {
                  name: 'current',
                  component: 'toggle',
                  label: 'Step Current',
                  description: 'Select the current step in the routine.',
                  toggleLabels: {
                    true: 'on',
                    false: 'off',
                  },
                  defaultValue: false,
                },
              ],
            },
            {
              name: 'use',
              component: 'select',
              label: 'When to Use',
              options: [
                {
                  value: 'none',
                  label: 'None',
                },
                {
                  value: 'AM',
                  label: 'AM',
                },
                {
                  value: 'PM',
                  label: 'PM',
                },
                {
                  value: 'AMPM',
                  label: 'AM & PM',
                },
                {
                  value: 'SPF',
                  label: 'SPF',
                },
                {
                  value: 'PMSPF',
                  label: 'PM & SPF',
                },
              ],
              defaultValue: 'none',
            },
            {
              name: 'width',
              component: 'text',
              label: 'Width',
              description: 'Enter a width for the icon. E.g. 50%',
              defaultValue: '50%',
            },
          ],
          defaultValue: [
            {
              heading: 'Etiam Vestibulum Risus Nullam',
              description:
                'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            },
            {
              heading: 'Etiam Vestibulum Risus Nullam',
              description:
                'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            },
          ],
        },
      ],
      defaultValue: {
        heading: 'Parturient Ipsum Condimentum',
        headingColor: 'primary',
        cta: {
          label: 'CTA',
          href: '#',
        },
        buttonStyle: 'primary',
        moreDetails: {
          label: 'More Details',
          href: '#',
        },
        details: [
          {
            heading: 'Etiam Vestibulum Risus Nullam',
            description:
              'Nullam id dolor id nibh ultricies vehicula ut id elit.',
          },
          {
            heading: 'Etiam Vestibulum Risus Nullam',
            description:
              'Nullam id dolor id nibh ultricies vehicula ut id elit.',
          },
        ],
      },
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: false,
        },
      ],
    },
  ],
};
