export const colors = {
  black: '#050001',
  white: '#fff',
  background: '#fff', // req
  text: '#050001', // req
  yellow: '#FFD200',
  green: '#016250',
  red: '#F05B29',
  primary: '#B33BB3',
  secondary: {
    1: '#F05B29',
    2: '#0849B3',
    3: '#0096A5',
    4: '#016250',
    5: '#E4E67A',
    6: '#FFD200',
  },
  backgrounds: {
    1: '#F5E0AC',
    2: '#F0CFD4',
    3: '#C1DBE4',
    4: '#D4DDBE',
  },
  greys: {
    1: '#050001',
    2: '#3d4246',
    3: '#69727b',
    4: '#929292',
    5: '#AFAFAF',
    6: '#D7D9DB',
  },
  whites: {
    1: '#FFFFFF',
    2: '#f0f0f0',
    3: '#f7f4ef',
  },
};
