import React from 'react';
import { Container } from 'theme-ui';
import { useProductRecommendations } from '@backpackjs/storefront';
import 'swiper/css/bundle';

import { ProductSwiper } from '@snippets';

export function ProductRecommendations({ cms }) {
  const { recommendations } = useProductRecommendations({ limit: cms.limit });

  return (
    recommendations?.length && (
      <Container
        data-comp={ProductRecommendations.displayName}
        variant="layout.container"
        sx={{
          overflowX: 'clip',
          overflowY: 'visible',
          mb: [9, null, null, null, null, null, 13],
        }}
      >
        <ProductSwiper
          products={recommendations}
          heading={cms.heading}
          hidePrices={cms.hidePrices}
          hideReviews={cms.hideReviews}
        />
      </Container>
    )
  );
}

ProductRecommendations.displayName = 'ProductRecommendations';

ProductRecommendations.Schema = {
  label: 'Product Recommendations',
  key: 'product-recommendations',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'limit',
      label: 'Number of Recommended Products',
      component: 'number',
      default: 4,
    },
  ],
};
