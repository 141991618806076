import { breakpoints } from './theme.breakpoints';
import { box } from './theme.box';
import { buttons } from './theme.buttons';
import { colors } from './theme.colors';
import { flex } from './theme.flex';
import { fontSizes } from './theme.fontSizes';
import { fontWeights } from './theme.fontWeights';
import { fonts } from './theme.fonts';
import { forms } from './theme.forms';
import { layout } from './theme.layout';
import { letterSpacings } from './theme.letterSpacings';
import { lineHeights } from './theme.lineHeights';
import { shadows } from './theme.shadows';
import { space } from './theme.space';
import { styles } from './theme.styles';
import { text } from './theme.text';
import { alerts } from './theme.alerts';

export default {
  // Add more if needed
  breakpoints,
  box,
  buttons,
  colors,
  flex,
  fontSizes,
  fontWeights,
  fonts,
  forms,
  layout,
  letterSpacings,
  lineHeights,
  shadows,
  space,
  styles,
  text,
  alerts,
  // Add more if needed
};
