export default {
  '.rc-collapse-motion': {
    transition: 'height 0.3s, opacity 0.3s',
  },

  '.rc-collapse': {
    borderBottom: '1px solid #DCDCDC',
    borderTop: '1px solid #DCDCDC',
  },

  '.rc-collapse > .rc-collapse-item': {
    borderTop: '1px solid #DCDCDC',
  },

  '.rc-collapse > .rc-collapse-item:first-child': {
    borderTop: 'none',
  },

  '.rc-collapse > .rc-collapse-item > .rc-collapse-header': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '.25px',
    lineHeight: '20px',
    padding: '12px 0px',
    position: 'relative',
    cursor: 'pointer',
    // yolo
    outline: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },

  '.rc-collapse .arrow': {
    backgroundImage: `url(/svg/expand.svg)`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 'calc(50% - 8px)',
    right: 0,
    width: 14,
    height: 14,
  },

  '.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow': {
    backgroundImage: `url(/svg/collapse.svg)`,
  },

  '.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra': {
    margin: '0 16px 0 auto',
  },

  '.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only': {
    cursor: 'default',
  },

  '.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only .rc-collapse-header-text':
    {
      cursor: 'pointer',
    },

  '.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header': {
    cursor: 'not-allowed',
    backgroundColor: '#f3f3f3',
  },

  '.rc-collapse-content': {
    overflow: 'hidden',
    maxHeight: 0,
    transition: 'max-height 0.3s ease-in-out',
    padding: '0',
    backgroundColor: '#fff',
  },

  '.rc-collapse-content > .rc-collapse-content-box': {
    marginTop: '4px',
    marginBottom: '16px',
  },

  '.rc-collapse-content-hidden': {
    display: 'none',
  },

  '.rc-collapse-content-active': {
    maxHeight: '9999rem',
  },
};
