import { Container } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css/bundle';

import { PrimaryHero } from '@sections/PrimaryHero';

export function PrimaryHeroSlider({ cms }) {
  return (
    <Container
      data-comp="PrimaryHeroSlider"
      variant="layout.full"
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <Swiper
        loop
        modules={[Navigation, Pagination, A11y]}
        pagination={{ clickable: true }}
        effect="fade"
        autoHeight
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        fadeEffect={{ crossFade: true }}
        css={{
          '.swiper-pagination': {
            bottom: '30px',
            left: '20px',
            textAlign: 'left',
            '.swiper-pagination-bullet': {
              width: '10px',
              height: '10px',
              opacity: 1,
              background: 'transparent',
              border: 'thin solid',
              borderColor: 'black',
              transition: 'all .25s ease-in-out',
              '&.swiper-pagination-bullet-active': {
                backgroundColor: 'black',
              },
            },
          },
        }}
      >
        {cms?.slides?.length &&
          cms?.slides?.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <PrimaryHero cms={slide} isSlide />
              </SwiperSlide>
            );
          })}

        <div className="swiper-button-prev" css={buttonStyle} />
        <div className="swiper-button-next" css={buttonStyle} />
      </Swiper>
    </Container>
  );
}

/**
 * This is to use the existing Primary Hero Schema without needing to redefine
 * everything here. But need to exclude out the global stuff.
 */
const primaryHeroFields = PrimaryHero.Schema.fields.filter((field) => {
  if (field.name === 'sectionVisibility') return false;
  if (field.name === 'sectionType') return false;
  if (field.name === 'containerSettings') return false;

  return true;
});

PrimaryHeroSlider.Schema = {
  label: 'Primary Hero Slider',
  key: 'primary-hero-slider',
  fields: [
    {
      label: 'Slides',
      name: 'slides',
      component: 'group-list',
      itemProps: {
        label: '{{item.textSettings.heading}}',
      },
      defaultItem: {
        imageSettings: {
          image: {
            src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
            alt: 'Hero Image',
          },
          desktopRatio: '5:12',
          mobileRatio: '1:1',
          mobileImage: {
            src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
            alt: 'Hero Image',
          },
        },
        textSettings: {
          superheading: 'Superheading',
          heading: 'Primary Hero Heading',
          subheading: 'Primary Hero Subheading',
          textColor: 'black',
          mobileTextColor: 'black',
          textAlign: 'center',
        },
        buttonSettings: {
          cta: {
            url: '#',
            text: 'Shop Now',
          },
          buttonStyle: 'primary',
          cta2: {
            url: '#',
            text: '',
          },
          buttonTwoStyle: 'primary',
        },
      },
      fields: [...primaryHeroFields],
      defaultValue: [
        {
          imageSettings: {
            image: {
              src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
              alt: 'Hero Image',
            },
            desktopRatio: '5:12',
            mobileRatio: '1:1',
            mobileImage: {
              src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
              alt: 'Hero Image',
            },
          },
          textSettings: {
            superheading: 'Superheading',
            heading: 'Primary Hero Heading',
            subheading: 'Primary Hero Subheading',
            textColor: 'black',
            mobileTextColor: 'black',
            textAlign: 'center',
          },
          buttonSettings: {
            cta: {
              url: '#',
              text: 'Shop Now',
            },
            buttonStyle: 'primary',
            cta2: {
              url: '#',
              text: '',
            },
            buttonTwoStyle: 'primary',
          },
        },
      ],
    },
  ],
  defaultValue: {
    slides: [
      {
        imageSettings: {
          image: {
            src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
            alt: 'Hero Image',
          },
          desktopRatio: '5:12',
          mobileRatio: '1:1',
          mobileImage: {
            src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
            alt: 'Hero Image',
          },
        },
        textSettings: {
          superheading: 'Superheading',
          heading: 'Primary Hero Heading',
          subheading: 'Primary Hero Subheading',
          textColor: 'black',
          mobileTextColor: 'black',
          textAlign: 'center',
        },
        buttonSettings: {
          cta: {
            url: '#',
            text: 'Shop Now',
          },
          buttonStyle: 'primary',
          cta2: {
            url: '#',
            text: '',
          },
          buttonTwoStyle: 'primary',
        },
      },
    ],
  },
};

const buttonStyle = {
  background: 'white',
  color: 'black',
  opacity: 0.8,
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  fontSize: '20px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
  '&:after': {
    fontSize: '20px',
  },
  '@media only screen and (max-width: 991px)': {
    height: '40px',
    width: '40px',
    fontSize: '16px',
    '&:after': {
      fontSize: '16px',
    },
  },
  '&:hover': {
    opacity: 1,
  },
};
