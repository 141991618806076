import { useState, useEffect } from 'react';
import { Box, Button } from 'theme-ui';
import Cookies from 'js-cookie';
import { Markdown } from '@snippets';

const getCookieValue = (name = '_BPLocationMessage') => {
  const cookieValue = Cookies.get(name);

  return cookieValue;
};

const setCookie = (cookieName, cookieValue) => {
  const sameSite = 'strict';

  const cookieOptions = {
    expires: 1,
    sameSite,
    secure: location ? location.protocol === 'https:' : true,
  };

  Cookies.set(cookieName, cookieValue, cookieOptions);
};

export function LocationMessage({ settings }) {
  if (!settings.enabled) return null;

  const [userLoc, setLocation] = useState(null);
  const [active, setActive] = useState(true);

  useEffect(() => {
    const currentCookie = getCookieValue();

    if (currentCookie) {
      setActive(false);
    }
  }, []);

  useEffect(() => {
    fetch('/api/loc-test')
      .then((data) => {
        return data.json();
      })
      .then((location) => {
        if (location['x-country']) {
          setLocation(location['x-country']);
        }
      });
  }, [settings]);

  const dismissMessage = () => {
    setCookie('_BPLocationMessage', true);
    setActive(false);
  };

  return (
    settings &&
    settings?.locations?.map((location) => {
      if (active && location.countryCode === userLoc) {
        return (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              p: 10,
              zIndex: 50,
              maxWidth: ['100%', null, null, null, null, '400px'],
            }}
          >
            <Box
              sx={{
                p: 10,
                pr: 12,
                backgroundColor: 'white',
                borderRadius: '4px',
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.161)',
                position: 'relative',
              }}
            >
              <Button
                variant="none"
                onClick={dismissMessage}
                sx={{
                  backgroundColor: 'transparent',
                  color: 'black',
                  fontSize: 20,
                  position: 'absolute',
                  top: 2,
                  right: 5,
                  cursor: 'pointer',
                }}
              >
                &times;
              </Button>

              <Box
                variant="text.body.reg"
                sx={{
                  a: {
                    color: 'primary',
                  },
                }}
              >
                <Markdown text={location.message || ''} />
              </Box>
            </Box>
          </Box>
        );
      }
    })
  );
}
