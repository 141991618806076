import { useCallback, useState, useEffect } from 'react';
import Link from 'next/link';
import { Box, Flex, Image, Paragraph } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import {
  useCartAddItem,
  useLocalizedVariant,
  useProductByHandle,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';
import { StarBadges } from '@snippets/Stamped';
import { useDataLayerActions } from '@hooks/datalayer';
// import { addToCartGtm } from '../../global/DataLayer';

function addZeroes(num) {
  const dec = num.split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

export function ProductItem({
  handle,
  fromCollection,
  product: passedProduct,
  index,
  isSearchResults = false,
}) {
  const { sendClickProductItemEvent } = useDataLayerActions();

  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product: fetchedProduct } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const product =
    fetchedProduct || (passedProduct?.loaded ? passedProduct : null);

  const { inventory } = useProductInventoryByHandle({
    handle,
    withInventory: false,
    fetchOnMount: inView,
  });

  const isAvailable = inventory?.availableForSale;

  const soldOut =
    !isAvailable ||
    !product?.variants?.[0] ||
    !product?.variants?.[0]?.availableForSale;

  const [activeImage, setActiveImage] = useState(product?.featuredImage);
  const [hovered, setHovered] = useState(false);

  const { cartAddItem } = useCartAddItem();
  const setModal = useSetRecoilState(store.modal);
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const toggleCart = useCallback(() => {
    setSideDrawer('cart');
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
  }, []);

  const addToCart = async (e) => {
    e.preventDefault();
    const firstVariant = product?.variants[0];

    if (!firstVariant || !firstVariant.availableForSale) return;

    if (!firstVariant?.id) {
      return console.log('Missing selectedVariant id');
    }

    toggleCart();
    setModal(false); // in case it's open
    // addToCartGtm({ selectedVariant: firstVariant });
    await cartAddItem({
      merchandiseId: firstVariant.id,
      quantity: 1,
    });
  };

  const { localized } = useLocalizedVariant({
    variant: product?.variants?.[0],
  });

  const switchImageOnHoverChange = () => {
    const haveMultipleImages = product?.images?.length > 1;
    const haveAtLeastOneImage = product?.images?.length > 1;
    if (hovered) {
      // show first product image
      haveMultipleImages && setActiveImage(product?.images?.[1]);
    } else {
      // show second product image
      haveAtLeastOneImage &&
        activeImage !== 0 &&
        setActiveImage(product?.images?.[0]);
    }
  };

  const getPriceOffPercentage = (price, compareAtPrice) => {
    const totalValue = 100 * ((compareAtPrice - price) / compareAtPrice);
    return `${totalValue.toFixed(0)}% Off`;
  };

  useEffect(() => {
    const defaultImage = product?.images?.length
      ? product?.images[0]
      : product?.variants?.image || null;

    setActiveImage(defaultImage);
  }, [product]);

  useEffect(() => {
    switchImageOnHoverChange();
  }, [hovered, product]);

  return (
    <Link
      data-comp="ProductItem"
      key={handle}
      href={encodeURI(`/products/${handle}`)}
      sx={{ minHeight: 0 }}
    >
      <Box
        ref={ref}
        sx={{
          cursor: 'pointer',
          border: '1px solid',
          borderRadius: '4px',
          borderColor: (t) => t.colors.black,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          maxHeight: '480px',
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={() => {
          if (fromCollection) {
            PubSub.publish('COLLECTION_PRODUCT_SELECTED', product);
          }
          sendClickProductItemEvent({
            isSearchResults,
            listIndex: index,
            localized,
            product: product,
            selectedVariant: product?.variants[0],
          });
        }}
      >
        {/* Images */}
        {activeImage ? (
          <Box
            sx={{
              position: 'relative',
              borderRadius: '3px 3px 0 0',
              overflow: 'hidden',
            }}
          >
            <Image
              src={activeImage?.originalSrc}
              alt={activeImage?.altText || product?.title}
              width={activeImage?.width}
              height={activeImage?.height}
              sx={{
                width: '100%',
                maxWidth: ['600px', null, '100%', null, '1024px', '100%'],
                backgroundColor: (t) => t.colors.lightGrey,
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              pb: '135%',
              backgroundColor: (t) => t.colors.white,
            }}
          />
        )}

        {product?.variants?.[0]?.compareAtPrice ? (
          <Box
            sx={{
              width: '100px',
              height: '100px',
              overflow: 'hidden',
              position: 'absolute',
              top: '-10px',
              left: '-10px',
              '&::before, &::after': {
                position: 'absolute',
                zIndex: '-1',
                content: '""',
                display: 'block',
                border: '5px solid',
                borderColor: 'primary',
              },
              '&::before': {
                top: 0,
                right: 0,
              },
              '&::after': {
                bottom: 0,
                left: 0,
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'block',
                width: '225px',
                padding: '15px 0',
                backgroundColor: 'primary',
                boxShadow: '0 5px 10px rgba(0,0,0,.1)',
                color: '#fff',
                textShadow: '0 1px 1px rgba(0,0,0,.2)',
                textTransform: 'uppercase',
                textAlign: 'center',
                right: '-46px',
                top: '8px',
                fontWeight: '600',
                fontSize: 4,
                transform: 'rotate(-45deg)',
              }}
            >
              {getPriceOffPercentage(
                product?.variants?.[0]?.priceV2?.amount,
                product?.variants?.[0]?.compareAtPriceV2?.amount
              )}
            </Box>
          </Box>
        ) : null}

        {/* Title, Price + Swatches */}
        <Flex
          variant="flex.column"
          sx={{
            px: 9,
            py: 4,
            flexGrow: 1,
            position: 'relative',
            zIndex: 4,
            backgroundColor: 'white',
            borderRadius: '0 0 3px 3px',
            justifyContent: 'space-between',
            minHeight: '145px',
          }}
        >
          <Box>
            {/* Reviews */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              {product?.id && <StarBadges product={product} />}
            </Box>

            <Paragraph
              variant="text.h5.r.1"
              sx={{
                color: hovered ? 'primary' : 'black',
              }}
            >
              {product?.title}
            </Paragraph>

            <Paragraph
              variant="text.body.xs"
              sx={{
                mt: 4,
                height: '50px',
                overflow: 'hidden',
                color: (t) => t.colors.greys[4],
              }}
            >
              {product?.description?.slice(0, 80)}...
            </Paragraph>
          </Box>

          <Box
            sx={{
              flexShrink: 0,
              textAlign: 'left',
            }}
          >
            {product?.variants?.[0]?.priceV2?.amount ? (
              <Paragraph as="span" variant="text.label.3" sx={{ mr: 4 }}>
                ${addZeroes(product?.variants?.[0]?.priceV2?.amount)}
              </Paragraph>
            ) : null}

            {product?.variants?.[0]?.compareAtPrice ? (
              <Paragraph
                as="span"
                variant="text.label.3"
                sx={{
                  textDecoration: product?.variants?.[0]?.compareAtPriceV2
                    ?.amount
                    ? 'line-through'
                    : 'none',
                  color: product?.variants?.[0]?.compareAtPriceV2?.amount
                    ? (t) => t.colors.red
                    : 'black',
                }}
              >
                $
                {addZeroes(product?.variants?.[0]?.compareAtPriceV2?.amount) ||
                  0}
              </Paragraph>
            ) : null}
          </Box>
        </Flex>

        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            backgroundColor: soldOut ? 'greys.4' : 'primary',
            justifyContent: 'center',
            alignItems: 'center',
            width: '44px',
            height: '44px',
            top: 9,
            right: 9,
            transition: 'all .25s ease-in',
            borderRadius: '50%',
            zIndex: 3,
            opacity: [
              1,
              null,
              null,
              null,
              hovered
                ? soldOut
                  ? 0.65
                  : [1, null, null, null, null, null, 1]
                : 0,
            ],
            '&:hover': {
              backgroundColor: soldOut ? 'greys.4' : '#D83CD8',
            },
            pointerEvents: soldOut ? 'none' : 'all',
            cursor: soldOut ? 'not-allowed' : 'pointer',
          }}
          onClick={addToCart}
        >
          <Image
            alt="Add to cart icon"
            src="/svg/cart-white.svg"
            width="14px"
          />
        </Box>
      </Box>
    </Link>
  );
}
