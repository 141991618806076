export const forms = {
  label: {
    variant: 'text.label.1',
    textTransform: 'uppercase',
    m: 0,
  },
  account: {
    input: {
      variant: 'text.16.1',
      p: 8,
      mb: [9],
      border: (t) => `1px solid ${t.colors.borderGrey}`,
      borderRadius: 0,
      ':focus': {
        outline: 'none !important',
        border: (t) => `1px solid ${t.colors.borderGrey}`,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);',
      },
    },
  },
  search: {
    variant: 'text.16.1',
    pt: 8,
    pb: 7,
    mb: ['40px'],
    pl: '40px',
    pr: 8,
    border: (t) => `1px solid ${t.colors.borderGrey}`,
    borderRadius: 0,
    ':focus': {
      outline: 'none !important',
      border: (t) => `1px solid ${t.colors.borderGrey}`,
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);',
    },
  },
};
