import accountSettings from './account';
import analytics from './analytics';
import cartSettings from './cart.js';
import footerSettings from './footer.js';
import headerSettings from './header.js';
import locationSettings from './location';
import productSettings from './product';
import searchSettings from './search.js';
import socialSettings from './social.js';

export * as common from './common';

export default [
  analytics,
  locationSettings,
  headerSettings,
  footerSettings,
  cartSettings,
  accountSettings,
  productSettings,
  searchSettings,
  socialSettings,
  {
    label: 'Redirect',
    name: 'redirect',
    component: 'group',
    fields: [
      {
        name: 'redirectEnabled',
        label: 'Redirect Enabled',
        component: 'toggle',
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
        defaultValue: false,
      },
      {
        name: 'redirectTo',
        label: 'Redirect To',
        component: 'text',
      },
      {
        name: 'redirectExclude',
        label: 'Exclude These',
        component: 'tags',
      },
    ],
  },
  {
    label: 'Hide Header and Footer',
    name: 'hideHeaderFooter',
    component: 'group',
    fields: [
      {
        name: 'hideHeaderFooterUrls',
        label: 'On these URLs',
        component: 'tags',
      },
    ],
  },
];
