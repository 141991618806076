import { Image, Flex } from 'theme-ui';

import store, { useRecoilValue, useSetRecoilState } from '@store';

export function MenuToggle() {
  const menuDrawerOpen = useRecoilValue(store.menuDrawerOpen);
  const toggleMenuDrawer$ = useSetRecoilState(store.toggleMenuDrawer$);
  const activeIcon = menuDrawerOpen ? 'close' : 'burger';

  return (
    <Flex
      onClick={() => {
        toggleMenuDrawer$();
      }}
      sx={{
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'flex-start',
        justifySelf: 'flex-start',
        display: ['flex', null, null, null, 'none'],
      }}
    >
      <Image
        src={`/svg/${activeIcon}.svg`}
        alt={activeIcon}
        sx={{
          width: '16px',
        }}
      />
    </Flex>
  );
}
