import { useState, useCallback, useMemo } from 'react';
import { setsIntersection } from '@utils';

export const useCollectionFilter = ({ collection, activeSort = null }) => {
  const defaultFiltersState = collection?.filters
    ? Object.keys(collection.filters).reduce((initFilter, filterKey) => {
        // set all filterGroups to an empty set
        initFilter[filterKey] = new Set([]);
        return initFilter;
      }, {})
    : {};

  const [activeFilters, setActiveFilter] = useState(defaultFiltersState);
  const [activeFiltersCount, setActiveFilterCount] = useState(0);

  const lastActiveColorFilter = activeFilters?.Color
    ? Array.from(activeFilters.Color).pop()
    : null;

  const resetFilters = useCallback(() => {
    setActiveFilterCount(0);
    setActiveFilter(defaultFiltersState);
  });

  const allHandlesSet = useMemo(() => {
    return activeSort && collection.sorts[activeSort]
      ? collection.sorts[activeSort]
      : collection?.products
      ? new Set(
          collection.products
            .map((product) => product?.handle || false)
            .filter(Boolean)
        )
      : new Set([]);
  }, [collection?.handle, activeSort]);

  // main filtering function which returns a set of filtered handles based on chose filterGroups and options
  const filteredHandles = useMemo(() => {
    let filteredHandles = Array.from(allHandlesSet);

    const activeFilterGroups = activeFilters
      ? Object.keys(activeFilters).reduce((handleSets, currentFilterKey) => {
          const currentFilterSet = activeFilters[currentFilterKey];
          const currentFilterActive = currentFilterSet.size > 0;
          let currentFilterOptionKeys = []; // will hold an array of active filter options for a given filter group
          let currentFilterHandles = []; // will hold the unique active handles for a given filter group

          if (!currentFilterActive) return handleSets;

          // filterGroup has active filters
          currentFilterOptionKeys = Array.from(currentFilterSet); // asphalt, black...

          // get unique combined handles for a given filterGroup based on the active options
          currentFilterHandles = currentFilterOptionKeys.reduce(
            (currentFilterHandles, filterOption) => {
              // merge unique handles for all options selected i.e black handles and charcoal handles
              currentFilterHandles = new Set([
                ...Array.from(currentFilterHandles),
                ...collection.filters[currentFilterKey][filterOption],
              ]);
              return currentFilterHandles;
            },
            []
          );

          handleSets[currentFilterKey] = currentFilterHandles; // set of all active color/size handles..

          return handleSets;
        }, {})
      : {};

    const activeFilterGroupsKeys = Object.keys(activeFilterGroups);

    if (activeFilterGroupsKeys?.length) {
      filteredHandles = Array.from(
        activeFilterGroupsKeys.reduce((handlesSet, filterGroupKey) => {
          // AND operation between filterGroup
          if (!handlesSet.size) handlesSet = activeFilterGroups[filterGroupKey];
          else {
            handlesSet = setsIntersection(
              handlesSet,
              activeFilterGroups[filterGroupKey]
            );
          }
          return handlesSet;
        }, new Set([]))
      );
    }

    return filteredHandles;
  }, [collection?.handle, activeFiltersCount, activeFilters, activeSort]);

  return [
    // state
    { activeFilters, lastActiveColorFilter, filteredHandles },
    // actions
    { setActiveFilterCount, setActiveFilter, resetFilters },
  ];
};
