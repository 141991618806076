import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { Box, Paragraph, Image } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';

export function MenuItems() {
  const siteSettings = useSettings();
  const { header } = siteSettings;
  const toggleMenuDrawer$ = useSetRecoilState(store.toggleMenuDrawer$);
  const router = useRouter();
  const goToLinkAndToggle = useCallback((menuLink) => {
    if (menuLink?.link?.url) {
      // navigate
      router.push(menuLink.link.url);
      // close the drawer
      toggleMenuDrawer$(false);
    }
  });

  return (
    <Box as="nav" data-comp="MenuItems">
      <Box as="ul" sx={{ listStyleType: 'none', px: 0 }}>
        {header?.headerMenu?.map((item) => {
          return (
            <Box
              as="li"
              key={uuidv4()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 7,
                borderTop: 'thin solid',
                borderBottom: 'thin solid',
                borderColor: (t) => t.colors.greys[6],
                mt: '-1px',
                cursor: 'pointer',
              }}
            >
              <Paragraph
                as="a"
                onClick={() => goToLinkAndToggle(item)}
                variant="text.label.2"
                sx={{
                  color: 'black',
                }}
              >
                {item?.link?.text}
              </Paragraph>

              <Image
                alt="Link Chevron"
                src="/svg/right-arrow.svg"
                height="14px"
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
