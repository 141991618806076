import { Box, Paragraph, Flex } from 'theme-ui';
import Collapse, { Panel } from 'rc-collapse';

import rcCollapseCss from '@utils/rc-collapse.css.js';
import { Markdown } from '@snippets';

export function AccordionBlock({ cms: { heading, accordions, onePanelOpen } }) {
  return (
    <Box
      data-comp={AccordionBlock.displayName}
      sx={{
        maxWidth: '800px',
        width: '100%',
        mb: [9, null, null, null, null, null, 13],
        px: [9, null, null, null, null, null, 0],
        mx: 'auto',
      }}
      css={rcCollapseCss}
    >
      {heading && (
        <Flex
          variant="flex.column.center"
          sx={{
            mb: [4, null, null, null, 12],
          }}
        >
          <Paragraph
            variant="text.h2.r.1"
            sx={{
              color: 'primary',
              textAlign: 'center',
            }}
          >
            {heading}
          </Paragraph>
        </Flex>
      )}

      <Collapse accordion={onePanelOpen}>
        {accordions?.map(({ heading: accordionHeading, body }, index) => {
          return (
            <Panel
              key={index}
              header={
                <Paragraph
                  sx={{
                    fontWeight: 600,
                    mr: 8,
                  }}
                >
                  {accordionHeading}
                </Paragraph>
              }
            >
              <Flex variant="flex.column">
                <Markdown text={body} />
              </Flex>
            </Panel>
          );
        })}
      </Collapse>
    </Box>
  );
}

AccordionBlock.displayName = 'AccordionBlock';

AccordionBlock.Schema = {
  label: 'Accordion Block',
  key: 'accordion-block',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'Accordion Block',
    },
    {
      name: 'accordions',
      label: 'Accordion Panels',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      defaultItem: {
        heading: 'Item heading',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
      },
      fields: [
        {
          name: 'title',
          label: 'Item Title',
          component: 'text',
          defaultValue: 'Item Title',
        },
        {
          name: 'heading',
          label: 'Panel Heading',
          component: 'text',
          defaultValue: 'Item heading',
        },
        {
          name: 'body',
          label: 'Panel Body',
          component: 'markdown',
          defaultValue:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
        },
      ],
      defaultValue: [
        {
          heading: 'Item Heading 1',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
        },
        {
          heading: 'Item Heading 2',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
        },
      ],
    },
    {
      name: 'onePanelOpen',
      label: 'One Panel Open At A Time',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
  ],
};
