import { store } from '@backpackjs/storefront';

const defaultAria = {
  main: { 'aria-hidden': 'false', tabIndex: '0' },
  cart: { 'aria-hidden': 'true', tabIndex: '-1' },
  menu: { 'aria-hidden': 'true', tabIndex: '-1' },
  menuSecondary: { 'aria-hidden': 'true', tabIndex: '-1' },
  modal: { 'aria-hidden': 'true', tabIndex: '-1' },
};

export const aria = store.recoil.atom({
  key: 'aria/aria',
  default: defaultAria,
});

const resetAria$ = store.recoil.selector({
  key: 'aria/resetAria$',
  set: ({ reset }) => {
    reset(aria);
  },
  get: ({ get }) => {},
});

export default {
  aria,
  resetAria$,
};
