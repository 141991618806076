import { AccordionBlock } from './AccordionBlock';
import { FeatureDescriptionImages } from './FeatureDescriptionImages';
import { FiftyFiftyTile } from './FiftyFiftyTile';
import { ImageBlock } from './ImageBlock';
import { ImageCtas } from './ImageCtas';
import { ImageText } from './ImageText';
import { InfoTiles } from './InfoTiles';
import { Instagram } from './Instagram';
import { LinksBlock } from './LinksBlock';
import { MarkdownBody } from './MarkdownBody';
import { PrimaryHero } from './PrimaryHero';
import { PrimaryHeroSlider } from './PrimaryHeroSlider';
import { ProductBundle } from './ProductBundle';
import { ProductGrid } from './ProductGrid';
import { ProductList } from './ProductList';
import { ProductMeta } from './ProductMeta';
import { ProductRecommendations } from './ProductRecommendations';
import { RawHTMLBody } from './RawHTMLBody';
import { SecondaryHero } from './SecondaryHero';
import { StampedReviewWidget } from './StampedReviewWidget';
import { ThreeDViewer } from './ThreeDViewer';
import { VideoBlock } from './VideoBlock';

export default [
  AccordionBlock,
  FiftyFiftyTile,
  FeatureDescriptionImages,
  ImageBlock,
  ImageCtas,
  ImageText,
  InfoTiles,
  Instagram,
  LinksBlock,
  MarkdownBody,
  PrimaryHero,
  PrimaryHeroSlider,
  ProductBundle,
  ProductGrid,
  ProductList,
  ProductMeta,
  ProductRecommendations,
  RawHTMLBody,
  SecondaryHero,
  StampedReviewWidget,
  ThreeDViewer,
  VideoBlock,
];
