import { store } from '@backpackjs/storefront';
import { searchDrawerOpen } from './Search';
import { menuDrawerOpen } from './Menu';
import { cartDrawerOpen } from './Cart';
import { aria } from './Aria';

export const settings = store.recoil.atom({
  key: 'global/settings',
  default: null,
  effects_UNSTABLE: [store.effects.logStateChange('settings')],
});

export const updateSettings = store.recoil.selector({
  key: `global/updateSettings`,
  set: ({ set }, currentSettings) => {
    set(settings, currentSettings);
  },
  get: ({ get }) => {
    return get(settings);
  },
});

export const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
});

export const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
});

const hideAllDrawers$ = store.recoil.selector({
  key: 'global/hideAllDrawers$',
  get: ({ get }) => {},

  set: ({ set, reset }) => {
    set(overlay, null);
    set(modal, null);

    set(sideDrawer, false);
    set(cartDrawerOpen, false);
    set(searchDrawerOpen, false);
    set(menuDrawerOpen, false);
    reset(aria);
  },
});

const sideDrawer = store.recoil.atom({
  key: 'menu/sideDrawer',
  default: false,
  effects_UNSTABLE: [
    // store.effects.logStateChange('sideDrawer')
  ],
});

const sideDrawerIsCart$ = store.recoil.selector({
  key: 'global/sideDrawerIsCart$',
  get: ({ get }) => {
    const _sideDrawer = get(sideDrawer);
    return _sideDrawer === 'cart';
  },
});

const sideDrawerIsSearch$ = store.recoil.selector({
  key: 'global/sideDrawerIsSearch$',
  get: ({ get }) => {
    const _sideDrawer = get(sideDrawer);
    return _sideDrawer === 'search';
  },
});

export default {
  overlay,
  modal,
  settings,
  updateSettings,
  // Side Drawer
  sideDrawer,
  hideAllDrawers$,
  sideDrawerIsCart$,
  sideDrawerIsSearch$,
};
