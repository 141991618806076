import { useEffect, useState, useRef } from 'react';
import { Box, Container } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import Player from '@vimeo/player';

export function VideoBlock({ cms }) {
  const videoBoxRef = useRef(null);

  const genericVideoRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const [paused, setPaused] = useState(false);

  const handleVideoClick = () => {
    if (paused) {
      genericVideoRef.current.play();
      setPaused(false);
    } else {
      genericVideoRef.current.pause();
      setPaused(true);
    }
  };

  useEffect(() => {
    if (!cms.vimeoLink || !videoBoxRef.current) {
      return;
    }

    // Some settings wont work unless (PRO, or Business members)
    new Player(videoBoxRef.current, {
      id: cms.vimeoLink,
      loop: true,
      autoplay: true,
      controls: false,
      background: true,
      responsive: true,
      muted: true,
      quality: '540p',
    });
  }, [cms.vimeoLink, videoBoxRef]);

  return (
    <Container
      data-comp={VideoBlock.displayName}
      variant={cms.fullWidth ? 'layout.full' : 'layout.container'}
      sx={{
        width: '100%',
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      {cms.genericVideoLink ? (
        <Box
          ref={ref}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            as="video"
            ref={genericVideoRef}
            muted
            playsInline
            loop
            autoPlay={inView}
            sx={{
              height: '100%',
              width: '100%',
            }}
            onClick={handleVideoClick}
          >
            {inView && (
              <source
                key={cms.genericVideoLink}
                src={cms.genericVideoLink}
                type="video/mp4"
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          ref={videoBoxRef}
          sx={{
            width: '100%',
          }}
        />
      )}
    </Container>
  );
}

VideoBlock.displayName = 'VideoBlock';

VideoBlock.Schema = {
  label: 'Video Block',
  key: 'video-block',
  fields: [
    {
      name: 'vimeoLink',
      label: 'Vimeo Link',
      component: 'text',
    },
    {
      name: 'genericVideoLink',
      label: 'Video Link',
      component: 'text',
      description:
        'Adding a generic video url will overwrite the carousel, single image, and vimeo video.',
    },
    {
      name: 'fullWidth',
      component: 'toggle',
      label: 'Full Width',
      description: 'Section to be full width or not',
      toggleLabels: {
        true: 'Full Width',
        false: 'Contained',
      },
      defaultValue: false,
    },
  ],
};
