import { Box, Button, Container, Paragraph } from 'theme-ui';
import Link from 'next/link';

import { BackpackImage } from '@snippets';
import Schema from './Schema';

export function InfoTiles({ cms }) {
  return (
    <Container
      data-comp={InfoTiles.displayName}
      variant={
        cms?.containerSettings?.fullWidth ? 'layout.full' : 'layout.container'
      }
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            `repeat(${cms?.mobileColumns || 1}, 1fr)`,
            null,
            null,
            null,
            `repeat(${cms?.columns || 2}, 1fr)`,
          ],
          columnGap: 10,
          rowGap: 10,
        }}
      >
        {cms?.tiles?.map((tile, index) => {
          const src = tile.tileImage?.src || tile.tileImage;
          return (
            <Box key={index}>
              {src ? (
                <Box
                  sx={{
                    backgroundColor: 'ghostwhite',
                    width: '100%',
                    display: 'block',
                    paddingBottom: '100%',
                    position: 'relative',
                    mb: [6, null, null, null, null, null, 10],
                  }}
                >
                  <BackpackImage
                    src={src}
                    layout="fill"
                    alt={tile.title}
                    sx={{
                      width: '100%',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                  />
                </Box>
              ) : null}

              {tile.title && (
                <Paragraph
                  variant="text.h2.r.1"
                  sx={{
                    color: 'primary',
                    mb: [3, null, null, null, null, null, 6],
                    textAlign: [
                      tile?.mobileTextAlign,
                      null,
                      null,
                      null,
                      null,
                      null,
                      tile?.desktopTextAlign,
                    ],
                  }}
                >
                  {tile.title}
                </Paragraph>
              )}

              {tile.subText && (
                <Paragraph
                  variant="text.body.r.1"
                  sx={{
                    maxWidth: '488px',
                    mr: [
                      tile?.mobileTextAlign === 'right' ? 0 : 'auto',
                      null,
                      null,
                      null,
                      null,
                      null,
                      tile?.desktopTextAlign === 'right' ? 0 : 'auto',
                    ],
                    ml: [
                      tile?.mobileTextAlign === 'left' ? 0 : 'auto',
                      null,
                      null,
                      null,
                      null,
                      null,
                      tile?.desktopTextAlign === 'left' ? 0 : 'auto',
                    ],
                    textAlign: [
                      tile?.mobileTextAlign,
                      null,
                      null,
                      null,
                      null,
                      null,
                      tile?.desktopTextAlign,
                    ],
                  }}
                >
                  {tile.subText}
                </Paragraph>
              )}

              {tile?.cta?.text && (
                <Box
                  sx={{
                    mt: [9, null, null, null, null, null, 10],
                    textAlign: [
                      tile?.mobileTextAlign,
                      null,
                      null,
                      null,
                      null,
                      null,
                      tile?.desktopTextAlign,
                    ],
                  }}
                >
                  <Link href={tile?.cta?.url || '#'}>
                    <Button variant="buttons.secondary">
                      {tile?.cta?.text || ''}
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

InfoTiles.displayName = 'InfoTiles';

InfoTiles.Schema = Schema;
