import { useState } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { Markdown } from '@snippets';

export function ProductMeta({ cms }) {
  const [active, setActive] = useState(0);

  return (
    <Box
      data-comp={ProductMeta.displayName}
      sx={{
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 2,
        lineHeight: 16,
        p: {
          mb: 5,
        },
        'p:last-of-type': {
          mb: 5,
        },
      }}
    >
      {cms?.additionalInfo?.length > 0 ? (
        <Flex
          sx={{
            flexWrap: 'wrap',
            mb: 4,
          }}
        >
          <Box
            sx={{
              mr: 5,
            }}
            onClick={() => setActive(0)}
          >
            <Paragraph
              variant="text.links.normal"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                transition: '0.25s ease-in-out',
                width: 'fit-content',
                '&::after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  backgroundColor: active === 0 ? 'primary' : 'black',
                  top: '100%',
                  width: active === 0 ? '100%' : 0,
                  height: '1px',
                  transition: '0.25s ease-in-out',
                },
                '&:hover::after': {
                  width: '100%',
                  backgroundColor: 'primary',
                },
              }}
            >
              {cms?.title || 'Description'}
            </Paragraph>
          </Box>

          {cms?.additionalInfo?.map((info, index) => {
            return (
              <Box
                key={`${info.id}${index}`}
                sx={{
                  mr: 5,
                }}
                onClick={() => setActive(index + 1)}
              >
                <Paragraph
                  variant="text.links.normal"
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    transition: '0.25s ease-in-out',
                    width: 'fit-content',
                    '&::after': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      backgroundColor:
                        active === index + 1 ? 'primary' : 'black',
                      top: '100%',
                      width: active === index + 1 ? '100%' : 0,
                      height: '1px',
                      transition: '0.25s ease-in-out',
                    },
                    '&:hover::after': {
                      width: '100%',
                      backgroundColor: 'primary',
                    },
                  }}
                >
                  {info.title}
                </Paragraph>
              </Box>
            );
          })}
        </Flex>
      ) : null}

      <Box
        sx={{
          display: active === 0 ? 'block' : 'none',
        }}
      >
        <Markdown text={cms?.description} />
      </Box>

      {cms?.additionalInfo?.length > 0
        ? cms?.additionalInfo?.map((info, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: index + 1 === active ? 'block' : 'none',
                }}
              >
                <Markdown text={info?.description} />
              </Box>
            );
          })
        : null}
    </Box>
  );
}

ProductMeta.displayName = 'ProductMeta';

ProductMeta.Schema = {
  label: 'Product Meta Information',
  key: 'product-meta',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Product Meta Title',
      defaultValue: 'Description',
    },
    {
      name: 'description',
      component: 'markdown',
      label: 'Product Description',
      defaultValue: 'This is a product description',
    },
    {
      name: 'additionalInfo',
      component: 'group-list',
      label: 'Additional Info',
      itemProps: {
        label: '{{item.title}}',
      },
      defaultItem: {
        title: 'New Info',
        description: 'This is a new info',
      },
      fields: [
        {
          name: 'title',
          component: 'text',
          label: 'Title',
        },
        {
          name: 'description',
          component: 'markdown',
          label: 'Description',
        },
      ],
    },
  ],
};
