import { store } from '@backpackjs/storefront';
import { overlay, modal } from './Global';
import { searchDrawerOpen } from './Search';
import { aria } from './Aria';

export const cartDrawerOpen = store.recoil.atom({
  key: 'cart/cartDrawerOpen',
  default: false,
});

const toggleCartDrawer$ = store.recoil.selector({
  key: 'cart/toggleCartDrawer$',
  get: ({ get }) => {},
  set: ({ set, get, reset }, newState) => {
    const _cartDrawerOpen = get(cartDrawerOpen);

    const toggleOpen = () => {
      set(cartDrawerOpen, true);
      set(searchDrawerOpen, false);
      set(modal, null);
      set(overlay, true);
      set(aria, {
        main: { 'aria-hidden': 'true', tabIndex: '-1' },
        cart: { 'aria-hidden': 'false', tabIndex: '0' },
        menu: { 'aria-hidden': 'true', tabIndex: '-1' },
        menuSecondary: { 'aria-hidden': 'true', tabIndex: '-1' },
        modal: { 'aria-hidden': 'true', tabIndex: '-1' },
      });
    };

    const toggleClose = () => {
      set(cartDrawerOpen, false);
      set(overlay, null);
      reset(aria);
    };

    typeof newState === 'boolean'
      ? newState
        ? toggleOpen()
        : toggleClose()
      : !_cartDrawerOpen
      ? toggleOpen()
      : toggleClose();
  },
});

export const addingToCartStatus = store.recoil.atom({
  key: 'cart/addingToCartStatus',
  default: {
    started: false,
    finished: false,
    success: false,
    errors: [],
  },
});

export default {
  cartDrawerOpen,
  addingToCartStatus,
  toggleCartDrawer$,
};
