import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCartUpdateAttributes } from '@backpackjs/storefront';

import { CartPage } from './CartPage';
import { CartDrawer } from './CartDrawer';

export function Cart({ isDrawer = false, ...props }) {
  const cartProps = { isDrawer };
  const { cartUpdateAttributes, ...updateAttrStatus } =
    useCartUpdateAttributes();

  useEffect(() => {
    // Add cookie data for Elevar conversion tracking
    const exec = async () => {
      const cookies =
        Object.fromEntries(
          decodeURIComponent(document.cookie ?? '')
            .split('; ')
            .map((c) => c.split('='))
        ) ?? {};

      const attributes = Object.keys(cookies)
        .filter(
          (key) =>
            ['_fbp', '_fbc', '_ga'].includes(key) || key.startsWith('_ga_')
        )
        .map((key) => ({ key: `_elevar_${key}`, value: cookies[key] }));

      await cartUpdateAttributes({
        attributes,
      });
    };
    exec().then();
  }, []);

  return isDrawer ? (
    <CartDrawer {...cartProps} {...props} />
  ) : (
    <CartPage {...cartProps} {...props} />
  );
}

Cart.displayName = 'Cart';

Cart.propTypes = {
  isDrawer: PropTypes.bool,
};
