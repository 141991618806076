import React, { useEffect, useState } from 'react';

function Shopify3DModelViewer({ alt, src, iosSrc, poster = null }) {
  const [viewerLoaded, setViewerLoaded] = useState(false);

  // load this heavy library dynamically
  useEffect(async () => {
    await import('@google/model-viewer/dist/model-viewer.min');
    setViewerLoaded(true);
  }, []);

  return viewerLoaded ? (
    <model-viewer
      style={{
        width: '100%',
        height: '300px',
      }}
      alt={alt}
      src={src}
      ios-src={iosSrc}
      poster={poster}
      shadow-intensity="1"
      camera-controls
      auto-rotate
      ar
    />
  ) : (
    <p> Loading </p>
  );
}

export { Shopify3DModelViewer };
