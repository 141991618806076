import { Flex, Image, Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';
import store, { useSetRecoilState } from '@store';

export function SocialLinks({ iconColor }) {
  const siteSettings = useSettings();
  const socialLinks = siteSettings?.socialLinks;

  return (
    <Flex as="ul" data-comp="SocialLinks" sx={{ listStyleType: 'none', px: 0 }}>
      {socialLinks?.length &&
        socialLinks.map((social) => {
          return (
            <SocialIcon
              network={social.network}
              handle={social.handle}
              key={social.network}
              size={20}
              iconColor={iconColor}
            />
          );
        })}
    </Flex>
  );
}

function SocialIcon({ network, iconColor, handle, size }) {
  const toggleMenuDrawer$ = useSetRecoilState(store.toggleMenuDrawer$);
  const networks = {
    facebook: {
      url: 'https://www.facebook.com/',
    },
    instagram: {
      url: 'https://www.instagram.com/',
    },
    tiktok: {
      url: 'https://www.tiktok.com/',
    },
    twitter: {
      url: 'https://www.twitter.com/',
    },
    pinterest: {
      url: 'https://www.pinterest.com/',
    },
    youtube: {
      url: 'https://www.youtube.com/',
    },
    vimeo: {
      url: 'https://www.vimeo.com/',
    },
  };

  const currentNetwork = networks[network];

  return (
    <Box
      as="li"
      data-comp={`SocialIcon-${network}`}
      onClick={() => {
        toggleMenuDrawer$(false);
      }}
      sx={{
        width: size,
        height: size,
        mr: 9,
        mb: 0,
        cursor: 'pointer',
      }}
    >
      <a
        href={
          network && networks[network] && handle
            ? `${currentNetwork.url}${handle}`
            : '#'
        }
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src={`/svg/${network}${iconColor ? `-${iconColor}` : ''}.svg`}
          width={size}
          height={size}
        />
      </a>
    </Box>
  );
}
