import Link from 'next/link';
import { Box, Button, Container } from 'theme-ui';

import { BackpackImage } from '@snippets';

export function ImageCtas({ cms }) {
  return (
    <Container
      data-comp={ImageCtas.displayName}
      variant={
        cms?.containerSettings?.fullWidth ? 'layout.full' : 'layout.container'
      }
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            null,
            null,
            null,
            `repeat(${cms?.columns || 2}, 1fr)`,
          ],
          columnGap: 10,
          rowGap: 10,
        }}
      >
        {cms?.tiles?.map((tile, index) => {
          return (
            <Box
              key={index}
              sx={{
                position: 'relative',
                ':hover .image-cta': {
                  opacity: 1,
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'ghostwhite',
                  width: '100%',
                  display: 'block',
                  paddingBottom: '100%',
                  position: 'relative',
                }}
              >
                <BackpackImage
                  src={tile.tileImage?.src || tile.tileImage}
                  layout="fill"
                  alt={tile.title}
                  sx={{
                    width: '100%',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                />
              </Box>

              {tile?.cta?.text && (
                <Box
                  className="image-cta"
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: 11,
                    opacity: [1, null, null, null, 0],
                    transition: 'opacity .25s ease-in-out',
                  }}
                >
                  <Link href={tile?.cta?.url || '#'}>
                    <Button variant="buttons.outline">
                      {tile?.cta?.text || ''}
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

ImageCtas.displayName = 'ImageCtas';

ImageCtas.Schema = {
  label: 'Image CTAs',
  key: 'image-ctas',
  fields: [
    {
      name: 'columns',
      label: 'Columns',
      component: 'number',
      defaultValue: 3,
    },
    {
      name: 'tiles',
      label: 'Tiles',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      defaultItem: {
        title: 'New Tile',
        tileImage: {
          src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DRY_SKIN_BUNDLE_2023.jpg?v=1681144464',
          filename: 'DRY_SKIN_BUNDLE_2023.jpg',
        },
        cta: {
          text: 'Shop Now',
          url: '#',
        },
      },
      fields: [
        {
          name: 'title',
          label: 'Title',
          component: 'text',
        },
        {
          name: 'tileImage',
          label: 'Image',
          component: 'image',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
        },
      ],
      defaultValue: [
        {
          title: 'Bundle',
          tileImage: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DRY_SKIN_BUNDLE_2023.jpg?v=1681144464',
            filename: 'DRY_SKIN_BUNDLE_2023.jpg',
          },
          cta: {
            text: 'Shop Now',
            url: '#',
          },
        },
        {
          title: 'New Tile',
          tileImage: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DRY_SKIN_BUNDLE_2023.jpg?v=1681144464',
            filename: 'DRY_SKIN_BUNDLE_2023.jpg',
          },
          cta: {
            text: 'Shop Now',
            url: '#',
          },
        },
        {
          title: 'New Tile',
          tileImage: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DRY_SKIN_BUNDLE_2023.jpg?v=1681144464',
            filename: 'DRY_SKIN_BUNDLE_2023.jpg',
          },
          cta: {
            text: 'Shop Now',
            url: '#',
          },
        },
      ],
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: false,
        },
      ],
    },
  ],
};
