// just so that we don't need to imports in components
import { store } from '@backpackjs/storefront';

import Aria from './Aria';
import Cart from './Cart';
import Global from './Global';
import Menu from './Menu';
import ProductSelect from './SelectedVariant';
import Search from './Search';
import SizeGuide from './SizeGuide';

export const { useRecoilState } = store.recoil;
export const { useRecoilStateLoadable } = store.recoil;
export const { useSetRecoilState } = store.recoil;
export const { useResetRecoilState } = store.recoil;
export const { useRecoilValue } = store.recoil;
export const { useRecoilValueLoadable } = store.recoil;
export const { useRecoilSnapshot } = store.recoil;
export const { useRecoilCallback } = store.recoil;
export const { atom } = store.recoil;
export const { atomFamily } = store.recoil;
export const { selector } = store.recoil;
export const { selectorFamily } = store.recoil;

// eslint-disable-next-line import/no-default-export
export default {
  ...Aria,
  ...Cart,
  ...Global,
  ...Menu,
  ...ProductSelect,
  ...Search,
  ...SizeGuide,
};
