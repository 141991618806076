import React from 'react';

// On cms sidebar open/close update swiper instance to adjust for the width change
export const useUpdateSwiperOnSidebarToggle = (swiper) => {
  const cms = null;
  // const { useCMS } = useSettings()
  // const cms = useCMS() || null

  React.useEffect(() => {
    if (!cms || !cms?.enabled || !swiper) return;
    const updateSwiper = (event) => {
      console.log('Updating swiper on', event.type);
      const t0 = requestTimeout(() => {
        swiper.update();
        swiper.updateSize();
        clearRequestTimeout(t0);
      }, 300);
    };
    cms.events.subscribe('sidebar:opened', updateSwiper);
    cms.events.subscribe('sidebar:closed', updateSwiper);
  }, [swiper]);
};
