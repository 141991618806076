import Head from 'next/head';

function HeadPreconnects() {
  const preconnects = [
    {
      key: 'https://cdn.shopify.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://cdn.shopify.com/',
    },
    {
      key: 'https://cdn.shopify.com/-preconnect',
      rel: 'preconnect',
      href: 'https://cdn.shopify.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://monorail-edge.shopifysvc.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://monorail-edge.shopifysvc.com/',
    },
    {
      key: 'https://monorail-edge.shopifysvc.com/-preconnect',
      rel: 'preconnect',
      href: 'https://monorail-edge.shopifysvc.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://www.googletagmanager.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://www.googletagmanager.com/',
    },
    {
      key: 'https://www.googletagmanager.com/-preconnect',
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://googleads.g.doubleclick.net/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://googleads.g.doubleclick.net/',
    },

    {
      key: 'https://googleads.g.doubleclick.net/-preconnect',
      rel: 'preconnect',
      href: 'https://googleads.g.doubleclick.net/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://www.googleadservices.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://www.googleadservices.com/',
    },

    {
      key: 'https://www.googleadservices.com/-preconnect',
      rel: 'preconnect',
      href: 'https://www.googleadservices.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://www.google-analytics.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://www.google-analytics.com/',
    },
    {
      key: 'https://www.google-analytics.com/-preconnect',
      rel: 'preconnect',
      href: 'https://www.google-analytics.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://fonts.googleapis.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://fonts.googleapis.com/',
    },

    {
      key: 'https://fonts.googleapis.com/-preconnect',
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://fonts.gstatic.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://fonts.gstatic.com/',
    },
    {
      key: 'https://fonts.gstatic.com/-preconnect',
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://ajax.googleapis.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://ajax.googleapis.com/',
    },
    {
      key: 'https://ajax.googleapis.com/-preconnect',
      rel: 'preconnect',
      href: 'https://ajax.googleapis.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://analytics.tiktok.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://analytics.tiktok.com/',
    },
    {
      key: 'https://analytics.tiktok.com/-preconnect',
      rel: 'preconnect',
      href: 'https://analytics.tiktok.com/',
      crossOrigin: 'anonymous',
    },

    {
      key: 'https://tr.snapchat.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://tr.snapchat.com/',
    },
    {
      key: 'https://tr.snapchat.com/-preconnect',
      rel: 'preconnect',
      href: 'https://tr.snapchat.com/',
      crossOrigin: 'anonymous',
    },

    {
      key: 'https://www.facebook.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://www.facebook.com/',
    },
    {
      key: 'https://www.facebook.com/-preconnect',
      rel: 'preconnect',
      href: 'https://www.facebook.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://connect.facebook.net/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://connect.facebook.net/',
    },
    {
      key: 'https://connect.facebook.net/-preconnect',
      rel: 'preconnect',
      href: 'https://connect.facebook.net/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://api.ipify.org/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://api.ipify.org/',
    },
    {
      key: 'https://api.ipify.org/-preconnect',
      rel: 'preconnect',
      href: 'https://api.ipify.org/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://cdn.polyfill.io/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://cdn.polyfill.io/',
    },
    {
      key: 'https://cdn.polyfill.io/-preconnect',
      rel: 'preconnect',
      href: 'https://cdn.polyfill.io/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://cdnjs.cloudflare.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://cdnjs.cloudflare.com/',
    },

    {
      key: 'https://cdnjs.cloudflare.com/-preconnect',
      rel: 'preconnect',
      href: 'https://cdnjs.cloudflare.com/',
      crossOrigin: 'anonymous',
    },

    {
      key: 'good-molecules.myshopify.com-prefetch',
      rel: 'dns-prefetch',
      href: 'good-molecules.myshopify.com',
    },
    {
      key: 'good-molecules.myshopify.com-preconnect',
      rel: 'preconnect',
      href: 'good-molecules.myshopify.com',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://good-molecules.myshopify.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://good-molecules.myshopify.com/',
    },
    {
      key: 'https://good-molecules.myshopify.com/-preconnect',
      rel: 'preconnect',
      href: 'https://good-molecules.myshopify.com/',
      crossOrigin: 'anonymous',
    },

    {
      key: 'https://instafeed.nfcube.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://instafeed.nfcube.com/',
    },

    {
      key: 'https://instafeed.nfcube.com/-preconnect',
      rel: 'preconnect',
      href: 'https://instafeed.nfcube.com/',
      crossOrigin: 'anonymous',
    },
    {
      key: 'https://js.afterpay.com/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://js.afterpay.com/',
    },
    {
      key: 'https://js.afterpay.com/-preconnect',
      rel: 'preconnect',
      href: 'https://js.afterpay.com/',
      crossOrigin: 'anonymous',
    },

    {
      key: 'https://cdn1.stamped.io/-prefetch',
      rel: 'dns-prefetch',
      href: 'https://cdn1.stamped.io/',
    },
    {
      key: 'https://cdn1.stamped.io/-preconnect',
      rel: 'preconnect',
      href: 'https://cdn1.stamped.io/',
      crossOrigin: 'anonymous',
    },
  ];
  return (
    <Head>
      {preconnects.map((preconnect) => (
        <link key={preconnect.key} {...preconnect} />
      ))}
    </Head>
  );
}

export default HeadPreconnects;
