import { useEffect } from 'react';

/*
  sets the CSS variable root { --viewport-height: 123px } on mount and updates on screen resize.
  This allows us to do i.e sx={{ minHeight: var(--viewport-height) }} to get full height instead
  of using sx= {{ minHeight: 100vh }} which is NOT supported on Safari
*/
export const useSetViewportHeightCssVar = () => {
  useEffect(() => {
    const setViewportHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--viewport-height', `${window.innerHeight}px`);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setViewportHeight);
      setViewportHeight();
    }

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
};
