// <Container> variants

export const layout = {
  full: {
    width: '100%',
    maxWidth: '2400px',
    mx: 'auto',
  },
  container: {
    maxWidth: 1440,
    px: [9, null, null, null, null, null, null, 10],
    mx: 'auto',
  },
  pdp: {
    maxWidth: 1440,
    px: [9, null, null, null, null, null, null, 0],
    mx: 'auto',
  },
  megaNav: {
    maxWidth: ['100%', null, null, null, null, null, null, 792],
    mx: 'auto',
  },
};
