import uniqid from 'uniqid';

const menuItemLink = [
  {
    label: 'Link',
    name: 'link',
    component: 'link',
  },
];

const imagesWithAlt = {
  label: 'Images',
  name: 'images',
  component: 'group-list',
  itemProps: {
    label: '{{item.alt}}',
  },
  fields: [
    {
      label: 'Alt',
      name: 'alt',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue: '',
    },
  ],
};

const menuItemsWithImages = [
  // level 1
  ...menuItemLink,
  imagesWithAlt,
  {
    label: 'Sub Items',
    name: 'subItems',
    component: 'group-list',
    itemProps: {
      label: '{{item.link.text}}',
    },
    defaultItem: {
      name: 'New menu item',
      link: {
        text: '',
        url: '#',
      },
    },
    fields: [
      // level 2
      ...menuItemLink,
      imagesWithAlt,
      {
        label: 'Sub Items',
        name: 'subItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          name: 'New menu item',
          link: {
            text: '',
            url: '#',
          },
        },
        fields: [
          // level 3
          ...menuItemLink,
          imagesWithAlt,
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'imageLayout',
            label: 'Image Layout',
            description:
              'The image layout. If no layout is chosen, collection links will be rendered as landscape and all other links as portrait.',
            options: [
              { label: 'Portrait', value: 'portrait' },
              { label: 'Landscape', value: 'landscape' },
            ],
          },
        ],
      },
    ],
  },
];

const container = {
  label: 'Container Settings',
  name: 'containerSettings',
  component: 'group',
  fields: [
    {
      name: 'maxWidth',
      label: 'Max Width',
      component: 'select',
      options: [
        { value: 'narrow', label: 'Narrow' },
        { value: 'medium', label: 'Medium' },
        { value: 'wide', label: 'Wide' },
        { value: 'default', label: 'Full' },
      ],
      defaultValue: 'default',
    },
    {
      name: 'mx',
      label: 'Alignment',
      component: 'select',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'default', label: 'Center' },
        { value: 'right', label: 'Right' },
      ],
      defaultValue: 'default',
    },
    {
      name: 'px',
      label: 'Horizontal Spacing',
      component: 'select',
      options: [
        { value: 'default', label: 'None' },
        { value: 'small', label: 'Small' }, // 8px-16px-20px
        { value: 'medium', label: 'Medium' }, // 20px-32px-36px
        { value: 'large', label: 'Large' }, // 32px-48px-52px
      ],
      defaultValue: 'default',
    },
    {
      name: 'py',
      label: 'Vertical Spacing',
      component: 'select',
      options: [
        { value: 'default', label: 'None' },
        { value: 'small', label: 'Small' }, // 20px-36px-48px
        { value: 'medium', label: 'Medium' }, // 32px-52px-64px
        { value: 'large', label: 'Large' }, // 48px-72px-100px
      ],
      defaultValue: 'default',
    },
  ],
};

export { imagesWithAlt, menuItemLink, menuItemsWithImages, container };
