import { useState, useEffect } from 'react';
import { Box, Button, Paragraph } from 'theme-ui';
import Cookies from 'js-cookie';
import Link from 'next/link';

import store, { useRecoilValue, useSetRecoilState } from '@store';

const defaultCookieConsentName = '_BPCookieConsent';

export const SAME_SITE_OPTIONS = {
  STRICT: 'strict',
  LAX: 'lax',
  NONE: 'none',
};

export const getCookieConsentValue = (name = defaultCookieConsentName) => {
  let cookieValue = Cookies.get(name);

  // if the undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(getLegacyCookieName(name));
    return cookieValue;
  }

  return typeof cookieValue === 'string'
    ? JSON.parse(cookieValue)
    : cookieValue;
};

const getLegacyCookieName = (name) => {
  return `${name}-legacy`;
};

const DEFAULT_CONFIG = {
  analytics: {
    title: 'Analytics & Statistics',
    description:
      'These cookies allow us to measure visitors traffic and see traffic sources by collecting information in data sets. They also help us understand which products and actions are more popular than others.',
  },
  marketing: {
    title: 'Marketing & Retargeting',
    description:
      'These cookies are usually set by our marketing and advertising partners. They may be used by them to build a profile of your interest and later show you relevant ads. If you do not allow these cookies you will not experience targeted ads for your interests.',
  },
  functionality: {
    title: 'Functionality Cookies',
    description:
      'These cookies enable our website to offer additional functions and personal settings. They can be set by us or by third-party service providers that we have placed on our pages. If you do not allow these cookies, these or some of these services may not work properly.',
  },
};

export function CookieConsent({
  debug = false,
  cookieConfig,
  message,
  messageLink,
}) {
  const [currentCookies, setCurrentCookies] = useState(
    Object.keys(cookieConfig || DEFAULT_CONFIG).reduce((acc, cookieName) => {
      acc[cookieName] = true;
      return acc;
    }, {})
  );

  const [gdprActive, setGDPRActive] = useState(false);
  const [gdprModalActive, setGDPRModalActive] = useState(false);

  const setCookie = (cookieName, cookieValue) => {
    const sameSite = 'strict';

    const cookieOptions = {
      expires: 365,
      sameSite,
      secure: location ? location.protocol === 'https:' : true,
    };

    if (sameSite === SAME_SITE_OPTIONS.NONE) {
      Cookies.set(getLegacyCookieName(cookieName), cookieValue, cookieOptions);
    }

    Cookies.set(
      JSON.stringify(cookieName),
      JSON.stringify(cookieValue),
      cookieOptions
    );
  };

  const getCookieValue = (cookieName) => {
    return getCookieConsentValue(cookieName);
  };

  useEffect(() => {
    const currentCookie = getCookieValue();

    if (currentCookie === undefined || debug) {
      setGDPRActive(true);
    } else {
      setCurrentCookies(currentCookie);
      setGDPRActive(false);
    }
  }, []);

  const setOverlay = useSetRecoilState(store.overlay);
  const overlay = useRecoilValue(store.overlay);

  const toggleGDPRModal = () => {
    setOverlay(!overlay);
    setGDPRModalActive(!gdprModalActive);
  };

  const acceptSelected = () => {
    setCookie(defaultCookieConsentName, currentCookies);
    setOverlay(false);
    setGDPRActive(false);
    setGDPRModalActive(false);
  };

  const acceptAll = () => {
    const cookies = cookieConfig || DEFAULT_CONFIG;

    const cookieValue = Object.keys(cookies).reduce((acc, cookieName) => {
      acc[cookieName] = true;
      return acc;
    }, {});

    setCookie(defaultCookieConsentName, cookieValue);
    setCurrentCookies(cookieValue);
    setOverlay(false);
    setGDPRActive(false);
    setGDPRModalActive(false);
  };

  const config = cookieConfig || DEFAULT_CONFIG;

  if (typeof window === 'undefined') return null;
  return (
    <>
      <Box
        sx={{
          display: gdprModalActive ? 'flex' : 'none',
          position: 'fixed',
          p: 10,
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: '100%',
          zIndex: '45',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            maxWidth: '800px',
            m: '0 auto',
            maxHeight: ['90vh', null, null, null, '600px'],
            p: 10,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Paragraph
            onClick={toggleGDPRModal}
            sx={{
              position: 'absolute',
              top: 9,
              right: 9,
              fontSize: 10,
              cursor: 'pointer',
            }}
          >
            &times;
          </Paragraph>

          {/* HEADING */}
          <Paragraph
            as="h3"
            variant="text.h3.r.1"
            sx={{
              mb: 8,
              flexGrow: 1,
            }}
          >
            Choose Type of Cookies You Accept Using
          </Paragraph>

          {/* COOKIES SELECTOR */}
          <Box
            sx={{
              flexGrow: 3,
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                mb: 6,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <input
                  id="required"
                  name="required"
                  type="checkbox"
                  checked
                  readOnly
                  disabled
                />

                <Paragraph
                  as="label"
                  htmlFor="required"
                  variant="text.h6.r.1"
                  sx={{
                    ml: 2,
                    cursor: 'pointer',
                  }}
                >
                  Strictly Required Cookies
                </Paragraph>
              </Box>

              <Paragraph variant="text.body.s">
                These cookies are required for the website to run and cannot be
                switched off. Such cookies are only set in response to actions
                made by you such as language, currency, login session, privacy
                preferences. You can set your browser to block these cookies but
                this might affect the way our site is working.
              </Paragraph>
            </Box>

            {Object.keys(currentCookies).map((key) => {
              return (
                <Box
                  key={key}
                  sx={{
                    mb: 6,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <input
                      id={key}
                      name={key}
                      type="checkbox"
                      checked={!!currentCookies[key]}
                      onChange={() => {
                        setCurrentCookies((cookieValue) => {
                          return {
                            ...cookieValue,
                            [key]: !cookieValue[key],
                          };
                        });
                      }}
                    />

                    <Paragraph
                      as="label"
                      htmlFor={key}
                      variant="text.h6.r.1"
                      sx={{
                        cursor: 'pointer',
                        ml: 2,
                      }}
                    >
                      {config[key].title}
                    </Paragraph>
                  </Box>

                  <Paragraph variant="text.body.s">
                    {config[key].description}
                  </Paragraph>
                </Box>
              );
            })}
          </Box>

          {/* ACCEPT / DECLINE BUTTONS */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              pt: 9,
              flexDirection: ['column', null, null, null, 'row'],
            }}
          >
            <Button
              variant="buttons.outlineTransparent"
              onClick={acceptSelected}
              sx={{
                '&': { py: 4 },
                flexBasis: ['100%', null, null, null, '50%'],
                mr: [0, null, null, null, 2],
                mb: [2, null, null, null, 0],
                width: '100%',
                ':hover': {
                  color: 'black',
                  borderColor: 'black',
                },
              }}
            >
              Accept Selected
            </Button>

            <Button
              variant="buttons.primary"
              onClick={acceptAll}
              sx={{
                '&': { py: 4 },
                flexBasis: ['100%', null, null, null, '50%'],
                ml: [0, null, null, null, 2],
                width: '100%',
              }}
            >
              Accept All
            </Button>
          </Box>
        </Box>
      </Box>

      {/* FOOTER GDPR MESSAGE */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          py: 8,
          px: 10,
          backgroundColor: 'black',
          display: gdprActive ? 'flex' : 'none',
          flexDirection: ['column', null, null, null, 'row'],
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 30,
        }}
      >
        <Box>
          <Paragraph
            sx={{
              color: 'white',
            }}
          >
            {message ||
              'This website uses cookies to ensure you get the best experience on our website. '}

            {messageLink || (
              <Paragraph
                as="span"
                sx={{
                  fontWeight: '500',
                  color: 'primary',
                  textDecoration: 'underline',
                  ':hover': {
                    color: 'white',
                  },
                }}
              >
                <Link href="pages/privacy-policy">Privacy Policy</Link>
              </Paragraph>
            )}
          </Paragraph>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            pt: [9, null, null, null, 0],
            flexDirection: ['column', null, null, null, 'row'],
            maxWidth: ['100%', null, null, null, '400px'],
            width: '100%',
          }}
        >
          <Button
            variant="buttons.outlineTransparent"
            onClick={toggleGDPRModal}
            sx={{
              '&': { py: 4 },
              flexBasis: ['100%', null, null, null, '50%'],
              mr: [0, null, null, null, 2],
              mb: [2, null, null, null, 0],
              width: '100%',
            }}
          >
            Preferences
          </Button>

          <Button
            variant="buttons.primary"
            onClick={acceptAll}
            sx={{
              '&': { py: 4 },
              flexBasis: ['100%', null, null, null, '50%'],
              ml: [0, null, null, null, 2],
              width: '100%',
            }}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </>
  );
}
