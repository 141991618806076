import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import MarkdownJsx from 'markdown-to-jsx';
import { createRoot } from 'react-dom/client';
import { useEffect, useRef } from 'react';

const textHtmlTags = `p, h1, h2, h3, h4, h5, h6, span, label, div, strong, em, blockquote, pre, code, i, b, ul, ol, li`;

export function Markdown({
  text,
  children,
  style,
  textSx,
  textAlign = 'left',
  options = {},
  ...props
}) {
  const markdownText = typeof children === 'string' ? children : text;
  const markdownContainer = useRef(null);
  useEffect(() => {
    if (markdownContainer.current) {
      const root = createRoot(markdownContainer.current);
      root.render(
        <MarkdownJsx options={{ forceBlock: true, ...options }}>
          {markdownText || ''}
        </MarkdownJsx>
      );
    }
  }, [markdownContainer, markdownText]);

  return (
    <Box
      data-comp={Markdown.displayName}
      sx={{
        table: {
          width: '100%',
        },
        thead: {
          backgroundColor: 'lightgrey',
        },
        'th, td': {
          py: '5px',
        },
        'tr:nth-of-type(even)': {
          backgroundColor: '#f5f5f5',
        },
        ...markdownSx(textAlign),
        ...style,
        [textHtmlTags]: {
          textAlign: textAlign || null,
          mt: textSx ? 0 : null,
          '&:last-of-type': textSx ? { mb: 0 } : {},
          ...textSx,
        },
      }}
      {...props}
    >
      <div ref={markdownContainer} />
    </Box>
  );
}

const markdownSx = (textAlign) => ({
  p: {
    mb: 8,
    fontFamily: 'body',
    textAlign,
  },
  'p:last-of-type': {
    mb: 0,
  },
  'ul, ol': {
    ml: 13,
    mb: 8,
  },
  ul: {
    li: {
      listStyleType: 'disc',
    },
  },
  ol: {
    li: {
      listStyleType: 'decimal',
    },
  },
  li: {
    mb: 4,
    // listStylePosition: 'inside',
    // textIndent: '-22px',
    fontFamily: 'body',
  },
  a: {
    textDecoration: 'underline',
    fontFamily: 'body',
  },
  h1: {
    textAlign: 'center',
    mb: 11,
    fontSize: [10, null, null, 11],
    fontWeight: 600,
    fontFamily: 'body',
  },
  h2: {
    mb: 10,
    mt: 11,
    fontSize: 9,
    fontWeight: 500,
    fontFamily: 'body',
    textAlign,
  },
  h3: {
    mb: 4,
    fontSize: 8,
    fontWeight: 500,
    fontFamily: 'body',
    textAlign,
  },
  strong: {
    fontWeight: 600,
  },
});

Markdown.displayName = 'Markdown';

Markdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  style: PropTypes.object,
};
