import { Bundle } from './Bundle';

export function ProductBundle({ cms }) {
  if (!cms || !cms.bundles) return null;

  return cms.bundles.map((bundle) => {
    return <Bundle key={bundle?.bundle?.id} bundle={bundle?.bundle} />;
  });
}

ProductBundle.displayName = 'ProductBundle';

ProductBundle.Schema = {
  label: 'Product Bundle',
  key: 'product-bundle',
  fields: [
    {
      name: 'bundles',
      label: 'Bundles',
      component: 'group-list',
      fields: [
        {
          name: 'bundle',
          label: 'Bundle',
          component: 'productBundles',
        },
      ],
    },
  ],
};
