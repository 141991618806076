export const fontSizes = [
  /*
    DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  10, // 0
  11, // 1
  12, // 2
  13, // 3
  14, // 4
  15, // 5
  16, // 6
  18, // 7
  20, // 8
  24, // 9
  28, // 10
  32, // 11
  36, // 12
  40, // 13
  48, // 14
  80, // 15
  /* DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER */
];
