import React from 'react';
import { jsx, Image, Input, Box } from 'theme-ui';
import store, { useRecoilState, useSetRecoilState } from '@store';
import { useDebounce } from '@hooks';

export function SearchInput(props) {
  const searchInputRef = React.useRef();
  const [rawSearchTerm, setRawSearchTerm] = useRecoilState(store.rawSearchTerm);
  const debouncedSearchTerm = useDebounce(rawSearchTerm, 300);
  const [activeSearchTerm, setActiveSearchTerm] = useRecoilState(
    store.searchTerm
  );
  const setSearchIsFocused = useSetRecoilState(store.searchIsFocused);

  React.useEffect(() => {
    if (activeSearchTerm !== debouncedSearchTerm) {
      setActiveSearchTerm(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* Search Input */}
      <Box
        data-comp="SearchInput"
        sx={{
          position: 'relative',
          mb: 10,
          height: 54,
        }}
      >
        {/* [Todo]: /searching.svg */}
        <Image
          src="/svg/search.svg"
          alt="Search"
          width="14px"
          sx={{
            position: 'absolute',
            top: '19px',
            left: 8,
          }}
        />

        <Input
          ref={searchInputRef}
          variant="search"
          placeholder="Search"
          onFocus={() => setSearchIsFocused(true)}
          onBlur={() => setSearchIsFocused(false)}
          onChange={(e) => setRawSearchTerm(e.target.value)}
          value={rawSearchTerm}
        />

        <Image
          src="/svg/close.svg"
          alt="Clear search"
          height="14px"
          width="14px"
          onClick={() => setRawSearchTerm('')}
          sx={{
            display: rawSearchTerm ? 'block' : 'none',
            position: 'absolute',
            cursor: 'pointer',
            top: '19px',
            right: 8,
          }}
        />
      </Box>
    </>
  );
}
