import Link from 'next/link';
import { Box, Button, Container, Paragraph } from 'theme-ui';

import { breakpoints } from '../../theme/theme.breakpoints';
import Schema from './Schema';

export function SecondaryHero({
  cms: {
    imageSettings,
    textSettings,
    buttonSettings,
    contentSettings,
    containerSettings,
  },
}) {
  const image = {
    src: imageSettings?.image?.src || imageSettings?.image || '',
  };

  const text = {
    heading: textSettings?.heading || '',
    subheading: textSettings?.subheading || '',
    headingColor: textSettings?.headingColor || 'black',
    subHeadingColor: textSettings?.subHeadingColor || 'black',
    textAlign: textSettings?.textAlign || 'center',
  };

  const button = {
    text: buttonSettings?.cta?.text || '',
    path: buttonSettings?.cta?.url || '#',
    style: buttonSettings?.buttonStyle || 'primary',
  };

  const content = {
    position: contentSettings?.contentPosition || 'center center',
    maxWidth: contentSettings?.maxContentWidth || '500px',
  };

  return (
    <Container
      data-comp={SecondaryHero.displayName}
      variant={
        containerSettings?.fullWidth ? 'layout.full' : 'layout.container'
      }
    >
      <Box
        data-comp={SecondaryHero.displayName}
        sx={{
          width: '100%',
          position: 'relative',
          mb: [9, null, null, null, null, null, 13],
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            paddingTop: [
              'calc((1 / 1) * 100%)',
              null,
              null,
              null,
              'calc((4 / 9) * 100%)',
            ],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <picture>
              <source
                media={`(min-width: ${breakpoints[3]})`}
                srcSet={image.src}
              />

              <Box
                as="img"
                src={image.src}
                alt={text.heading}
                sx={{
                  width: '100%',
                  display: 'block',
                }}
              />
            </picture>
          </Box>

          <Container
            variant="layout.container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              p: [10, 12],
              alignItems: [
                'center',
                null,
                null,
                null,
                content.position.split(' ')[0],
              ],
              justifyContent: [
                'center',
                null,
                null,
                null,
                content.position.split(' ')[1],
              ],
            }}
          >
            <Box sx={{ maxWidth: `${parseInt(content.maxWidth)}px` }}>
              <Paragraph
                variant="text.h2.r.1"
                sx={{
                  color: text.headingColor,
                  textAlign: ['center', null, null, null, text.textAlign],
                  mb: text.subheading ? [3, null, null, null, 6] : 0,
                }}
              >
                {text.heading}
              </Paragraph>

              <Paragraph
                variant="text.body.r.1"
                sx={{
                  color: text.subHeadingColor,
                  textAlign: ['center', null, null, null, text.textAlign],
                  mb: button.text ? [9, null, null, null, 10] : 0,
                }}
              >
                {text.subheading}
              </Paragraph>

              <Box
                sx={{
                  display: button.text ? 'flex' : 'none',
                  justifyContent: [
                    'center',
                    null,
                    null,
                    null,
                    text.textAlign
                      ? text.textAlign === 'left'
                        ? 'flex-start'
                        : text.textAlign === 'center'
                        ? 'center'
                        : 'flex-end'
                      : 'center',
                  ],
                }}
              >
                {button.text && (
                  <Link href={button.path}>
                    <Button variant={`buttons.${button.style}`}>
                      {button.text}
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Container>
  );
}

SecondaryHero.displayName = 'SecondaryHero';

SecondaryHero.Schema = Schema;
