import React, { useCallback, useEffect } from 'react';
import Link from 'next/link';
import { Box, Flex, Image, Paragraph } from 'theme-ui';
import store, { useRecoilValue, useSetRecoilState } from '@store';
import { useCart, useCartCount, useCustomerInit } from '@backpackjs/storefront';
// import { cartViewGtm } from '../../global/DataLayer';

export function MenuCtas() {
  const cart = useCart();
  const cartCount = useCartCount();
  const customer = useCustomerInit();

  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const setCartOpen = useSetRecoilState(store.cartDrawerOpen);
  const rawSearchTerm = useRecoilValue(store.rawSearchTerm);
  const sideDrawerIsCart = useRecoilValue(store.sideDrawerIsCart$);
  const sideDrawerIsSearch = useRecoilValue(store.sideDrawerIsSearch$);

  const toggleSearch = useCallback(() => {
    if (sideDrawerIsSearch) {
      setSideDrawer(false);
      setOverlay(false);
    } else {
      setSideDrawer('search');
      const t0 = requestTimeout(() => {
        setOverlay(true);
        clearRequestTimeout(t0);
      }, 175);
    }
  }, [sideDrawerIsSearch]);

  const toggleCart = useCallback(() => {
    const attentive = document.getElementById('attentive_overlay');

    if (sideDrawerIsCart) {
      setSideDrawer(false);
      setOverlay(false);
      setCartOpen(false);
      if (attentive) {
        attentive.style.display = 'block';
      }
    } else {
      // PubSub.publish('VIEW_MINI_CART', cart);
      if (attentive) {
        attentive.style.display = 'none';
      }

      setSideDrawer('cart');
      setCartOpen(true);
      const t0 = requestTimeout(() => {
        setOverlay(true);
        clearRequestTimeout(t0);
      }, 175);
    }
  }, [sideDrawerIsCart]);

  useEffect(() => {
    if (sideDrawerIsCart) {
      PubSub.publish('VIEW_MINI_CART', cart);
    }
  }, [sideDrawerIsCart]);

  return (
    <Flex
      data-comp="MenuCtas"
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: [null, null, null, null, '200px'],
      }}
    >
      {/* Search 🔍 */}
      <Box
        data-comp="SearchToggle"
        onClick={toggleSearch}
        sx={{
          mr: [8, 9, null, null, 10],
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Image
          src={`/svg/${
            rawSearchTerm && sideDrawerIsSearch ? 'searching' : 'search'
          }.svg`}
          alt="Search"
          height="14px"
          width="14px"
        />
      </Box>

      {/* Account */}
      <Box
        data-comp="AccountToggle"
        sx={{
          mr: [8, 9, null, null, 10],
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Link
          href={customer ? '/account' : '/account/login'}
          aria-label="Go to Account Page"
        >
          <Box
            as="a"
            onClick={() => {
              setSideDrawer(false);
              setOverlay(false);
            }}
          >
            <Image
              src="/svg/account.svg"
              alt="Account"
              sx={{ cursor: 'pointer' }}
              height="24px"
              width="24px"
            />
          </Box>
        </Link>
      </Box>

      {/* Cart 🛒 */}
      <Box
        data-comp="CartToggle"
        onClick={() => {
          toggleCart();
          // cartViewGtm({ lineItems: cart.lineItems });
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <Image src="/svg/cart.svg" alt="Toggle Cart" width="16px" />

        <Paragraph
          sx={{
            display: cartCount ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            color: 'white',
            backgroundColor: 'primary',
            fontSize: '10px',
            top: '-5px',
            right: '-14px',
            padding: '2px',
            borderRadius: '50%',
            textAlign: 'center',
            width: '20px',
            height: '20px',
          }}
        >
          {cartCount}
        </Paragraph>
      </Box>
    </Flex>
  );
}

MenuCtas.displayName = 'MenuCtas';
