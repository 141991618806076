import uniqid from 'uniqid';

export default {
  label: 'Location Settings',
  name: 'locationSettings',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      component: 'toggle',
      label: 'Enable Location Message',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Locations',
      name: 'locations',
      component: 'group-list',
      itemProps: {
        label: '{{item.countryCode}}',
      },
      defaultItem: {
        name: 'country',
      },
      fields: [
        {
          name: 'countryCode',
          label: 'Country Code',
          component: 'text',
        },
        {
          name: 'message',
          label: 'Message',
          component: 'markdown',
        },
      ],
    },
  ],
};
