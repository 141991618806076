export default {
  label: 'Info Cards',
  key: 'info-cards',
  fields: [
    {
      name: 'columns',
      label: 'Desktop Columns',
      component: 'number',
      defaultValue: 2,
    },
    {
      name: 'mobileColumns',
      label: 'Mobile Columns',
      component: 'number',
      defaultValue: 1,
    },
    {
      name: 'tiles',
      label: 'Tiles',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      defaultItem: {
        name: 'New Tile',
        tileImage: {
          src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
          filename: 'DAILY_ESSENTIALS_2023.jpg',
        },
        title: 'Lorem Amet Etiam Vulputate',
        subText:
          'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        cta: {
          label: 'Shop Now',
          href: '#',
        },
      },
      fields: [
        {
          name: 'tileImage',
          label: 'Image',
          component: 'image',
        },
        {
          name: 'desktopTextAlign',
          label: 'Desktop Text Alignment',
          component: 'select',
          options: [
            {
              value: 'left',
              label: 'Left',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'right',
              label: 'Right',
            },
          ],
          defaultValue: 'left',
        },
        {
          name: 'mobileTextAlign',
          label: 'Mobile Text Alignment',
          component: 'select',
          options: [
            {
              value: 'left',
              label: 'Left',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'right',
              label: 'Right',
            },
          ],
          defaultValue: 'left',
        },
        {
          name: 'title',
          component: 'text',
          label: 'Hero Heading',
          defaultValue: 'Lorem Amet Etiam Vulputate',
        },
        {
          name: 'subText',
          component: 'text',
          label: 'Hero Subtext',
          defaultValue:
            'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
        },
      ],
      defaultValue: [
        {
          name: 'New Tile 1',
          tileImage: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
            filename: 'DAILY_ESSENTIALS_2023.jpg',
          },
          title: 'Lorem Amet Etiam Vulputate',
          subText:
            'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
          cta: {
            label: 'Shop Now',
            href: '#',
          },
        },
        {
          name: 'New Tile 2',
          tileImage: {
            src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/products/DAILY_ESSENTIALS_2023.jpg?v=1681414448',
            filename: 'DAILY_ESSENTIALS_2023.jpg',
          },
          title: 'Lorem Amet Etiam Vulputate',
          subText:
            'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
          cta: {
            label: 'Shop Now',
            href: '#',
          },
        },
      ],
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: false,
        },
      ],
    },
  ],
};
