import React, { useEffect } from 'react';
import { Flex, Spinner, Box, Container } from 'theme-ui';
import { isBrowser } from '@utils';

import store, {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
} from '@store';
import { SearchInput } from '@snippets';

import { SearchOrMenus } from './SearchOrMenus';

export function MenuDrawerMob() {
  const menuDrawerOpen = useRecoilValue(store.menuDrawerOpen);
  const [rawSearchTerm, setRawSearchTerm] = useRecoilState(store.rawSearchTerm);
  const setSearchIsFocused = useSetRecoilState(store.searchIsFocused);

  useEffect(() => {
    // reset the search on exit so we can always show the menus on open
    if (!menuDrawerOpen && rawSearchTerm) {
      setRawSearchTerm('');
      setSearchIsFocused(false);
    }
  }, [menuDrawerOpen]);

  return (
    <Box
      as="aside"
      data-comp="MenuDrawerMob"
      sx={{
        zIndex: 1,
        position: 'absolute',
        top: 'calc(var(--header-height) + var(--promoBar-height) )',
        left: 0,
        width: '100%',
        height: '100%',
        bg: (t) => t.colors.white,
        transform: menuDrawerOpen ? 'translateX(0)' : 'translateX(-100%)',
        willChange: 'transform',
        transition: 'transform .3s ease-in-out',
        display: ['block', null, null, null, 'none'],
      }}
    >
      <Container
        data-comp="MenuDrawerScroll"
        sx={{
          pt: [11],
          pb: '40px',
          px: [9],
          bg: (t) => t.colors.white,
          height: `calc(var(--viewport-height) - var(--header-height))`,
          overflowY: 'scroll',
          webkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {/* 🔍 Input */}
        <SearchInput />

        {/* If searching show search suggesting or results, else show the menus  */}
        {isBrowser ? (
          <React.Suspense fallback={<SearchOrMenusLoader />}>
            <SearchOrMenus />
          </React.Suspense>
        ) : null}
      </Container>
    </Box>
  );
}

function SearchOrMenusLoader() {
  return (
    <Flex
      data-comp="SearchOrMenusLoader"
      variant="flex.row.center"
      sx={{
        height: `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height) - 200px)`,
      }}
    >
      <Spinner />
    </Flex>
  );
}
