import uniqid from 'uniqid';

export default {
  label: 'Footer Settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Email Marketing',
      name: 'emailSignup',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
      ],
    },
    {
      label: 'Footer Menu',
      name: 'footerMenu',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: {
        name: 'New Menu Item',
        link: {
          text: '',
          url: '#',
        },
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Sub Menu',
          name: 'childMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          defaultItem: {
            name: 'New Sub Item',
            link: {
              text: '',
              url: '#',
            },
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'About',
          link: {
            text: 'About',
            url: '/pages/about-good-molecules',
          },
        },
      ],
    },
    {
      label: 'Legal Links',
      name: 'legalLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: {
        name: 'New legal link',
        link: {
          text: '',
          url: '#',
        },
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
    },
    {
      label: 'Social Links',
      name: 'socialLinks',
      component: 'group',
      itemProps: {
        label: '{{item.label}}',
      },
      fields: [
        {
          label: 'Facebook',
          name: 'fb',
          component: 'text',
        },
        {
          label: 'Instagram',
          name: 'ig',
          component: 'text',
        },
        {
          label: 'Pinterest',
          name: 'pinterest',
          component: 'text',
        },
      ],
    },
    {
      label: 'Site Copyright Notice',
      name: 'copyrightNotice',
      component: 'text',
    },
  ],
};
