import { Box } from 'theme-ui';

export function PictureSource({
  breakpoint,
  breakpointPrev,
  isLast,
  width,
  src,
}) {
  const media = isLast
    ? `(min-width: ${breakpointPrev})`
    : `(max-width: ${breakpoint})`;

  const breakpointPx = Math.round(parseFloat(breakpoint.split('em')[0]) * 16);
  return (
    <Box
      as="source"
      data-comp={PictureSource.displayName}
      data-render-until={`${breakpointPx}px/${breakpoint}`}
      data-crop-width={`${width}px`}
      media={media}
      srcSet={src}
    />
  );
}

PictureSource.displayName = 'PictureSource';
