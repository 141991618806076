import { store } from '@backpackjs/storefront';
import effects from './effects';
import { searchDrawerOpen } from './Search';
import { modal, overlay } from './Global';
import { cartDrawerOpen } from './Cart';
import { aria } from './Aria';

export const menuDrawerOpen = store.recoil.atom({
  key: 'menu/menuDrawerOpen',
  default: false,
});

const toggleMenuDrawer$ = store.recoil.selector({
  key: 'menu/toggleMenuDrawer$',
  get: ({ get }) => {},
  set: ({ get, set, reset }, newState) => {
    const _menuDrawerOpen = get(menuDrawerOpen);

    const toggleOpen = () => {
      set(menuDrawerOpen, true);
      set(searchDrawerOpen, false);
      set(cartDrawerOpen, false);
      set(modal, null);
      set(aria, {
        main: { 'aria-hidden': 'true', tabIndex: '-1' },
        menu: { 'aria-hidden': 'false', tabIndex: '0' },
        menuSecondary: { 'aria-hidden': 'true', tabIndex: '-1' },
        cart: { 'aria-hidden': 'true', tabIndex: '-1' },
        modal: { 'aria-hidden': 'true', tabIndex: '-1' },
      });
    };

    const toggleClose = () => {
      set(menuDrawerOpen, false);
      reset(aria);
    };

    typeof newState === 'boolean'
      ? newState
        ? toggleOpen()
        : toggleClose()
      : !_menuDrawerOpen
      ? toggleOpen()
      : toggleClose();
  },
});

// eslint-disable-next-line import/no-default-export
export default {
  // atoms
  menuDrawerOpen,

  // selectors
  toggleMenuDrawer$,
};
