import { Box, Flex, NavLink, Paragraph } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';


export function LinksBlock({ cms: { heading, links } }) {
  return (
    <Box
      data-comp={LinksBlock.displayName}
      sx={{
        maxWidth: '800px',
        width: '100%',
        mb: [9, null, null, null, null, null, 13],
        px: [9, null, null, null, null, null, 0],
        mx: 'auto',
      }}
    >
      {heading && (
        <Flex
          variant="flex.column.center"
          sx={{
            mb: [4, null, null, null, 12],
          }}
        >
          <Paragraph
            variant="text.h2.r.1"
            sx={{
              color: 'primary',
              textAlign: 'center',
            }}
          >
            {heading}
          </Paragraph>
        </Flex>
      )}

      {links?.map(({ link }) => {
        return (
          <NavLink
            href={link?.url || '#'}
            key={uuidv4()} target={link.newTab ? '_blank' : '_self'}
            rel={link.newTab ? 'noopener noreferrer' : ''}
            sx={{ width: '100%' }}
          >
            <Paragraph
              sx={{
                fontWeight: 600,
                py: 6,
                borderTop: 'thin solid black',
                borderBottom: 'thin solid black',
                mt: '-1px',
                cursor: 'pointer',
              }}
            >
              {link?.text || 'Link Item'}
            </Paragraph>
          </NavLink>
        );
      })}
    </Box>
  );
}

LinksBlock.displayName = 'LinksBlock';

LinksBlock.Schema = {
  label: 'Links Block',
  key: 'links-block',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'Links',
    },
    {
      name: 'links',
      label: 'Links Panels',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      fields: [
        {
          name: 'link',
          component: 'link',
          label: 'Link',
        },
      ],
      defaultValue: [
        {
          text: 'Link Item',
          type: 'isPage',
          url: '/',
        },
        {
          text: 'Link Item',
          type: 'isPage',
          url: '/',
        },
        {
          text: 'Link Item',
          type: 'isPage',
          url: '/',
        },
      ],
    },
  ],
};
