export const space = [
  /*
    DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  0, // 0
  2, // 1
  4, // 2
  6, // 3
  8, // 4
  10, // 5
  12, // 6
  14, // 7
  16, // 8
  20, // 9
  24, // 10
  32, // 11
  36, // 12
  48, // 13
  52, // 14
  64, // 15
  72, // 16
  100, // 17
  128, // 18
  150, // 19
  200, // 20
  256, // 21
  400, // 22
  512, // 23
  /* DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER */
];
