import { Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  useCartTotals,
  useCartCount,
  useCartCheckout,
} from '@backpackjs/storefront';

function addZeroes(num) {
  if (!num) return '';

  const dec = num.split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

export function CartTotals({ isFooter = false, isDrawer = false, ...props }) {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { cartCheckout } = useCartCheckout();

  return (
    <Flex
      data-comp={CartTotals.displayName}
      variant="flex.column"
      sx={{
        pt: isDrawer ? 9 : [0, null, null, null, 11],
        pb: isDrawer ? 9 : [0, null, null, null, 10],
        px: isDrawer ? 9 : [0, null, null, null, 9],
        borderStyle: 'solid',
        borderColor: (t) => t.colors.black,
        borderRadius: '4px',
        borderWidth: isDrawer ? 0 : [0, null, null, null, '1px'],
        mb: isDrawer ? 0 : [11, null, null, null, 9],
      }}
      {...props}
    >
      <Paragraph
        variant="text..1"
        sx={{
          display: isDrawer ? 'none' : ['none', null, null, null, 'block'],
          mb: 8,
        }}
      >
        Order Summary
      </Paragraph>

      <Flex variant="flex.row.between.center" sx={{ mb: 4 }}>
        <Paragraph variant="text.h6.d">Subtotal</Paragraph>
        <Paragraph variant="text.buttons.primary">
          ${addZeroes(cartTotals?.subtotal)}
        </Paragraph>
      </Flex>

      <Paragraph variant="text.body.xs" sx={{ color: 'greys.2', mb: 9 }}>
        Discounts, shipping &amp; taxes calculated at checkout
      </Paragraph>

      <Button
        variant="buttons.primary"
        onClick={async (event) => {
          event.preventDefault();
          await cartCheckout();
        }}
      >
        {cartCount > 0 ? 'Checkout' : 'Shop Now'}
      </Button>
    </Flex>
  );
}

CartTotals.displayName = 'CartTotals';

CartTotals.propTypes = {
  isFooter: PropTypes.bool,
  isDrawer: PropTypes.bool,
};
