import React from 'react';
import { jsx, Flex, Paragraph } from 'theme-ui';
import store, { useSetRecoilState } from '@store';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

export function MenuLinksMob() {
  const siteSettings = useSettings();
  const { header } = siteSettings;
  const toggleMenuDrawer$ = useSetRecoilState(store.toggleMenuDrawer$);
  const router = useRouter();
  const goToLinkAndToggle = React.useCallback((menuLink) => {
    if (menuLink?.link?.url) {
      // navigate
      router.push(menuLink.link.url);
      // close the drawer
      toggleMenuDrawer$(false);
    }
  });

  return (
    <Flex
      variant="column"
      sx={{
        flexDirection: 'column',
        mt: 11,
        mb: 12,
      }}
    >
      {header.headerLinks?.length > 0 &&
        header.headerLinks.map((menuLink, index) => {
          return (
            <Paragraph
              as="a"
              onClick={() => goToLinkAndToggle(menuLink)}
              key={menuLink.id}
              sx={{
                cursor: 'pointer',
                fontSize: 3,
                lineHeight: 18,
                letterSpacing: '.16',
                textTransform: 'uppercase',
                mb: 5,
              }}
            >
              {menuLink?.link?.text}
            </Paragraph>
          );
        })}
    </Flex>
  );
}
