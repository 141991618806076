import { useState, useEffect } from 'react';
import { Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartRemoveItem, useCartUpdateItem } from '@backpackjs/storefront';

export function QuantitySelect({ id, quantity, ...props }) {
  const { cartRemoveItem } = useCartRemoveItem();
  const { cartUpdateItem } = useCartUpdateItem();

  const changeBy = async (amount) => {
    const newQuantity = parseInt(quantity) + amount;
    if (newQuantity < 0) return;
    newQuantity > 0
      ? await cartUpdateItem({ lineId: id, quantity: newQuantity })
      : await cartRemoveItem({ lineId: id });
  };

  return (
    <Flex
      data-comp={QuantitySelect.displayName}
      sx={{
        alignItems: 'center',
      }}
      {...props}
    >
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 5,
          width: '28px',
          height: '28px',
          borderRadius: '50%',
          border: 'thin solid',
          borderColor: (t) => t.colors.black,
          p: 0,
          minWidth: 'unset',
          fontWeight: 400,
          flexShrink: 0,
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            borderColor: (t) => t.colors.black,
            backgroundColor: 'black',
            color: 'white',
          },
        }}
        onClick={() => changeBy(-1)}
      >
        &#8212;
      </Button>

      <Paragraph
        sx={{
          height: '28px',
          lineHeight: '28px',
          width: '35px',
          textAlign: 'center',
        }}
      >
        {quantity}
      </Paragraph>

      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 5,
          width: '28px',
          height: '28px',
          borderRadius: '50%',
          border: 'thin solid',
          borderColor: (t) => t.colors.black,
          p: 0,
          minWidth: 'unset',
          fontWeight: 400,
          flexShrink: 0,
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            borderColor: (t) => t.colors.black,
            backgroundColor: 'black',
            color: 'white',
          },
        }}
        onClick={() => changeBy(1)}
      >
        +
      </Button>
    </Flex>
  );
}

QuantitySelect.displayName = 'Quantity Select';

QuantitySelect.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
