import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';

// Hook to load in after pay script after component mount
const useAfterpayScript = (src) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        script.setAttribute('data-analytics-enabled', true);
        // script.setAttribute("data-min", "35.00");
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
};

export function Afterpay({ price }) {
  useAfterpayScript('https://js.afterpay.com/afterpay-1.x.js');

  return (
    <Box
      sx={{
        'afterpay-placement': {
          fontFamily: 'body',
          '--logo-badge-width': '72px',
        },
        '.afterpay-paragraph': {
          fontSize: '12px !important',
        },
      }}
    >
      <afterpay-placement
        data-show-upper-limit="false"
        style={{
          margin: 0,
          bg: 'red',
        }}
        data-modal-theme="white"
        data-locale="en_US"
        data-currency="USD"
        data-amount={price}
        data-size="xs"
      />
    </Box>
  );
}
