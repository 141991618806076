import Head from 'next/head';
import Script from 'next/script';

function Libs() {
  return (
    <>
      {/* GTM Data Layer Script */}
      <Script
        strategy="beforeInteractive"
        id="gtm-dataLayer-js"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = [];
            console.log('GTM dataLayer instantiated.');
          `,
        }}
      />

      {/* GTM Script */}
      <Script
        strategy="beforeInteractive"
        id="gtm-script-js"
        dangerouslySetInnerHTML={{
          __html: `
            console.log('GTM script initialized.');
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MN9NW32');
          `,
        }}
      />

      {/* Stamped.io Script Widget */}
      <Script
        src="https://cdn1.stamped.io/files/widget.min.js"
        id="stamped-script-widget"
        async
        data-api-key={`${process.env.NEXT_PUBLIC_STAMPEDIO_PUBLIC_API_KEY}`}
        onLoad={() => {
          window.StampedFn.init({
            apiKey: process.env.NEXT_PUBLIC_STAMPEDIO_PUBLIC_API_KEY,
            sId: process.env.NEXT_PUBLIC_STAMPEDIO_STORE_HASH,
          });
        }}
      />

      {/* Instafeed Script */}
      <Script
        src="https://storage.nfcube.com/instafeed-f34622ed5510dc25f8e91028d343a1a3.js?shop=good-molecules.myshopify.com"
        strategy="beforeInteractive"
        id="instafeed-instafeed-js"
        onLoad={() => {
          console.log('Instafeed script initialized.');
        }}
      />

      {/* Instafeed CSS */}
      <link
        rel="stylesheet"
        href="/styles/instafeed.css"
        id="instafeed-instafeed.css-css"
      />
      {/* Stamped.io styles */}
      <link
        rel="stylesheet"
        href="https://cdn1.stamped.io/files/widget.min.css"
        type="text/css"
      />
    </>
  );
}

export default Libs;
