export const buttons = {
  primary: {
    variant: 'text.buttons.primary',
    backgroundColor: 'primary',
    border: '1px solid primary',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    minWidth: '188px',
    px: 7,
    py: ['11px', null, null, '13px'],
    textAlign: 'center',
    transition: '0.25s ease-in-out',
    '&:hover': {
      borderColor: '#D83CD8',
      backgroundColor: '#D83CD8',
    },
  },
  secondary: {
    variant: 'text.buttons.primary',
    backgroundColor: 'black',
    border: '1px solid black',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    minWidth: '188px',
    px: 7,
    py: ['11px', null, null, '13px'],
    textAlign: 'center',
    transition: '0.25s ease-in-out',
    '&:hover': {
      borderColor: 'greys.2',
      backgroundColor: 'greys.2',
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
  dark: {
    variant: 'text.buttons.primary',
    backgroundColor: '#000000',
    border: '1px solid #000000',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    minWidth: '188px',
    px: 7,
    py: ['11px', null, null, '13px'],
    textAlign: 'center',
    transition: '0.25s ease-in-out',
    '&:hover': {
      borderColor: '#000000',
      backgroundColor: '#000000',
    },
  },
  outline: {
    variant: 'text.buttons.primary',
    backgroundColor: 'whites.3',
    border: '1px solid black',
    borderRadius: '4px',
    color: 'black',
    cursor: 'pointer',
    minWidth: '188px',
    px: 7,
    py: ['11px', null, null, '13px'],
    textAlign: 'center',
    transition: '0.25s ease-in-out',
    '&:hover': {
      color: 'primary',
      borderColor: 'primary',
    },
  },
  outlineTransparent: {
    variant: 'text.buttons.primary',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'primary',
    borderRadius: '4px',
    color: 'primary',
    cursor: 'pointer',
    minWidth: '188px',
    px: 7,
    py: ['11px', null, null, '13px'],
    textAlign: 'center',
    transition: '0.25s ease-in-out',
    '&:hover': {
      color: 'white',
      borderColor: 'white',
    },
  },
  link: {
    variant: 'text.links.text',
    backgroundColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    m: 0,
    p: 0,
    position: 'relative',
    outline: 'none',
    transition: '0.25s ease-in-out',
    width: 'fit-content',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      backgroundColor: 'black',
      top: '100%',
      width: '100%',
      height: '1px',
      transition: '0.25s ease-in-out',
    },
    '&:hover': {
      color: 'primary',
    },
    '&:hover::after': {
      backgroundColor: 'primary',
    },
    main: {
      variant: 'text.links.main',
      backgroundColor: 'transparent',
      color: 'black',
      cursor: 'pointer',
      m: 0,
      p: 0,
      position: 'relative',
      outline: 'none',
      transition: '0.25s ease-in-out',
      width: 'fit-content',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        backgroundColor: 'black',
        top: '100%',
        width: '100%',
        height: '1px',
        transition: '0.25s ease-in-out',
      },
      '&:hover::after': {
        backgroundColor: 'primary',
      },
    },
  },
};
