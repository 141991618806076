export default {
  label: 'Product Page Settings',
  name: 'productPage',
  component: 'group',
  fields: [
    {
      label: 'Description Message',
      name: 'descriptionMessage',
      component: 'markdown',
    },
  ],
};
