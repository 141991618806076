import { Box, Container } from 'theme-ui';

import { breakpoints } from '../../theme/theme.breakpoints';

export function ImageBlock({ cms: { imgAlt, image, fullWidth } }) {
  return (
    <Container
      data-comp={ImageBlock.displayName}
      variant={fullWidth ? 'layout.full' : 'layout.container'}
      sx={{
        width: '100%',
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <picture>
        <source
          media={`(min-width: ${breakpoints[3]})`}
          srcSet={image?.src || image}
          width={1440}
        />
        <Box
          as="img"
          alt={imgAlt || image?.filename.split('.')[0]}
          src={image?.src || image}
          width={1440}
          sx={{
            width: '100%',
            display: 'block',
          }}
        />
      </picture>
    </Container>
  );
}

ImageBlock.displayName = 'ImageBlock';

ImageBlock.Schema = {
  label: 'Image Block',
  key: 'image-block',
  fields: [
    {
      name: 'image',
      label: 'Image',
      component: 'image',
      defaultValue: {
        src: 'https://cdn.shopify.com/s/files/1/0173/5085/2708/files/LDM-desktop.jpg?v=1681143646',
        filename: 'LDM-desktop.jpg',
      },
    },
    {
      name: 'imgAlt',
      label: 'Image Alt',
      component: 'text',
      defaultValue: 'Image Alt',
    },
    {
      name: 'fullWidth',
      component: 'toggle',
      label: 'Full Width',
      description: 'Section to be full width or not',
      toggleLabels: {
        true: 'Full Width',
        false: 'Contained',
      },
      defaultValue: false,
    },
  ],
};
