const presets = {
  maxWidth: {
    default: '100%',
    wide: '900px',
    medium: '700px',
    narrow: '500px',
  },
  ml: {
    default: 'auto',
    left: '0px',
    right: 'auto',
  },
  mr: {
    default: 'auto',
    left: 'auto',
    right: '0px',
  },
  px: {
    default: '0px',
    small: [4, null, null, null, 8, 9],
    medium: [9, null, null, null, 11, 12],
    large: [11, null, null, null, 13, 14],
  },
  py: {
    default: '0px',
    small: [10, null, null, null, 12, 13],
    medium: [11, null, null, null, 14, 15],
    large: [13, null, null, null, 16, 17],
  },
};

export const parseContainerSettings = (settings) => {
  if (!settings)
    return {
      maxWidth: presets.maxWidth.default,
      ml: presets.ml.default,
      mr: presets.mr.default,
    };

  const keys = ['maxWidth', 'mx', 'px', 'py'];

  return keys.reduce((obj, key) => {
    if (key === 'mx') {
      if (
        !settings.mx ||
        !presets.ml[settings.mx] ||
        !presets.mr[settings.mx]
      ) {
        obj.ml = presets.ml.default;
        obj.mr = presets.mr.default;
        return obj;
      }
      obj.ml = presets.ml[settings.mx];
      obj.mr = presets.mr[settings.mx];
      return obj;
    }

    if (!settings[key] || !presets[key][settings[key]]) {
      obj[key] = presets[key].default;
      return obj;
    }
    obj[key] = presets[key][settings[key]];
    return obj;
  }, {});
};
