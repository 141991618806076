import React from 'react';
import Head from 'next/head';

function HeadFonts() {
  const link = [
    {
      key: 'oswald-600-latin',
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      href: '/fonts/Oswald-600-latin5.woff2',
    },
    {
      key: 'oswald-600-latin-ext4',
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      href: '/fonts/Oswald-600-latin-ext4.woff2',
    },
    {
      key: 'font-stylesheet',
      rel: 'stylesheet',
      href: '/fonts/fonts.css',
    },
    {
      key: 'google-fonts-stylesheet',
      rel: 'stylesheet',
      href: '/fonts/google-fonts.css',
    },
  ];
  return (
    <Head>
      {link.map((item) => {
        return <link key={item.key} {...item} />;
      })}
    </Head>
  );
}

export default HeadFonts;
