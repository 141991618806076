import { useCartCount } from '@backpackjs/storefront';
import { Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';

export function CartHeader({ isDrawer, ...props }) {
  const cartCount = useCartCount();

  return (
    <Flex
      data-comp={CartHeader.displayName}
      variant="flex.row.between.center"
      sx={{
        mb: 9,
      }}
      {...props}
    >
      <Paragraph variant="text.h5.r.1">Cart ({cartCount})</Paragraph>
    </Flex>
  );
}

CartHeader.displayName = 'Cart Header';

CartHeader.propTypes = {
  cartCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
