import { Box, Flex, Paragraph } from 'theme-ui';

export function Routine({ steps = [] }) {
  return (
    <Box sx={{ justifyContent: 'center' }}>
      {steps.map(({ step, current }, index) => (
        <Flex key={index} sx={{ alignItems: 'center' }}>
          <Flex
            sx={{
              flexDirection: 'column',
              position: 'relative',
              justifyContent: 'center',
            }}
          >
            <Box
              key={index}
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: current ? 'black' : 'transparent',
                border: '1px solid',
                borderColor: current ? 'black' : 'text',
                mx: '8px',
                my: '10px',
              }}
            />
            <Box
              sx={{
                borderLeft: '1px solid black',
                height: '16px',
                borderColor:
                  index !== steps.length - 1 ? 'text' : 'transparent',
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </Flex>
          <Paragraph>
            {step}{' '}
            {current && (
              <Box as="span" sx={{ color: 'primary' }}>
                {'(You are here.)'}{' '}
              </Box>
            )}{' '}
          </Paragraph>
        </Flex>
      ))}
    </Box>
  );
}
