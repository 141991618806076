/** @jsxImportSource theme-ui */
import React from 'react';
import { Box } from 'theme-ui';
import store, { useSetRecoilState, useRecoilValue } from '@store';
import { useBodyScrollLock } from '@hooks';

export function Overlay(props) {
  const [overlayZindex, setOverlayZindex] = React.useState(-1);
  const { lockScroll, unlockScroll } = useBodyScrollLock();
  const hideAllDrawers$ = useSetRecoilState(store.hideAllDrawers$);
  const cartDrawerOpen = useRecoilValue(store.cartDrawerOpen);
  const modal = useRecoilValue(store.modal);
  const overlay = useRecoilValue(store.overlay);

  React.useEffect(() => {
    if (overlay) {
      // console.log('Overlay: setting z-index to 40')
      // console.log('Overlay: locking scroll')
      setOverlayZindex(40);
      lockScroll();
    } else {
      const resetOverlayZindex = () => {
        const tO = requestTimeout(() => {
          // console.log('Overlay: re-setting z-index to -1')
          setOverlayZindex(-1);
          clearRequestTimeout(tO);
        }, 100);
      };
      // console.log('Overlay: unlocking scroll')
      unlockScroll({ onComplete: resetOverlayZindex });
    }
  }, [overlay]);

  return (
    <Box
      data-comp="Overlay"
      variant="box.fill"
      onClick={() => hideAllDrawers$()}
      {...props}
      sx={{
        // zIndex: overlay ? 40 : -1,
        zIndex: overlayZindex,
        position: 'fixed',
        backgroundColor: 'black',
        opacity: overlay ? 0.2 : 0,
        willChange: 'opacity',
        transition: 'opacity .2s cubic-bezier(0.2, 0.5, 0.2, 1)',
        '&:hover': {
          cursor:
            cartDrawerOpen || modal
              ? `url('/svg/close-cursor.svg') 0 0, auto`
              : 'pointer',
        },
        ...props.sx,
      }}
    />
  );
}
