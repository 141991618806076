import { Box, Container, Paragraph } from 'theme-ui';
import { breakpoints } from '../../theme/theme.breakpoints';

export function CollectionHero({ collection }) {
  return (
    <Container
      data-comp="CollectionHero"
      variant="layout.full"
      sx={{
        paddingBottom: collection?.image
          ? '0px'
          : ['64%', null, null, null, '19.44%'],
        backgroundColor: `backgrounds.1`,
        position: 'relative',
      }}
    >
      {collection?.image && (
        <picture>
          <source
            media={`(min-width: ${breakpoints[4]})`}
            srcSet={collection?.image?.originalSrc || ''}
          />

          <Box
            as="img"
            src={collection?.image?.originalSrc || ''}
            alt={collection?.image?.altText}
            sx={{
              width: '100%',
              display: 'block',
            }}
          />
        </picture>
      )}

      <Container
        variant="layout.container"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        {collection?.title && (
          <Paragraph
            as="h1"
            variant="text.h2.r.1"
            sx={{
              textAlign: 'center',
              maxWidth: '480px',
            }}
          >
            {collection?.title}
          </Paragraph>
        )}

        {collection?.description && (
          <Paragraph
            variant="text.body.l"
            sx={{
              textAlign: 'center',
              maxWidth: '480px',
              mt: 6,
            }}
          >
            {collection?.description}
          </Paragraph>
        )}
      </Container>
    </Container>
  );
}
