// import { atom, selector  } from 'recoil';
import { store } from '@backpackjs/storefront';
import { menuDrawerOpen } from './Menu';
import { cartDrawerOpen } from './Cart';
import { overlay, modal } from './Global';

export const searchDrawerOpen = store.recoil.atom({
  key: 'search/searchDrawerOpen',
  default: false,
});

const toggleSearchDrawer$ = store.recoil.selector({
  key: 'search/toggleSearchDrawer$',
  get: ({ get }) => {},

  set: ({ set, get }, newState) => {
    const _searchDrawerOpen = get(searchDrawerOpen);

    const toggleOpen = () => {
      set(searchDrawerOpen, true);
      set(menuDrawerOpen, false);
      set(overlay, null);
      set(modal, null);
      set(cartDrawerOpen, false);
    };

    const toggleClose = () => {
      set(searchDrawerOpen, false);
    };

    typeof newState === 'boolean'
      ? newState
        ? toggleOpen()
        : toggleClose()
      : !_searchDrawerOpen
      ? toggleOpen()
      : toggleClose();
  },
});

// instant un-debounced search term (not used)
const rawSearchTerm = store.recoil.atom({
  key: 'search/rawSearchTerm',
  default: '',
});

// debounced term used
const searchTerm = store.recoil.atom({
  key: 'search/searchTerm',
  default: '',
});

// true when search input is focused
const searchIsFocused = store.recoil.atom({
  key: 'search/searchIsFocused',
  default: false,
});

// holds both results
const searchResults$ = store.recoil.selector({
  key: 'search/searchResults$',
  get: async ({ get }) => {
    try {
      const _searchTerm = get(searchTerm);
      console.log({ _searchTerm });
      if (!_searchTerm) return null;
      const response = await searchShopify(_searchTerm);
      console.log({ response });
      if (response && response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error('searchResults:error', error);
    }
  },
});

// returns product results
const foundProducts$ = store.recoil.selector({
  key: 'search/foundProducts$',
  get: ({ get }) => {
    const _searchResults = get(searchResults$);
    return _searchResults?.products?.length ? _searchResults?.products : null;
  },
});

// returns collection results
const foundCollections$ = store.recoil.selector({
  key: 'menu/foundCollections$',
  get: ({ get }) => {
    const _searchResults = get(searchResults$);
    return _searchResults?.collections?.length
      ? _searchResults?.collections
      : null;
  },
});

// search fetch function
const searchShopify = async (term) => {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ term }),
    };
    const request = await fetch(`/api/shopify/search`, options);
    const { response } = await request.json();
    return response?.results || null;
  } catch (e) {
    console.error('searchShopify:error', e);
    return [];
  }
};

export default {
  // atoms
  rawSearchTerm,
  searchTerm,
  searchIsFocused,
  searchDrawerOpen,

  // selectors
  searchResults$,
  foundProducts$,
  foundCollections$,
  toggleSearchDrawer$,
};
