import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Image } from 'theme-ui';
import 'swiper/css/bundle';

export function FiftyFiftyTileCarousel({ images }) {
  if (!images) return null;

  return (
    <Box
      data-comp={FiftyFiftyTileCarousel.displayName}
      sx={{
        width: '100%',
        height: '100%',
        '.swiper-container-autoheight, .swiper-slide, .swiper, .swiper-wrapper':
          {
            height: '100% !important',
          },
        '.swiper-pagination': {
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          '.swiper-pagination-bullet': {
            width: '10px',
            height: '10px',
            opacity: 1,
            background: 'transparent',
            border: 'thin solid white',
            borderColor: 'white',
            transition: 'all .25s ease-in-out',
            '&.swiper-pagination-bullet-active': {
              backgroundColor: 'white',
            },
          },
        },
      }}
    >
      <Swiper
        loop
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, A11y]}
        autoHeight
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        fadeEffect={{ crossFade: true }}
      >
        {images.map(({ image }, index) => {
          return (
            <SwiperSlide key={index}>
              <Image src={image?.src || image} />
            </SwiperSlide>
          );
        })}

        <div className="swiper-button-prev" css={buttonStyle} />
        <div className="swiper-button-next" css={buttonStyle} />
      </Swiper>
    </Box>
  );
}

FiftyFiftyTileCarousel.displayName = 'FiftyFiftyTileCarousel';

const buttonStyle = {
  background: 'white',
  color: 'black',
  opacity: 0.8,
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  fontSize: '20px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
  '&:after': {
    fontSize: '20px',
  },
  '@media only screen and (max-width: 991px)': {
    height: '40px',
    width: '40px',
    fontSize: '16px',
    '&:after': {
      fontSize: '16px',
    },
  },
  '&:hover': {
    opacity: 1,
  },
};
