export default {
  label: 'Primary Hero',
  key: 'primary-hero',
  fields: [
    {
      label: 'Image Settings',
      name: 'imageSettings',
      description: 'E.g. Background Image, Image Position.',
      component: 'group',
      fields: [
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          defaultValue:
            'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
        },
        {
          name: 'desktopRatio',
          label: 'Desktop Image Ratio',
          component: 'text',
          description:
            'Format is X:Y, this is the ratio for the container that holds image, this prevents jumps when loading. This site is helpful: https://ratiobuddy.com/',
          defaultValue: '5:12',
        },
        {
          name: 'mobileImage',
          label: 'Mobile Image',
          component: 'image',
          description: 'If empty, mobile will use main image.',
        },
        {
          name: 'mobileRatio',
          label: 'Mobile Image Ratio',
          component: 'text',
          description:
            'Format is X:Y, this is the ratio for the container that holds image, this prevents jumps when loading. This site is helpful: https://ratiobuddy.com/',
          defaultValue: '1:1',
        },
      ],
      defaultValue: {
        image: {
          src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
          alt: 'Hero Image',
        },
        desktopRatio: '5:12',
        mobileRatio: '1:1',
        mobileImage: {
          src: 'https://images.unsplash.com/photo-1567016515344-5e3b0d67bb75?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80',
          alt: 'Hero Image',
        },
      },
    },
    {
      label: 'Text Settings',
      name: 'textSettings',
      description: 'E.g. Heading, Text Color.',
      component: 'group',
      fields: [
        {
          name: 'superheading',
          component: 'text',
          label: 'Super Heading',
          defaultValue: 'Superheading',
        },
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
          defaultValue: 'Primary Hero Heading',
        },
        {
          name: 'subheading',
          component: 'text',
          label: 'Sub Heading',
          defaultValue: 'Primary Hero Subheading',
        },
        {
          name: 'textColor',
          component: 'select',
          label: 'Text Color',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
          ],
          defaultValue: 'black',
        },
        {
          name: 'mobileTextColor',
          component: 'select',
          label: 'Mobile Text Color',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
          ],
          defaultValue: 'black',
        },
        {
          name: 'textAlign',
          component: 'select',
          label: 'Text Alignment',
          options: [
            {
              value: 'left',
              label: 'Left',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'right',
              label: 'Right',
            },
          ],
          defaultValue: 'center',
        },
      ],
      defaultValue: {
        superheading: 'Superheading',
        heading: 'Primary Hero Heading',
        subheading: 'Primary Hero Subheading',
        textColor: 'black',
        mobileTextColor: 'black',
        textAlign: 'center',
      },
    },
    {
      label: 'Button Settings',
      name: 'buttonSettings',
      description: 'E.g. Button Text, Button Path.',
      component: 'group',
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'CTA One',
          defaultValue: {
            url: '#',
            text: '',
          },
        },
        {
          name: 'buttonStyle',
          component: 'select',
          label: 'Button One Style',
          options: [
            {
              value: 'primary',
              label: 'Primary',
            },
            {
              value: 'secondary',
              label: 'Secondary',
            },
            {
              value: 'outline',
              label: 'Outline',
            },
            {
              value: 'link.main',
              label: 'Link',
            },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'cta2',
          component: 'link',
          label: 'CTA Two',
          defaultValue: {
            url: '#',
            text: '',
          },
        },
        {
          name: 'buttonTwoStyle',
          component: 'select',
          label: 'Button Two Style',
          options: [
            {
              value: 'primary',
              label: 'Primary',
            },
            {
              value: 'secondary',
              label: 'Secondary',
            },
            {
              value: 'outline',
              label: 'Outline',
            },
            {
              value: 'link.main',
              label: 'Link',
            },
          ],
          defaultValue: 'primary',
        },
      ],
      defaultValue: {
        cta: {
          url: '#',
          text: 'Shop Now',
        },
      },
    },
    {
      label: 'Content Settings',
      name: 'contentSettings',
      description: 'E.g. Content Width, Content Position.',
      component: 'group',
      fields: [
        {
          name: 'maxContentWidth',
          component: 'number',
          label: 'Max Content Width (px)',
          defaultValue: '600',
        },
        {
          name: 'contentPosition',
          component: 'select',
          label: 'Desktop Content Position',
          options: [
            {
              value: 'flex-start flex-start',
              label: 'Left Top',
            },
            {
              value: 'flex-start center',
              label: 'Left Center',
            },
            {
              value: 'flex-start flex-end',
              label: 'Left Bottom',
            },
            {
              value: 'flex-end flex-start',
              label: 'Right Top',
            },
            {
              value: 'flex-end center',
              label: 'Right Center',
            },
            {
              value: 'flex-end flex-end',
              label: 'Right Bottom',
            },
            {
              value: 'center flex-start',
              label: 'Center Top',
            },
            {
              value: 'center center',
              label: 'Center Center',
            },
            {
              value: 'center flex-end',
              label: 'Center Bottom',
            },
          ],
          defaultValue: 'center center',
        },
        {
          name: 'mobileContentPosition',
          component: 'select',
          label: 'Mobile Content Position',
          options: [
            {
              value: 'flex-start',
              label: 'Top',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'flex-end',
              label: 'Bottom',
            },
          ],
          defaultValue: 'center',
        },
      ],
      defaultValue: {
        maxContentWidth: '600',
        contentPosition: 'center center',
        mobileContentPosition: 'center',
      },
    },
    {
      label: 'Container Settings',
      name: 'containerSettings',
      description: 'E.g. Full with, or contained',
      component: 'group',
      fields: [
        {
          name: 'fullWidth',
          component: 'toggle',
          label: 'Full Width',
          description: 'Section to be full width or not',
          toggleLabels: {
            true: 'Full Width',
            false: 'Contained',
          },
          defaultValue: false,
        },
      ],
      defaultValue: {
        fullWidth: false,
      },
    },
  ],
};
