import Link from 'next/link';
import { Container, Box, Image, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';
import { MenuCtas } from './MenuCtas';
import { MenuItem } from './MenuItem';

import { MenuToggle } from './MenuToggle';

export function Menu() {
  const siteSettings = useSettings();
  const { header } = siteSettings;

  return (
    <Container
      data-comp="Menu"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: [56, null, null, 72],
        bg: 'white',
        width: '100%',
        zIndex: 2,
        boxShadow: ['bottom', null, null, null, 'none'],
      }}
    >
      {/* 🍔 / 🚫 */}
      <MenuToggle />

      {/* 💎 Logo */}
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: ['center', null, null, null, 'start'],
          flex: [1, null, null, null, 'initial'],
          width: [null, null, null, null, '200px'],
          cursor: 'pointer',
        }}
      >
        <Link href="/">
          <Flex
            sx={{
              width: ['170px', null, null, null, '200px'],
              alignItems: 'center',
            }}
          >
            <Image
              src="/svg/storefront-logo.svg"
              alt="Good Molecules Logo"
              sx={{ width: '100%' }}
            />
          </Flex>
        </Link>
      </Flex>

      {/* Top-level Desktop Menu links */}
      <Box
        data-comp="MenuLinks"
        as="ul"
        sx={{
          display: ['none', null, null, null, 'flex'],
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          listStyleType: 'none',
          px: 0,
        }}
      >
        {header?.headerMenu?.map((item, index) => {
          return <MenuItem key={index} item={item} />;
        })}
      </Box>

      {/* 🔍 👩‍💻  🛒 */}
      <MenuCtas />
    </Container>
  );
}

Menu.displayName = 'Menu';
