export const lineHeights = {
  14: '14px',
  16: '16px',
  18: '18px',
  22: '22px',
  26: '26px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  42: '42px',
  52: '52px',
  48: '48px',
  80: '80px',
};
