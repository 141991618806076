import uniqid from 'uniqid';
import { menuItemLink } from './common.js';

export default {
  label: 'Header Settings',
  name: 'header',
  component: 'group',
  fields: [
    {
      label: 'Promobar',
      name: 'promoBar',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable Promobar',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'number',
          name: 'duration',
          label: 'Autoplay duration',
          description: 'Time on each message. (sec)',
          step: 1,
          defaultValue: 3,
        },
        {
          component: 'number',
          name: 'speed',
          label: 'Autoplay speed',
          description: 'Time between message transitions. (sec)',
          step: 0.25,
          defaultValue: 0.75,
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message.text}}',
          },
          defaultItem: {
            name: 'New message',
            message: {
              url: '',
              text: '',
            },
          },
          fields: [
            {
              name: 'locations',
              component: 'tags',
              label: 'Available Location',
              description:
                'Locations this message is enabled in. If empty, it will be available to all. Press enter to set.',
            },
            {
              label: 'Message',
              description:
                'A promo bar message and optional link. i.e Free shipping on orders..',
              name: 'message',
              component: 'link',
            },
          ],
        },
      ],
    },

    // header.headerMenu
    {
      label: 'Header Menu',
      name: 'headerMenu',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
        name: '{{item.link.text}}',
      },
      defaultItem: {
        name: 'New item',
        link: {
          url: '',
          text: '',
        },
      },
      fields: menuItemLink,
    },
  ],
};
