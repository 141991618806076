import React, { useEffect, Children, useState } from 'react';

// Get the most accurate displayName of the child that will be CSR
const firstChildDisplayName = (children) => {
  return children
    ? Children.map(children, (child) => {
        return (
          child.props['data-comp'] ||
          child.props.__EMOTION_LABEL_PLEASE_DO_NOT_USE__ ||
          child.type.displayName
        );
      })[0]
    : 'unknown';
};

export function ClientSideOnly(props) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => setHasMounted(true), []);

  return hasMounted ? (
    <div data-comp="ClientSideOnly" {...props} />
  ) : (
    <div
      data-comp="ClientSideOnly"
      data-comp-csr={firstChildDisplayName(props.children)}
    />
  ); // Return empty div on SSR same as null
}
