import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

const DEBUG = false; // local debugging (logs whether passed or failed with elevar)
const ELEVAR_SIGNING_KEY = '5dc9cbcb6c1cf5eb284c885bb5e4e692432a6e62';

// DEBUG via Elevar's Data Layer Listener, add to the console:
// * Turn on: window.ElevarDebugMode(true)
// * Turn off: window.ElevarDebugMode(false)

// ENVS to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name
// * NEXT_PUBLIC_ELEVAR_SIGNING_KEY // unless key is hardcoded above

// IMPORTANT:
// * Elevar version 3.9.0+ required for this specific integration
// * For integration for earlier versions, reference codebase of previous stater kit: https://app.gitbook.com/o/-MaVT_K8VIPo7BLBVUfs/s/Ppd8OcidzJxwuOIIChl2/developer-handbook/starter-kit

export function DataLayerWithElevar() {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  return (
    ELEVAR_SIGNING_KEY && (
      <>
        {/* Non-Shopify Subdomains Source */}
        {/* Ensure Script has an id */}

        <Script type="module" id="elevar-script">
          {`try {
    const settings = {};
    const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/${ELEVAR_SIGNING_KEY}/config.js")).default;
    const scriptUrl = settings.proxyPath
      ? settings.proxyPath + config.script_src_custom_pages_proxied
      : config.script_src_custom_pages;

    if (scriptUrl) {
      const { handler } = await import(scriptUrl);
      await handler(config, settings);
    }
  } catch (error) {
    console.error("Elevar Error:", error);
  }`}
        </Script>
      </>
    )
  );
}

DataLayerWithElevar.displayName = 'DataLayerWithElevar';
