import { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Paragraph, Image } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import Player from '@vimeo/player';
import Link from 'next/link';

import { BackpackImage, Markdown } from '@snippets';

import Schema from './Schema';
import { FiftyFiftyTileCarousel } from './FiftyFiftyTileCarousel';
import { Routine } from './Routine';

export function FiftyFiftyTile({
  cms: { mediaSettings, detailsSettings, containerSettings },
}) {
  const genericVideoRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const [paused, setPaused] = useState(false);

  const videoBoxRef = useRef(null);

  const media = {
    mediaPosition: mediaSettings?.mediaPosition,
    image: mediaSettings?.image,
    images: mediaSettings?.images,
    vimeoLink: mediaSettings?.vimeoLink,
    genericVideoLink: mediaSettings?.genericVideoLink,
  };

  const details = {
    header: detailsSettings?.heading || '',
    headingColor: detailsSettings?.headingColor || 'primary',
    ctaText: detailsSettings?.cta?.text || '',
    ctaPath: detailsSettings?.cta?.url || '#',
    ctaStyle: detailsSettings?.buttonStyle || 'secondary',
    moreDetailsText: detailsSettings?.moreDetails?.text || '',
    moreDetailsPath: detailsSettings?.moreDetails?.url || '#',
  };

  const detailsContent = detailsSettings?.details || [];

  const handleVideoClick = () => {
    if (paused) {
      genericVideoRef.current.play();
      setPaused(false);
    } else {
      genericVideoRef.current.pause();
      setPaused(true);
    }
  };

  useEffect(() => {
    if (media.genericVideoLink || !media.vimeoLink || !videoBoxRef.current) {
      return;
    }

    // Some settings wont work unless (PRO, or Business members)
    new Player(videoBoxRef.current, {
      id: media.vimeoLink,
      loop: true,
      autoplay: true,
      controls: false,
      background: true,
      responsive: true,
      muted: true,
      quality: '540p',
    });
  }, [media.vimeoLink, videoBoxRef]);

  return (
    <Container
      data-comp={FiftyFiftyTile.displayName}
      variant={
        containerSettings?.fullWidth ? 'layout.full' : 'layout.container'
      }
      sx={{
        mb: [9, null, null, null, null, null, 13],
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, null, null, 'repeat(2, 1fr)'],
          gridTemplateAreas: [
            '"media" "details"',
            null,
            null,
            null,
            media?.mediaPosition ? '"details media"' : '"media details"',
          ],
        }}
      >
        <Box
          sx={{
            gridArea: 'media',
            paddingBottom: '100%', // This will make a perfect square
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {media.genericVideoLink ? (
              <Box
                ref={ref}
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  as="video"
                  ref={genericVideoRef}
                  muted
                  playsInline
                  loop
                  autoPlay={inView}
                  sx={{
                    position: 'absolute',
                    height: '101%',
                    width: '101%',
                    objectFit: 'cover',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  onClick={handleVideoClick}
                >
                  {inView && (
                    <source
                      key={media.genericVideoLink}
                      src={media.genericVideoLink}
                      type="video/mp4"
                    />
                  )}
                </Box>
              </Box>
            ) : media.vimeoLink ? (
              <Box
                ref={videoBoxRef}
                sx={{
                  width: '100%',
                  display: 'block',
                }}
              />
            ) : media?.images?.length > 0 ? (
              <FiftyFiftyTileCarousel images={media.images} />
            ) : (
              <BackpackImage
                src={media?.image?.src || media?.image}
                alt={details.header}
                layout="fill"
                sx={{
                  width: '100%',
                  display: 'block',
                }}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            alignItems: ['flex-start', null, null, null, null, null, 'center'],
            backgroundColor: 'whites.3',
            display: 'flex',
            flexDirection: 'column',
            gridArea: 'details',
            justifyContent: 'center',
            textAlign: 'left',
            px: 10,
            py: 9,
          }}
        >
          <Box sx={{ maxWidth: '488px' }}>
            <Paragraph
              variant="text.h2.r.1"
              sx={{
                color: details.headingColor,
                mb: [8, null, null, null, null, null, 10],
              }}
            >
              {details.header}
            </Paragraph>

            <Box as="ul" sx={{ mb: 10, listStyleType: 'none', px: 0 }}>
              {detailsContent.map((detail, index) => {
                return (
                  <Box as="li" key={index} sx={{ mb: 8 }}>
                    <Paragraph
                      variant="text.h4.r.1"
                      sx={{
                        mb: [2, null, null, null, null, null, 3],
                      }}
                    >
                      {detail.heading}
                    </Paragraph>

                    <Box>
                      <Box sx={{ variant: 'text.body.r.1' }}>
                        <Markdown text={detail?.description} />
                      </Box>
                      {detail?.routine && <Routine steps={detail?.routine} />}
                      {detail?.use && detail?.use !== 'none' && (
                        <Image
                          src={`/svgs/${detail?.use}.svg`}
                          sx={{
                            width: `${detail?.width}` || '20%',
                            userSelect: 'none',
                          }}
                          draggable={false}
                        />
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>

            {details.ctaText && (
              <Box sx={{ alignSelf: 'flex-start' }}>
                <Link href={details.ctaPath}>
                  <Button variant={`buttons.${details.ctaStyle}`}>
                    {details.ctaText}
                  </Button>
                </Link>
              </Box>
            )}

            {details.moreDetailsText && (
              <Box
                sx={{
                  alignSelf: 'flex-start',
                  mt: 10,
                }}
              >
                <Link href={details.moreDetailsPath}>
                  <Button variant="buttons.link.main">
                    {details.moreDetailsText}
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

FiftyFiftyTile.displayName = 'FiftyFiftyTile';

FiftyFiftyTile.Schema = Schema;
const MarkdownComponents = {
  // Code will go here
  p: (paragraph) => {
    const { node } = paragraph;

    if (node.children[0].tagName === 'img') {
      const image = node.children[0];
      const metastring = image.properties.alt;
      const alt = metastring?.replace(/ *\{[^)]*\} */g, '');
      const metaWidth = metastring.match(/{([^}]+)x/);
      const metaHeight = metastring.match(/x([^}]+)}/);
      const width = metaWidth ? metaWidth[1] : '768';
      const height = metaHeight ? metaHeight[1] : '432';
      const isPriority = metastring?.toLowerCase().match('{priority}');
      const hasCaption = metastring?.toLowerCase().includes('{caption:');
      const caption = metastring?.match(/{caption: (.*?)}/)?.pop();

      console.log({
        image,
        metastring,
        alt,
        metaWidth,
        metaHeight,
        width,
        height,
        isPriority,
        hasCaption,
        caption,
      });

      return (
        <div className="postImgWrapper">
          <Image
            src={image.properties.src}
            width={width}
            height={height}
            className="postImg"
            alt={alt}
            priority={isPriority}
          />
          {hasCaption ? (
            <div className="caption" aria-label={caption}>
              {caption}
            </div>
          ) : null}
        </div>
      );
    }
    return <p>{paragraph.children}</p>;
  },
};
