import { Box, Paragraph } from 'theme-ui';
import Link from 'next/link';

export function MenuItem({ item }) {
  return (
    <Box
      as="li"
      sx={{
        pl: 11,
      }}
    >
      <Link href={item?.link?.url || '#'}>
        <Paragraph
          data-comp="MenuItem"
          variant="text.label.3"
          sx={{
            position: 'relative',
            cursor: 'pointer',
            opacity: 1,
            width: 'fit-content',
            textTransform: 'uppercase',
            transition: '0.25s ease-in-out',
            '&:hover': {
              color: 'primary',
            },
            '&:hover::after': {
              opacity: 1,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              height: '8px',
              width: '8px',
              bg: 'primary',
              top: '50%',
              opacity: 0,
              transform: 'translateY(-50%)',
              left: '-16px',
              borderRadius: '50%',
              transition: '0.25s ease-in-out',
            },
          }}
        >
          {item?.link?.text}
        </Paragraph>
      </Link>
    </Box>
  );
}
