import { Box } from 'theme-ui';
import { useEffect } from 'react';
import { isBrowser } from '@utils';
import { useInView } from 'react-intersection-observer';

export function StarBadges({ product }) {
  // useStampedRefreshOnMount({ delay: 500 });
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (isBrowser && inView) {
      try {
        if (window.StampedFn) window.StampedFn?.loadBadges();
      } catch (e) {
        console.error('error loading stamped badges', e);
      }
    }
  }, [inView]);

  return (
    <Box
      ref={ref}
      data-comp={StarBadges.displayName}
      sx={{
        minHeight: '21px',
        '.stamped-starrating': {
          mr: '8px !important',
        },
        '.stamped-fa-star, .stamped-fa-star-half-o, .stamped-fa-star-o': {
          color: 'black',
        },
        '.stamped-badge-caption': {
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'uppercase',
          textDecoration: 'underline',
        },
        '.fa-star-half-o:before, .fa-star-o:before, .fa-star:before, .stamped-fa-star-half-o:before, .stamped-fa-star-o:before, .stamped-fa-star:before':
          {
            fontSize: '15px',
          },
      }}
    >
      <span
        className="stamped-product-reviews-badge stamped-main-badge font-circular"
        data-id={product?.legacyResourceId}
        data-product-title={product?.title}
        data-product-type={product?.productType}
        data-product-sku={product?.variants?.[0]?.sku}
      />
    </Box>
  );
}
