import React from 'react';
import dynamic from 'next/dynamic';
import { Paragraph, Flex, Spinner, Image, Box } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';
import { isBrowser } from '@utils';

import store, {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from '@store';
import { SearchInput } from '@snippets';
import { Cart } from '../Cart';

const SearchSuggestionsOrResults = dynamic(
  () =>
    import('../Search/SearchSuggestionsOrResults').then(
      (module) => module.SearchSuggestionsOrResults
    ),
  { ssr: false }
);

export function SideDrawer(props) {
  // useCartInit();
  const cartCount = useCartCount();
  const setOverlay = useSetRecoilState(store.overlay);
  const [sideDrawer, setSideDrawer] = useRecoilState(store.sideDrawer);
  const sideDrawerIsCart$ = useRecoilValue(store.sideDrawerIsCart$);

  return (
    <Box
      as="aside"
      data-comp="SideDrawer"
      {...props}
      sx={{
        zIndex: 50,
        position: 'fixed',
        top: '0',
        right: 0,
        height: '100%',
        width: ['100%', null, null, null, '375px'],
        backgroundColor: 'white',
        boxShadow: '10px 0px 30px rgb(0 0 0 / 8%)',
        transform: sideDrawer ? 'translateX(0)' : 'translateX(100%)',
        willChange: 'transform',
        transition: 'transform .3s ease-in-out',
        ...props.sx,
      }}
    >
      {/* content area */}
      <Box
        data-comp="SideDrawerContainer"
        sx={{
          height: '100%',
          minHeight: 0,
          // webkitOverflowScrolling: 'touch',
          // msOverflowStyle: 'none',
          // scrollbarWidth: 'none',
          // '::-webkit-scrollbar': {
          //   display: 'none',
          // },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        {/* Head */}
        <Box
          sx={{
            backgroundColor: 'white',
            background: 'white',
            zIndex: 60,
            boxShadow: 'bottom',
          }}
        >
          <Flex
            sx={{
              height: '48px',
              alignItems: 'center',
              px: 9,
            }}
          >
            <Flex
              sx={{
                pr: 9,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                height: '100%',
              }}
              onClick={() => {
                setSideDrawer(null);
                setOverlay(false);

                if (document.getElementById('attentive_overlay')) {
                  document.getElementById('attentive_overlay').style.display =
                    'block';
                }
              }}
            >
              <Image
                tabIndex="0"
                alt={sideDrawerIsCart$ ? 'Close Cart' : 'Close Search'}
                src="/svg/left-arrow.svg"
                height="16px"
              />
            </Flex>

            <Paragraph variant="text.label.3">
              {sideDrawerIsCart$ ? `CART (${cartCount})` : 'SEARCH'}
            </Paragraph>

            <Box
              tabIndex="0"
              sx={{
                position: 'relative',
                ml: 'auto',
                cursor: 'pointer',
                height: 'auto',
              }}
            >
              {!sideDrawerIsCart$ ? (
                <Paragraph
                  sx={{
                    display: cartCount ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    color: 'white',
                    backgroundColor: 'primary',
                    fontSize: '10px',
                    top: '-5px',
                    right: '-14px',
                    padding: '2px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    width: '20px',
                    height: '20px',
                  }}
                >
                  {cartCount}
                </Paragraph>
              ) : null}

              {/* Search or Cart Toggle Switch */}
              <Image
                onClick={() => {
                  setSideDrawer(sideDrawerIsCart$ ? 'search' : 'cart');
                }}
                alt={sideDrawerIsCart$ ? 'Switch to Search' : 'Switch to Cart'}
                src={`/svg/${sideDrawerIsCart$ ? 'search' : 'cart'}.svg`}
                width={sideDrawerIsCart$ ? '14px' : '16px'}
              />
            </Box>
          </Flex>
        </Box>

        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 48px)',
            flexGrow: 0,
          }}
        >
          {sideDrawerIsCart$ ? (
            <Cart isDrawer />
          ) : (
            <Box sx={{ px: 9, overflow: 'scroll' }}>
              <SearchInput alwaysFocused />

              {isBrowser && (
                <React.Suspense fallback={<SearchOrMenusLoader />}>
                  <SearchSuggestionsOrResults />
                </React.Suspense>
              )}
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
}

// [todo]: Repeating..
function SearchOrMenusLoader() {
  return (
    <Flex
      data-comp="SearchOrMenusLoader"
      variant="flex.row.center"
      sx={{
        height: `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height) - 200px)`,
      }}
    >
      <Spinner />
    </Flex>
  );
}
