import React, { useEffect, useState } from 'react';
import { Box, Flex, Paragraph, Text } from 'theme-ui';

import {
  useCartCount,
  useCartTotals,
  useSettings,
} from '@backpackjs/storefront';

export function FreeShippingProgress({}) {
  const [meterProgress, setMeterProgress] = useState(0);
  const [priceDifference, setPriceDifference] = useState(null);
  const siteSettings = useSettings();
  const {
    enableFreeShipping = false,
    freeShippingCartMin,
    freeShippingName,
  } = siteSettings?.cart?.freeShippingMeter;
  const cartTotalPrice = useCartTotals();
  const cartSubtotal = cartTotalPrice?.subtotal;
  const cartCount = useCartCount();

  useEffect(() => {
    if (!enableFreeShipping || !freeShippingCartMin || !cartCount) return;
    const min = parseFloat(freeShippingCartMin);
    const progress = cartSubtotal / min;
    const diff = min - cartSubtotal;
    setPriceDifference(!diff ? min : diff <= 0 ? 0 : diff);
    setMeterProgress(!progress ? 0 : progress >= 1 ? 100 : progress * 100);
  }, [cartSubtotal, freeShippingCartMin]);

  if (!enableFreeShipping || !freeShippingCartMin || !cartCount) return null;

  return (
    <Box
      data-comp="FreeShippingMeter"
      sx={{
        backgroundColor: 'white',
        width: '100%',
        overflowX: 'hidden',
        borderTop: '1px solid #DCDCDC',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          minHeight: '50px',
          alignItems: 'center',
          justifyContent: 'center',
          px: 9,
        }}
      >
        {priceDifference !== null ? (
          priceDifference ? (
            <Paragraph variant="text.label.2">
              You're{' '}
              <Text sx={{ fontWeight: 600 }}>
                ${priceDifference.toFixed(2)}
              </Text>{' '}
              away from {freeShippingName || 'Free Shipping'}!
            </Paragraph>
          ) : (
            <Paragraph variant="text.label.2">
              You’re Eligible for{' '}
              <Text sx={{ fontWeight: 600 }}>
                {freeShippingName || 'Free Shipping'}
              </Text>
              !
            </Paragraph>
          )
        ) : null}
      </Flex>
      <Box
        sx={{
          marginLeft: '16px',
          position: 'relative',
          height: '12px',
          width: 'calc(100% - 32px)',
          backgroundColor: '#E8E7E5',
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            height: '100%',
            position: 'absolute',
            backgroundColor: 'primary',
            top: 0,
            bottom: 0,
            left: 0,
            transition: 'width .2s ease-in',
            width: `${meterProgress}%`,
            borderRadius: '6px',
          }}
        />
      </Box>
    </Box>
  );
}
