import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Box, Container, ThemeProvider } from 'theme-ui';

import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  useBottomToIosFooterBar,
  useShopifyAnalytics,
  useSetViewportHeightCssVar,
} from '@hooks';

import { Footer, Header, Modal, Overlay, SideDrawer } from '@snippets';
import { CookieConsent } from '@snippets/CookieConsent';
import { LocationMessage } from '@snippets/LocationMessage';
import { DataLayerWithElevar } from '@snippets/DataLayerWithElevar';

import * as Head from '../head';
import StorefrontTheme from '../theme';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const router = useRouter();
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // adds var(--viewport-height) too root
  useSetViewportHeightCssVar();

  useShopifyAnalytics({ collection: props.collection, product: props.product });

  // wip
  useBottomToIosFooterBar();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // In Storefront Settings you can use the "Redirect" setting to set up
      // a global redirect that redirects all pages except the ones listed in
      // to exclude
      if (settings && settings?.redirect?.redirectEnabled && router) {
        const excludedHandles = settings?.redirect?.redirectExclude;
        const redirectTo = settings?.redirect?.redirectTo;

        if (!excludedHandles.includes(router.asPath) && redirectTo) {
          window.location.replace(redirectTo);
        }
      }

      // In Storefront Settings you can use the "Hide Header and Footer" setting
      // to hide the header and footer on specific pages
      if (
        settings &&
        settings?.hideHeaderFooter?.hideHeaderFooterUrls &&
        router
      ) {
        const hideHeaderFooterUrls =
          settings?.hideHeaderFooter?.hideHeaderFooterUrls;
        setHideHeaderFooter(hideHeaderFooterUrls.includes(router.asPath));
      }
    }
  }, [settings, hideHeaderFooter]);

  return (
    <ThemeProvider theme={StorefrontTheme}>
      {/* <head> links, scripts... */}
      <Head.Fonts />
      <Head.Libs />
      <Head.Metas />
      <Head.Preconnects />
      <DataLayerWithElevar />

      {settings?.header && !hideHeaderFooter && <Header />}

      {/* main content */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight:
            'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
          alignItems: 'stretch',
        }}
      >
        <Container
          as="main"
          variant="layout.full"
          sx={{ flexGrow: 1, flexShrink: 0 }}
        >
          <ContentForLayout {...props} key={router.asPath} />
        </Container>

        {!hideHeaderFooter && (
          <Box sx={{ flexShrink: 0 }}>
            <Footer />
          </Box>
        )}
      </Box>

      <CookieConsent />

      <Overlay />
      <SideDrawer />
      <Modal />

      {settings?.locationSettings ? (
        <LocationMessage settings={settings?.locationSettings} />
      ) : null}
    </ThemeProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
