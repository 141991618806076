import React, { useEffect } from 'react';
import { Box, Container, Flex, Paragraph, Link } from 'theme-ui';

export function Instagram({ cms }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.instafeedApp) {
      window.instafeedApp();
    }
  }, []);

  return (
    <Container variant="layout.container" data-comp={Instagram.displayName}>
      <Flex
        data-comp="InstagramSection"
        variant="flex.column"
        sx={{ pb: [10, null, null, null, 12, 13], overflow: 'hidden' }}
      >
        <Link
          href={cms.subHeadingUrl || '#'}
          target="_blank"
          title={cms.heading || ''}
          rel="noreferrer"
          sx={{
            textDecoration: 'none',
          }}
        >
          {cms.heading ? (
            <Paragraph
              variant="text.h2.r.1"
              sx={{
                cursor: 'pointer',
                color: 'primary',
                textAlign: ['center', null, null, 'center'],
                mb: [3, null, null, null, 4],
              }}
            >
              {cms.heading}
            </Paragraph>
          ) : null}
        </Link>

        <Link
          href={cms.subHeadingUrl || '#'}
          target="_blank"
          title={cms.subHeading || ''}
          rel="noreferrer"
          sx={{
            textDecoration: 'none',
          }}
        >
          {cms.subHeading ? (
            <Paragraph
              variant="text.body.r.1"
              sx={{
                cursor: 'pointer',
                textAlign: ['center', null, null, 'center'],
                mb: [6, null, null, null, 8],
              }}
            >
              {cms.subHeading}
            </Paragraph>
          ) : null}
        </Link>

        <Box
          sx={{
            height: '1px',
            bg: 'black',
            ml: 'auto',
            mr: 'auto',
            mb: 10,
            width: ['48px', null, null, '72px'],
            position: 'relative',
            '&::before, &::after': {
              content: '""',
              display: 'block',
              width: '8px',
              height: '8px',
              border: '1px solid black',
              borderRadius: '50%',
              backgroundColor: 'white',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            },
            '&::before': {
              left: 0,
            },
            '&::after': {
              right: 0,
            },
          }}
        />

        <Box
          id="insta-feed"
          sx={{
            width: '100%',
            mt: '0px !important',
            '& > h2': {
              display: 'none',
            },
            '.slider-arrow': {
              position: 'absolute',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
              top: '50%',
              transform: 'translateY(-50%)',
            },
          }}
        />
      </Flex>
    </Container>
  );
}

Instagram.displayName = 'InstagramSlider';

Instagram.Schema = {
  label: 'Instagram Slider',
  key: 'instagram-slider',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Nullam Ligula Purus Aenean',
    },
    {
      label: 'Sub heading',
      name: 'subHeading',
      component: 'text',
      defaultValue: 'Follow us @GoodMolecules',
    },
    {
      label: 'Headings URL',
      name: 'subHeadingUrl',
      component: 'text',
      defaultValue: 'https://www.instagram.com/goodmolecules/',
    },
  ],
};
