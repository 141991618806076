import React from 'react';
import Head from 'next/head';

function HeadMetas() {
  const meta = [
    {
      key: 'viewport-meta',
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      key: 'http-equiv',
      httpEquiv: 'Content-Type',
      content: 'text/html; charset=utf-8',
    },
    {
      key: 'keywords',
      name: 'keywords',
      content:
        'Good Molecules, Skincare, Discoloration Correcting Serum, Niacinamide Brightening Toner',
    },
    {
      key: 'description',
      name: 'description',
      content:
        'Good Molecules: See a difference in your skin. Never tested on animals.',
    },
  ];
  const link = [
    {
      key: 'shortcut icon',
      rel: 'shortcut icon',
      href: '/favicon.png',
    },
    {
      key: 'author',
      rel: 'author',
      href: 'https://www.goodmolecules.com',
    },
  ];
  return (
    <Head>
      {meta.map((item) => {
        return <meta key={item.key} {...item} />;
      })}
      {link.map((item) => {
        return <link key={item.key} {...item} />;
      })}
    </Head>
  );
}

export default HeadMetas;
