export const text = {
  description: {
    all: 'unset',
    color: 'var(--tina-color-grey-6)',
    display: 'block',
    fontSize: 'var(--tina-font-size-0)',
    fontStyle: 'initial',
    fontWeight: 400,
    lineHeight: 1.35,
    py: '4px',
    whiteSpace: 'pre-wrap',
  },
  display: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: 80,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 52,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // "Display/Mobile → Display/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [14, null, null, null, null, null, 15],
        lineHeight: [52, null, null, null, null, null, 80],
        textTransform: 'uppercase',
      },
    },
  },
  h1: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: 48,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 42,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H1/Mobile → H2/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [12, null, null, null, 13],
        lineHeight: [42, null, null, null, 48],
        textTransform: 'uppercase',
      },
    },
  },
  h2: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 11,
      lineHeight: 40,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: 36,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H2/Mobile → H2/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [10, null, null, null, 11],
        lineHeight: [36, null, null, null, 40],
        textTransform: 'uppercase',
      },
      2: {
        // H2/Mobile → H1/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [10, null, null, null, 13],
        lineHeight: [36, null, null, null, 48],
        textTransform: 'uppercase',
      },
      3: {
        // H2/Mobile → H3/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [10, null, null, null, 11],
        lineHeight: [36, null, null, null, 40],
        textTransform: 'uppercase',
      },
    },
  },
  h3: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: 36,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 9,
      lineHeight: 32,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H3/Mobile → H3/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [9, null, null, null, 10],
        lineHeight: [32, null, null, null, 36],
        textTransform: 'uppercase',
      },
    },
  },
  h4: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 9,
      lineHeight: 32,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 8,
      lineHeight: 28,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H4/Mobile → H4/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [8, null, null, null, 9],
        lineHeight: [28, null, null, null, 32],
        textTransform: 'uppercase',
      },
    },
  },
  h5: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 6,
      lineHeight: 22,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 5,
      lineHeight: 18,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H5/Mobile → H5/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [5, null, null, null, 6],
        lineHeight: [18, null, null, null, 22],
        textTransform: 'uppercase',
      },
    },
  },
  h6: {
    d: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 3,
      lineHeight: 18,
      textTransform: 'uppercase',
    },
    m: {
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 2,
      lineHeight: 18,
      textTransform: 'uppercase',
    },
    r: {
      1: {
        // H6/Mobile → H6/Desktop
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: [2, null, null, null, 3],
        lineHeight: [18, null, null, null, 18],
        textTransform: 'uppercase',
      },
    },
  },
  body: {
    xxs: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 0,
      lineHeight: 14,
    },
    xs: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 2,
      lineHeight: 16,
    },
    s: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 4,
      lineHeight: 18,
    },
    reg: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 5,
      lineHeight: 22,
    },
    l: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 7,
      lineHeight: 26,
    },
    xl: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 8,
      lineHeight: 28,
    },
    r: {
      1: {
        // Body/Regular → Body/X-Large
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: [5, null, null, null, 8],
        lineHeight: [22, null, null, null, 28],
      },
      2: {
        // Body/Small → Body/Large
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: [4, null, null, null, 7],
        lineHeight: [18, null, null, null, 26],
      },
      3: {
        // Body/Regular → Body/Large
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: [5, null, null, null, 7],
        lineHeight: [22, null, null, null, 26],
      },
    },
  },
  label: {
    1: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 0,
      lineHeight: 16,
      letterSpacing: '.16',
      textTransform: 'uppercase',
    },
    2: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 2,
      lineHeight: 14,
      letterSpacing: '.16',
      textTransform: 'uppercase',
    },
    3: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 4,
      lineHeight: 18,
      letterSpacing: '.16',
      textTransform: 'uppercase',
    },
  },
  buttons: {
    primary: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 3,
      lineHeight: 18,
      letterSpacing: 1.5,
      textTransform: 'uppercase',
    },
    small: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 0,
      lineHeight: 14,
      letterSpacing: '1.5',
      textTransform: 'uppercase',
    },
  },
  links: {
    normal: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 1,
      lineHeight: 18,
      textTransform: 'uppercase',
    },
    main: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 2,
      lineHeight: 16,
      letterSpacing: '.32',
      textTransform: 'uppercase',
    },
    small: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 0,
      lineHeight: 16,
      letterSpacing: 0.16,
      textTransform: 'uppercase',
    },
    text: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 1,
      lineHeight: 18,
      textTransform: 'uppercase',
    },
  },
};
