import { Container } from 'theme-ui';

import { ProductSwiper } from '@snippets';

export function ProductList({ cms }) {
  return (
    <Container
      variant="layout.container"
      data-comp="ProductList"
      sx={{
        mb: [9, null, null, null, null, null, 13],
        px: 0,
      }}
    >
      <ProductSwiper
        products={cms.products}
        heading={cms.heading}
        cta={{
          ...cms.cta,
          buttonStyle: cms.buttonStyle,
        }}
      />
    </Container>
  );
}

ProductList.Schema = {
  label: 'Product List',
  key: 'products-list',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'Our Products',
    },
    {
      name: 'cta',
      component: 'link',
      label: 'CTA',
      defaultValue: {
        text: 'Shop All',
        url: '/',
      },
    },
    {
      name: 'buttonStyle',
      component: 'select',
      label: 'Button Style',
      options: [
        {
          value: 'primary',
          label: 'Primary',
        },
        {
          value: 'secondary',
          label: 'Secondary',
        },
        {
          value: 'outline',
          label: 'Outline',
        },
        {
          value: 'link.main',
          label: 'Link',
        },
      ],
      defaultValue: 'primary',
    },
    {
      name: 'products',
      label: 'Products',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
      defaultValue: [
        {
          product: {
            handle: 'bha-clarify-gel-cream',
            title: 'BHA Clarify Gel Cream',
          },
        },
        {
          product: {
            handle: 'gentle-retinol-cream',
            title: 'Gentle Retinol Cream',
          },
        },
        {
          product: {
            handle: 'squalane-oil',
            title: 'Squalane Oil',
          },
        },
        {
          product: {
            handle: 'bha-clarify-gel-cream',
            title: 'BHA Clarify Gel Cream',
          },
        },
        {
          product: {
            handle: 'squalane-oil',
            title: 'Squalane Oil',
          },
        },
      ],
    },
  ],
};
