import { useEffect, useRef } from 'react';
import { isBrowser } from '@utils';

export const useBottomToIosFooterBar = (
  { targetRef = null } = { targetRef: null }
) => {
  const baseWindowHeight = useRef(isBrowser ? Math.max(window.innerHeight) : 0);
  const bodyRef = useRef(isBrowser ? document.body : null);
  // .ios-toolbar-gone button {
  //   transform: translateY(-2rem);
  // }

  useEffect(() => {
    if (!targetRef?.current || !isBrowser) return;
    /*
      Maybe try with an observer for performance.
      I could have hidden spacer behind the sidebar with 50px height
      and detect when its out of the screen
    */
    const updateHeight = (e) => {
      const newWindowHeight = Math.max(window.innerHeight);
      if (newWindowHeight - 50 > baseWindowHeight.current) {
        if (!bodyRef.current.classList.contains('ios-toolbar-gone')) {
          bodyRef.current.classList.add('ios-toolbar-gone');
        }
      } else if (bodyRef.current.classList.contains('ios-toolbar-gone')) {
        bodyRef.current.classList.remove('ios-toolbar-gone');
      }
    };
    document.addEventListener('scroll', updateHeight);

    return () => {
      document.removeEventListener('scroll', updateHeight);
    };
  }, []);
};
