import store, { useRecoilValue } from '@store';
import { SearchResults, SearchSuggestions } from '@snippets';

export function SearchSuggestionsOrResults(props) {
  const menuDrawerOpen = useRecoilValue(store.menuDrawerOpen);
  const sideDrawerIsSearch = useRecoilValue(store.sideDrawerIsSearch$);
  const searchIsFocused = useRecoilValue(store.searchIsFocused);
  const searchResults = useRecoilValue(store.searchResults$);
  const results =
    searchResults?.products.length || searchResults?.collections.length;
  return (
    <>
      {/* or this */}
      <SearchResults
        sx={{
          display: results ? 'block' : 'none',
        }}
      />
      {/* this */}
      <SearchSuggestions
        sx={{
          display: [
            // on mobile show suggestion if searchInput is focused, drawer is open and no results
            (menuDrawerOpen && searchIsFocused && !searchResults) ||
            // or on mobile show suggestion if no results and sideBar is open
            (sideDrawerIsSearch && !results)
              ? 'block'
              : 'none',
            null,
            null,
            null,
            // on desktop (SideBar) always show suggestions unless you have results
            !searchResults ? 'block' : 'none',
          ],
          mt: 5,
        }}
      />
    </>
  );
}
