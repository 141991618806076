import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Paragraph, Text } from 'theme-ui';

import {
  useCart,
  useCartAddItem,
  useCartCount,
  useCartRemoveItem,
  useCartTotals,
  useCartUpdateItem,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';
import Image from 'next/image';

export function GiftWithPurchase({ }) {
  const [meterProgress, setMeterProgress] = useState(0);
  // Just a value different from zero
  const [priceDifference, setPriceDifference] = useState(1);
  const siteSettings = useSettings();
  const {
    enableGiftWithPurchase = false,
    giftWithPurchaseMinCartSpend,
    giftWithPurchaseProduct,
  } = siteSettings?.cart?.giftWithPurchase ?? {};
  const cartTotalPrice = useCartTotals();
  const cartSubtotal = cartTotalPrice?.subtotal;
  const cartCount = useCartCount();
  const { cartAddItem } = useCartAddItem();
  const { product } = useProductByHandle({ handle: giftWithPurchaseProduct?.handle });
  const cart = useCart();
  const { cartRemoveItem } = useCartRemoveItem();
  const { cartUpdateItem } = useCartUpdateItem();

  useEffect(() => {
    if (!enableGiftWithPurchase || !giftWithPurchaseMinCartSpend || !cartCount)
      return;
    const min = parseFloat(giftWithPurchaseMinCartSpend);
    const progress = cartSubtotal / min;
    const diff = min - cartSubtotal;
    setPriceDifference(diff);
    setMeterProgress(!progress ? 0 : progress >= 1 ? 100 : progress * 100);
  }, [cartSubtotal, giftWithPurchaseMinCartSpend]);

  // Does the cart contain a Store Pack? Store Packs are identified by the
  // prefix "SP-" in the SKU. If so, we disable the whole GWP UI.
  const hasStorePack = useMemo(
    () =>
      cart?.lines?.find(
        (item) => item?.variant?.sku?.startsWith('SP-')
      ),
    [cart]
  );

  const isEligible = priceDifference <= 0 && !hasStorePack;

  // Is the gift already in the cart?
  const giftItem = useMemo(
    () =>
      cart?.lines?.find(
        (item) => item?.variant?.id === product?.variants[0]?.id
      ),
    [cart, product]
  );

  useEffect(() => {
    const exec = async () => {
      if (!isEligible) {
        if (giftItem) {
          await cartRemoveItem({ lineId: giftItem.id });
        }
      } else if (giftItem) {
        if (giftItem.quantity > 1) {
          await cartUpdateItem({ lineId: giftItem.id, quantity: 1 });
        }
      }
    };
    exec();
  }, [cart, cartRemoveItem, product]);

  if (!enableGiftWithPurchase || !giftWithPurchaseMinCartSpend || !cartCount || hasStorePack)
    return null;

  const isSoldOut = false;
  const addToCartText = !isSoldOut
    ? giftItem
      ? 'ADDED'
      : 'CLAIM GIFT'
    : 'SOLD OUT';

  return (
    <Box
      data-comp="GiftWithPurchase"
      sx={{
        backgroundColor: 'white',
        width: '100%',
        overflowX: 'hidden',
        textAlign: 'center',
        mt: 8,
      }}
    >
      <Flex
        sx={{
          width: '100%',
          px: 8,
        }}
      >
        <Image
          src={product?.images[0].src}
          alt={product?.images[0].altText}
          width={80}
          height={80}
          sx={{ mt: 2 }}
        />
        <Flex
          sx={{
            width: '100%',
            minHeight: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            ml: 6,
          }}
        >
          {!isEligible ? (
            <Paragraph variant="text.label.2">
              Add{' '}
              <Text sx={{ fontWeight: 600 }}>
                ${priceDifference.toFixed(2)}
              </Text>{' '}
              to unlock your free {giftWithPurchaseProduct.data.title}!
            </Paragraph>
          ) : giftItem ? (
            <Paragraph variant="text.label.2">
              <Text sx={{ fontWeight: 600 }}>{giftWithPurchaseProduct.data.title}</Text> added to
              your bag!
            </Paragraph>
          ) : (
            <Paragraph variant="text.label.2">
              You’re Eligible for your free{' '}
              <Text sx={{ fontWeight: 600 }}>{giftWithPurchaseProduct.data.title}</Text>!
            </Paragraph>
          )}
        </Flex>
      </Flex>
      <Box
        sx={{
          ml: 8,
          mt: 8,
          position: 'relative',
          height: '12px',
          width: 'calc(100% - 32px)',
          backgroundColor: '#E8E7E5',
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            height: '100%',
            position: 'absolute',
            backgroundColor: 'primary',
            top: 0,
            bottom: 0,
            left: 0,
            transition: 'width .2s ease-in',
            width: `${meterProgress}%`,
            borderRadius: '6px',
          }}
        />
      </Box>
      <Button
        variant="buttons.secondary"
        disabled={isSoldOut || !isEligible || !!giftItem}
        sx={{
          cursor: isSoldOut ? 'default' : 'pointer',
          outline: isSoldOut ? 'none' : null,
          mt: 6,
        }}
        onClick={async () => {
          if (isSoldOut || !isEligible || !!giftItem) return;
          if (!product.variants?.[0]?.id) {
            return console.error('Missing product variant id');
          }
          await cartAddItem({
            merchandiseId: product.variants?.[0]?.id,
            quantity: 1,
          });
        }}
      >
        {addToCartText}
      </Button>
    </Box>
  );
}
