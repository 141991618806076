import React from 'react';
import { isBrowser } from '@utils';

let originalOverflow = false;

export const useBodyScrollLock = (props) => {
  const scrollElement = props?.scrollElement || null;
  const scrollContainer = React.useRef(
    isBrowser ? scrollElement || document.documentElement : null
  );

  if (!isBrowser) {
    // SSR default
    return {
      locked: false,
      lockScroll: () => {},
      unlockScroll: () => {},
    };
  }

  const lockScroll = (props) => {
    const isLocked =
      scrollContainer.current.classList.contains('scroll-locked');
    if (isLocked) {
      return;
    }

    const onComplete = props?.onComplete || null;

    /*
      To prevent the body from jumping horizontally
      (due to scrollbar disappearing) after we set overflowY=hidden
      we capture the scrollbar width and set it as margin-right
    */
    const scrollbarWidthPx = window.innerWidth - document.body.clientWidth || 0;

    // set scrollbar to black to ensure the promobar "looks full width" (7 Diamonds specific)
    const to = window.requestTimeout(() => {
      document.documentElement.style.backgroundColor = 'black';
      clearRequestTimeout(to);
    }, 100);

    // Cache the original document overflow value
    originalOverflow = window.getComputedStyle(
      scrollContainer.current
    ).overflowY;

    // prevent scroll
    scrollContainer.current.style.overflowY = 'hidden';

    // set margin right to offset missing scrollbar
    // scrollContainer.current.style.marginRight = `${scrollbarWidthPx}px`;
    try {
      document.querySelector(
        'div[class^="StyledToolbar-sc-"]'
      ).style.marginRight = `${scrollbarWidthPx}px`;
    } catch (error) {
      //
    }

    // set class to monitor locked state
    scrollContainer.current.classList.toggle('scroll-locked');

    if (typeof onComplete === 'function') onComplete();
  };

  const unlockScroll = (props) => {
    const isLocked =
      scrollContainer.current.classList.contains('scroll-locked');
    if (!isLocked) {
      return;
    }

    const onComplete = props?.onComplete || null;

    // reset scroll bar color
    document.documentElement.style.backgroundColor = 'initial';

    // reset original document overflow value
    scrollContainer.current.style.overflowY = originalOverflow;

    // reset the right margin as we now have a scrollbar again
    // scrollContainer.current.style.marginRight = '0px';
    try {
      document.querySelector(
        'div[class^="StyledToolbar-sc-"]'
      ).style.marginRight = '0px';
    } catch (error) {
      //
    }

    // set class to monitor locked state
    scrollContainer.current.classList.toggle('scroll-locked');

    if (typeof onComplete === 'function') onComplete();
  };

  return { lockScroll, unlockScroll };
};
